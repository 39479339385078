import axios from "axios";
import { formatDate } from "./globalsUtils";
import { EndUser } from "models/EndUser";
import { AssetStatus, Profile, ProfileAsset } from "models/Profile";
import { useMaterialUIController } from "context";
import { makeRequest, networkGlobals } from "./networkUtils";
import { mmAction } from "./matchmakerUtils";


export const getByEmailPhone = async (
  brandID: string,
  opt: { email: string; phone: string }
): Promise<EndUser> => {
  const searchBY = opt.email ? `email` : `phone_number`;
  if (opt.phone){
    if (opt.phone.length == 3) {
      opt.phone = "+15555555" + opt.phone
    } else if (!opt.phone.startsWith("+")) {
      opt.phone = "+1" + opt.phone
    }
  }
  return makeRequest(
    `profiles/cognito?searchBY=${searchBY}&userID=${
      opt.email || opt.phone
    }&brandID=${brandID || ""}`
  );
};

export const getPendingProfiles = async ( brandID: string) => {
  return makeRequest(
    `profiles/adminList?brandID=${brandID || ""}&status=6`
  );
};

export const getProfileStats = async ( brandID: string) => {
  return makeRequest(`profiles/stats?brandID=${brandID}`);
};

let profilesCache = {};
export const getEndusersList = async ( brandID: string, status:string[], page: number) => {
  const key = `${brandID}-${page}-${networkGlobals.host}-${status.join(",")}`;
  if (!profilesCache[key]) {
    profilesCache[key] = await makeRequest(`profiles/adminList?brandID=${brandID || ""}&page=${page}&status=${status.join(",")}`);
  }
  return profilesCache[key];
};

export const getByUserId = async (
  userID: string
): Promise<{ user: EndUser; profile: Profile; profileAssets: ProfileAsset[] }> => {
  return makeRequest(`profiles/${userID}`);
};

export const getQuestionnaireStats = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/questionnaires-stats`);
}

export const getStatistics = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/statistics`);
}

export const getIntrosByFilter = async (profileId: string, filter: string) => {
  return makeRequest(`profiles/${profileId}/interactions?filter=${filter}`);
}

export const getImages = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/images`);
}

export const getUsageStats = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/usage-statistics`);
}

export const getTickets = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/tickets`);
}

export const updateStatus = async (profileId: string, statusId: number, statusReason:string) => {
  const res = makeRequest(`profiles/${profileId}/status`, {method:"PUT", body:{ statusId,statusReason }}).then(() => profilesCache = {});
  await mmAction(1, "approveEndUser", "","",profileId);
  return res;
}

export const updateEndUser = async (profileId: string, data: any) => {  
  const res = await makeRequest(`profiles/${profileId}`, {method:"PUT", body:data}).then((r) => {
     profilesCache = {}
      return r;
  })
  await mmAction(1, "approveEndUser", "","",profileId);
  return res;
}

export const getNotifcationsSettings = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/notifications`);
}

export const  updateNotificationSetting = async (profileId: string, channel_id: number, category_id: number, state: boolean) => {
  return makeRequest(`profiles/${profileId}/notifications`, {method:"PUT", body:{ channel_id, category_id, state }});
}

export const updateImageStatus = async (profileId: string, imageId: string, status) => {
  return makeRequest(`profiles/${profileId}/images/${imageId}`, {method:"PUT", body:{ status }});
}

export const deleteProfile = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}`, {method:"DELETE"}).then(() => profilesCache = {});
}

export const getProfileResponses = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/responses`);
}
