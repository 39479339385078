import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "components/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";

import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
  setAuth,
  setCurrentUser,
  setAuthToken,
  setTranslations,
  setCurrentRole,
  setCurrentHost,
} from "context";
import { checkAuthDO } from "utils/authUtils";
import { getTranslationsByBranDO } from "utils/globalsUtils";

import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { withErrorBoundary, useErrorBoundary } from "react-use-error-boundary";
import { ErrorPage } from "layouts/error/errorPage";

export default withErrorBoundary(({ children }) => {
  const [error, resetError] = useErrorBoundary(
    // You can optionally log the error to an error reporting service
    (error, errorInfo) => console.log(error, errorInfo)
  );

  const navigate = useNavigate();

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    auth,
    currentUser,
    currentRole
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      checkAuth();
      loadGlobals();
    }, 500);
  }, []);

  const loadGlobals = async () => {
    /** get translations */
    const preparedTanslations = await getTranslationsByBranDO();
    console.log('preparedTanslations: ', preparedTanslations);
    setTranslations(dispatch, preparedTanslations);
  };

  const checkAuth = async () => {
    const currentUser = JSON.parse(localStorage.getItem("BOCurrentUser"));
    setCurrentUser(dispatch, currentUser);
    setCurrentRole(
      dispatch,
      JSON.parse(localStorage.getItem("BORoles" + currentUser?.brands?.[0]))
    );
    setAuthToken(dispatch, localStorage.getItem("BOToken"));

    await checkAuthDO()
      .then(() => {})
      .catch((rej) => {
        if (rej?.response?.status === 401) {
          setAuth(dispatch, false);
          localStorage.removeItem("BOToken");
          localStorage.removeItem("BOCurrentUser");
          navigate("/sign-in");
        }
      });
  };

  const getRoutes = (allRoutes) => 
    allRoutes
      .filter((route) => route.accessNumber == 0 || !currentRole.pages || currentRole.pages.includes(route.accessNumber))
      .map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  if (window.location.hash) {
    // console.log('window.location.pathname: ', window.location.pathname);
    // console.log('window.location: ', window.location);
    // console.log('document.title: ', document.title);
    // window.history.replaceState("", document.title, window.location.pathname);
    // window.history.replaceState( {} , window.location.hash?.split('/')[1], '/'+window.location.hash?.split('/')[1] );
  }

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />

        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="mujual"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="mujual"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator /> */}
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      {error ? (
        <ErrorPage error={error} resetError={resetError} />
      ) : (
        <Routes>
          {getRoutes(routes)}
          {auth ? (
            <Route path="*" element={<Navigate to="/dashboard" />} />
          ) : (
            <Route path="*" element={<Navigate to="/sign-in" />} />
          )}
        </Routes>
      )}
    </ThemeProvider>
  );
});
