import { PredictorRelation } from "models/PredictorRelation"
import { makeRequest } from "./networkUtils"

export const getPredictorRelations = (): Promise<PredictorRelation[]> => {
    return makeRequest("predictor-relations")
}

export const getPredictorRelation = (id: string): Promise<PredictorRelation> => {
    return makeRequest(`predictor-relations/${id}`)
}

export const createPredictorRelationDO = (predictorRelation: PredictorRelation): Promise<PredictorRelation> => {
    return makeRequest("predictor-relations", { method: "POST", body: predictorRelation })
}

export const savePredictorRelationDO = (predictorRelation: PredictorRelation): Promise<PredictorRelation> => {
    return makeRequest(`predictor-relations/${predictorRelation.id}`, { method: "PUT", body: predictorRelation })
}

export const deletePredictorRelationDO = (predictorRelation: PredictorRelation): Promise<void> => {
    return makeRequest(`predictor-relations/${predictorRelation.id}`, { method: "DELETE" })
}

export const publishPredictorRelations = (): Promise<void> => {
    return makeRequest("utils/publish", { method: "POST", body: { type: "predictor-relations" } })
}