export class Content {

    languageId: string;
    entityId: string;
    txt: string;
    brandId: number;
    created: string;
    createdBy: number;
    updated: string;
    updatedBy: number;
    position: number[];
    preValidated: string;
    status: number;
    autoGenerated: boolean;
    answer:any;
    question:any;
   
    get id(): string {
        return this.entityId;
        }
    
}