import {
  Avatar,
  Badge,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeactivateDialog from "layouts/itemPageContainer/components/deactivateDialog";
import { formatDateTime } from "utils/globalsUtils";
import {
  HeaderIndication,
  HeaderIndicationProps,
} from "layouts/itemPageContainer/components/headerIndication";
import { log } from "console";
import { VerticalDivider } from "components/alove/VerticalDivider";
import React from "react";
const MDTypography = require("components/MDTypography/index.js").default;
const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const { T } = require("components/alove/Translator");
const MDBox = require("components/MDBox/index.js").default;
const MDAvatar = require("components/MDAvatar/index.js").default;

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "100%",
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: "100%",
});

export interface SMEHeaderProps {
  showImage?: boolean;
  formData?: any;
  canChange?: boolean;

  saveProfile?: () => void;
  indications?: HeaderIndicationProps[];
  title?: string;
}

export const SMEHeader = (props: SMEHeaderProps) => {
  const { formData, canChange, saveProfile, indications, showImage = true, title } = props;
  const [imageLoader, setImageLoader] = React.useState(false);

  const editAvatar = (e) =>
    new Promise((resolve, reject) => {
      const file = e.target.files[0];
      console.log(file);
      const reader = new FileReader();
      reader.onloadend = async () => {
        formData.avatarBase64 = reader.result;
        formData.avatar = reader.result;
        await saveProfile?.call(null);
        resolve("ok");

      };
      reader.readAsDataURL(file);
    });

  return (
    <Card sx={{ height: "125px", pt:2 }}>
      {!showImage && (
        <Grid item xs={12} lg={9}>
          <Card sx={{ px: 4 }}>
            <Grid container spacing={3} alignItems="center" justifyContent="space-between">
              <Grid item sx={{ mt: 1 }}>
                <MDTypography variant="h5">
                  <T>{title}</T>
                </MDTypography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
      <Grid flexDirection="row" container alignItems={"end"} sx={{ px: 4 }} wrap="nowrap" style={{overflowX: "scroll", height: "100%"}}>
        {showImage ? (
          <Grid item xs={4}  flexWrap="nowrap" flexDirection="row" container gap={3} alignItems={"center"} height={"100%"}>
            <Stack direction="row">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  canChange && (
                    <Button component="label">
                      <VisuallyHiddenInput
                        type="file"
                        onChange={async (e) => {
                          setImageLoader(true);
                          await editAvatar(e);
                          setImageLoader(false);
                        }}
                        accept="image/*"
                      />

                      <SmallAvatar
                        sx={{ backgroundColor: "#ffffff", border: "1px solid black" }}
                        alt="Remy Sharp"
                      >
                        {imageLoader ? (
                          <CircularProgress size={20} />
                        ) : (
                          <EditIcon sx={{ color: "#000000", cursor: "pointer" }} />
                        )}
                      </SmallAvatar>
                    </Button>
                  )
                }
              >
                <img
                  src={
                    formData?.avatar ||
                    "https://alove-dev-assets.s3.amazonaws.com/public/uploaded/zuug/png/no_pic_place_holder_m.png"
                  }
                  style={{ height: "84px", width: "84px", borderRadius: "50%", objectFit: "cover" }}
                />
              </Badge>
            </Stack>
            <Grid item container display="flex" flexDirection="column" justifyContent="center">
              <MDTypography variant="h5" fontWeight="light">
                {formData?.attributes?.title || ""}
              </MDTypography>
              <MDTypography variant="h5" fontWeight="medium">
                {formData?.firstName} {formData?.lastName}
              </MDTypography>
            </Grid>
          </Grid>
        ) : null}

        <Grid item display="flex" justifyContent="start" gap={1} flexDirection="row" alignItems="end">
          {indications
            ?.filter((indication) => !indication.hidden)
            .map((indication, index) => {
              return [
                <HeaderIndication {...indication} index={index} key={indication.title} />,
                index != indications.length - 1 && <VerticalDivider height="40px" key={index} />,
              ];
            })}
        </Grid>
      </Grid>
    </Card>
  );
};
