import { Divider, Grid } from "@mui/material";
import { MenuScreenItem, ScreenModel } from "models/Screen";
import { useEffect, useState } from "react";

const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

export const MenuForm = ({
  screen,
  setScreen,
}: {
  screen: ScreenModel;
  setScreen: (screen) => void;
}) => {

  return (
    <Grid>
      {screen.menuItems.map((item: MenuScreenItem, index) => {
        return (
          <Grid item xs={12} key={index} container justifyContent="space-between">
            <Grid item xs={8} my={1}>
              <MDInput
                label="Title"
                value={item.title}
                fullWidth
                multiline
                onChange={(e: any) => {
                    screen.menuItems[index].title = e.target.value;
                  setScreen({ ...screen, menuItems: screen.menuItems });
                }}
              />
            </Grid>
            <Grid item xs={8} my={1}>
              <MDInput
                label="Subtitle"
                value={item.subtitle}
                fullWidth
                multiline
                onChange={(e: any) => {
                    screen.menuItems[index].subtitle = e.target.value;
                  setScreen({ ...screen, menuItems: screen.menuItems });
                }}
              />
            </Grid>
            <Grid item xs={8} my={1}>
              <MDInput
                label="Panel"
                value={item.panel}
                fullWidth
                multiline
                onChange={(e: any) => {
                    screen.menuItems[index].panel = e.target.value;
                  setScreen({ ...screen, menuItems: screen.menuItems });
                }}
              />
            </Grid>
            {index === 0 ? null : (
              <Grid item xs={3}>
                <MDButton
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setScreen({
                      ...screen,
                        menuItems: screen.menuItems.filter((_, i) => i !== index),
                    });
                  }}
                >
                  Remove
                </MDButton>
              </Grid>
            )}
            <Divider style={{ width: "100%" }} />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <MDButton
          variant="contained"
          color="primary"
          onClick={() => {
            setScreen({ ...screen, menuItems: [...screen.menuItems, { title: "", subtitle: "", panel: "" }] });
          }}
        >
          Add Text
        </MDButton>
      </Grid>
    </Grid>
  );
};
