import React, { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

import { authDO } from "utils/authUtils";
import { getRoleByArrayDO } from "utils/rolesUtils";

import {
  useMaterialUIController,
  setAuth,
  setCurrentUser,
  setAuthToken,
  setCurrentRole,
  setCurrentBrand,
  setCurrentDialog,
} from "context";

// Authentication layout components
import IllustrationLayout from "layouts/signIn/components/IllustrationLayout";

// Images
import regularBgImage from "assets/images/logIn/login.jpg";
import zuugBgImage from "assets/images/logIn/zuugLogInSmall.jpg";
import aLoveLogo from "assets/images/brands/a-love.png";
import zuugLogo from "assets/images/brands/zuugLogo.svg";
import { indexof } from "stylis";
import { groupBy } from "utils/globalsUtils";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { T } from "components/alove/Translator";
import { getBrandByNmeDO, getBrandsDO } from "utils/brandsUtils";
import { Control } from "models/BoRole";
import { DialogType } from "components/alove/AloveDialog";
import { ForgotPasswordDialog } from "./components/forgotPassword";
import { networkGlobals } from "utils/networkUtils";

const MDTypography = require("components/MDTypography/index.js").default;
const MDInput = require("components/MDInput/index.js").default;
const MDBox = require("components/MDBox/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

function Illustration() {
  let bgImage, welcomeText, brandLogo;
  const domain = /:\/\/([^\/]+)/?.exec(window.location.href)?.[1]?.split(".") || [""];
  // let domain = [];
  // domain.push('zuug');
  bgImage = domain[0] === "zuug" ? zuugBgImage : regularBgImage;
  welcomeText = domain[0] === "zuug" ? "Sign in to ZUUG platform" : "Sign in to a-love platform";
  brandLogo = domain[0] === "zuug" ? zuugLogo : aLoveLogo;

  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const [rememberMe, setRememberMe] = useState(false);
  const [currentAuth, setCurrentAuth] = useState({
    email: null,
    password: null,
  });

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
  });
  const [errorMessages, setErrorMessages] = useState("");

  useEffect(() => {
    localStorage.clear();
    getBrandsDO(networkGlobals.host).then((res) => {
      console.log("res: ", res);
      setCurrentBrand(dispatch, res.find((x) => x?.name?.toLowerCase() === domain[0]) || res[0]);
    });
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const prepareRoles = (roles) => {
    let currentRole;
    const rolesByBrand = groupBy(roles, (x) => x.brand_id);

    for (const brand in rolesByBrand) {
      let pages: number[] = [];
      let controls: Control[] = [];
      for (const role of rolesByBrand[brand]) {
        for (const r of role?.permissions?.pages) {
          pages.push(r);
          pages.push(Math.floor(r));
          if (!Number.isSafeInteger(r)) {
            controls.push(role?.permissions?.controls.find((rr) => rr?.page === r));
          }
        }
      }
      const role = { pages, controls };
      localStorage.setItem("BORoles" + brand, JSON.stringify(role));
      currentRole ||= role;
    }
    setCurrentRole(dispatch, currentRole);
  };

  const loginHandle = async () => {
    const emailError = currentAuth?.email ? false : true;
    const passwordError = currentAuth?.password ? false : true;
    setErrorMessages("");
    setErrors({ ...errors, emailError, passwordError });

    if (currentAuth?.email && currentAuth?.password) {
      await authDO(currentAuth?.email, currentAuth?.password, "dev")
        .then(async (user) => {
          console.log("user: ", user);
          if (user?.user?.email) {
            const newUser = user?.user;
            const rolesResult = await getRoleByArrayDO(
              "dev",
              Object.values(newUser?.roles)
                .map((x: any) => x?.roles)
                .flat(),
              newUser?.brands[0]
            );
            console.log("rolesResult: ", rolesResult);
            localStorage.clear();
            await prepareRoles(rolesResult);

            setAuth(dispatch, true);
            await setCurrentUser(dispatch, user?.user);
            setAuthToken(dispatch, user?.token);
            localStorage.setItem("BOToken", user?.token);
            localStorage.setItem("BOCurrentUser", JSON.stringify(user?.user));
            navigate("/");
          }
        })
        .catch((rej) => {
          console.log("in reject of auth");
          console.log("rej: ", rej);
          if (rej?.response?.status === 400) {
            setErrorMessages("Incorrect Email/Password");
            setErrors({ ...errors, passwordError: true });
            // setAuth(dispatch, false);
            // localStorage.removeItem('BOToken');
            // localStorage.removeItem('BOCurrentUser');
          } else if (rej?.response?.status === 404) {
            console.log("404");
            setErrorMessages("Incorrect Email/Password");
            setErrors({ ...errors, emailError: true });
          }
        });
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <IllustrationLayout
      title="Sign In"
      illustration={bgImage}
      welcomeText={welcomeText}
      brandLogo={brandLogo}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            onChange={(e) => setCurrentAuth({ ...currentAuth, email: e?.target?.value })}
            error={errors?.emailError}
          />
        </MDBox>
        <MDBox mb={0}>
          <MDInput
            type={showPassword ? "text" : "password"}
            label="Password"
            placeholder="Password"
            fullWidth
            onChange={(e) => setCurrentAuth({ ...currentAuth, password: e?.target?.value })}
            error={errors?.passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>

        <MDBox mb={2} display="flex" alignItems="center" ml={0}>
          <MDTypography
            variant="button"
            fontWeight="regular"
            sx={{ userSelect: "none", color: "red" }}
          >
            {errorMessages}
            {/* &nbsp;&nbsp;Remember me */}
          </MDTypography>
        </MDBox>

        <Grid container justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center" ml={-1}>
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
              &nbsp;&nbsp;Remember me
            </MDTypography>
          </MDBox>
          <MDTypography
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
            sx={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={() => setCurrentDialog(dispatch, DialogType.ForgotPassword)}
          >
            <T>forgotPassword</T>
          </MDTypography>
        </Grid>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            onClick={() => loginHandle()}
          >
            sign in
          </MDButton>
        </MDBox>
        {domain[0] === "zuug" ? (
          <MDBox mt={3} textAlign="center">
            <MDTypography variant="button" color="text">
              Want to join ZUUG as a Shadchan?
            </MDTypography>
            <MDTypography
              // component={Link}
              // to="https://www.a-dmin.ai/registration"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
              sx={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => (window.location.href = "https://mm.a-dmin.ai")}
            >
              &nbsp;<T>applyNow</T>
            </MDTypography>
            {/* <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" size="large" fullWidth onClick={() => navigate("https://www.a-dmin.ai/registration")}>
                register
              </MDButton>
            </MDBox> */}
          </MDBox>
        ) : null}
      </MDBox>
      <ForgotPasswordDialog />
    </IllustrationLayout>
  );
}

export default Illustration;
