import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getShidduchRequests } from "utils/matchmakerUtils";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  TextField,
  Grid,
  Card,
  Box, List, ListItem
} from "@mui/material";
import { STATUSES } from "utils/matchmakerUtils";
import { T } from "components/alove/Translator";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { ShRequestsFilters } from "./components/filters";
import { getAdminStats } from "utils/matchmakerUtils";
import { TablePage } from "components/alove/TablePage";
import { PermissionType } from "models/BoRole";
import { getCustomerSupportTopicsDO, getSLASettingsDO, updateStatusDO, getUsersWithBrandsDO, updateAssigneeDO, getAllUSersDO } from 'utils/customerSupportUtils';
const MDSnackbar = require("components/MDSnackbar").default;

const host = 'dev';

const shouldColorBackground = (request) =>
  request.status <= 9 &&
  (request.statusReason?.initiatorStatus || request.statusReason?.responderStatus);

const action = (request, refresh) => { };

function CustomerSupportSettings() {
  const pageNumber = "6.2"
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentRole, currentBrand } = controller;
  const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.Manager);

  const [topics, setTopics] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [brands, setBrands] = useState([
    { id: 100, key: 'a-love', label: 'a-love' },
    { id: 101, key: 'mujual', label: 'mujual' },
    { id: 102, key: 'zuug', label: 'ZUUG' }
  ]);
  const [choosenBrand, setChoosenBrand] = useState(brands[0]);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [snackbar, setSnackbar] = useState({ status: false, color: "", icon: "", title: "" });
  const [refresh, setRefresh] = useState(false);

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  useEffect(() => {

    // console.log('in refresh');
    // setRefresh(!refresh);
  }, [choosenBrand]);

  const getUsers = async (brandID) => {
    let brands = [];
    if (brandID === 100) {
      brands.push(100);
    } else {
      brands.push(100);
      brands.push(brandID);
    }
    console.log('brands: ', brands);

    const users = await getUsersWithBrandsDO(host, brands);
    setUsers(users);
    setAllUsers(await getAllUSersDO(host));
  }

  const getSettings = async (host, filters, page) => {
    console.log('filters: ', filters);
    console.log('page: ', page);
    console.log(' choosenBrand?.id: ', choosenBrand?.id);

    const brandID = filters?.brand

    let tableData = [];
    const SLASettings = await getSLASettingsDO(host,brandID);
    console.log('SLASettings: ', SLASettings);

    const topics = await getCustomerSupportTopicsDO(host);
    console.log('topics: ', topics);

    topics.map((t, i) => {
      let obj = {};
      const sla = SLASettings.find((sla) => sla.topic_id === t.id);
      obj.id = sla?.id
      obj.category = t.name;
      obj.topic_id = t?.id;
      obj.sla_first = sla?.sla_first;
      obj.sla_completion = sla?.sla_completion;
      obj.assignee = sla?.assignee;
      obj.created = sla?.created;
      tableData.push(obj);
    })
    console.log('tableData: ', tableData);
    getUsers(brandID);
    return tableData;
  };

  const updateValue = async (newRow, oldRow) => {
    console.log('newRow: ', newRow);
    console.log('oldRow: ', oldRow);

    const newOBJ = { ...newRow, sla_completion: Number(newRow?.sla_completion) }
    const updateRes = await updateStatusDO(host, newOBJ);
    // setRefresh(!refresh);
    setSnackbar({
      status: true,
      color: updateRes === 200 ? 'success' : 'error',
      icon: updateRes === 200 ? 'check' : 'error',
      title: updateRes === 200 ? 'Settings was updated' : 'Something happened!',
    });
  };

  const renderBrand = (onChange, s) => (
    <Autocomplete
      sx={{ minWidth: 150 }}
      options={brands}
      value={choosenBrand}
      disableClearable
      hidden={!currentUser?.brands?.includes(100)}
      // isOptionEqualToValue={(option, value) => option.labe  == value.label}
      onChange={(e, o) => setChoosenBrand(o)}
      getOptionLabel={(o) => (o.getLabel ? o.getLabel() : o.label)}
      renderInput={(params) => <MDInput {...params} label='Brand' />}
    />
  );

  const SLAcolumns = (isAdmin, action) => [
    {
      headerName: 'Category',
      field: "category",
      width: 200
    },
    {
      headerName: 'SLA first (hours)',
      field: "sla_first",
      width: 150,
      editable: currentRole.hasAccess(pageNumber, PermissionType.Edit),
    },
    {
      headerName: 'SLA Completion (hours)',
      field: "sla_completion",
      width: 200,
      editable: currentRole.hasAccess(pageNumber, PermissionType.Edit)
    },
    {
      headerName: 'Assignee',
      field: "assignee",
      flex: 1,
      renderCell: (params) => {
        const defValue = [];
        if (params?.row?.assignee) {
          params?.row?.assignee?.map((u, i) => {
            const user = allUsers?.find((e) => e.id === u);
            defValue.push({ id: user?.id, first_name: user?.first_name, last_name: user?.last_name });
          })
        } else {
          defValue.push({ id: '', first_name: '', last_name: '' })
        }
        return (
          <Autocomplete
            key={params?.row?.id}
            sx={{ mt: 1, minWidth: 150 }}
            size="small"
            multiple
            disabled={!currentRole.hasAccess(pageNumber, PermissionType.Edit)}
            // limitTags={4}
            // getOptionDisabled={(option) => !currentRole.hasAccess(params.row.id, Number(option))}
            isOptionEqualToValue={(option, value) => option.first_name === value.first_name}
            onChange={(e, v) => updateAssignee(v, params.row)}
            // defaultValue={defValue ||[{id: '1', first_name: 'a', last_name: 'a' }]}
            defaultValue={defValue}
            options={users}
            getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name}
            renderInput={(params) => <MDInput {...params} variant="outlined" />}
          />
        )
      }
    }
  ];

  const updateAssignee = async (assignees, params) => {
    const data = [];
    assignees?.map((a, i) => data?.push(a?.id));
    const updateRes = await updateAssigneeDO(host, data, params?.id);
    setSnackbar({
      status: true,
      color: updateRes === 200 ? 'success' : 'error',
      icon: updateRes === 200 ? 'check' : 'error',
      title: updateRes === 200 ? 'Assignee was updated' : 'Something happened!',
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          '& .urgent': {
            color: '#F4357A'
          },
          '& .hidden': {
            color: 'transparent !important'
          }
        }}
      >
        <TablePage
          title="SLA Settings"
          refresh={refresh}
          table={{
            columns: SLAcolumns(isAdmin, action),
            loadNext: getSettings,
            pagination: true,
            processRowUpdate: updateValue
          }}
          filters={[
            // {
            //   label: "brand",
            //   hidden: isAdmin ? false : null,
            //   options: brands,
            //   defaultValue: brands[0],
            //   disableClearable: true
            //   // renderFilter: renderBrands,
            //   // clearOtherFilters: true,
            // },
            // {
            //   label: "Screen",
            //   renderFilter: renderBrand,
            //   clearOtherFilters: true,
            // }
          ]}
        />
      </Box>
      <MDSnackbar
        color={snackbar.color || "success"}
        icon={snackbar.icon || "check"}
        title={snackbar.title || ""}
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}

export default CustomerSupportSettings;
