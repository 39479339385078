import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/MDTable";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";
import MDSnackbar from "components/MDSnackbar";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Icon,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import EditQuestionnaireModal from "./editQuestionnaire";
import AddQuestionnaireModal from "./addQuestionnaire";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { questionnarieListDo } from "./../../../utils/questionsUtils";

import { useMaterialUIController, setMiniSidenav, setWhiteSidenav, setCurrentBrand } from "context";
import { camelcaseToSnakeCase } from "utils/globalsUtils";
import { TablePage } from "components/alove/TablePage";
import { formatDateTime } from "utils/globalsUtils";
import { actions } from "react-table";
import { PermissionType } from "models/BoRole";
import { networkGlobals } from "utils/networkUtils";

function QuestionnaireList() {
  const pageNumber = "4.2";
  const questionRidirectURL = "/questionnaires";

  const navigate = useNavigate();
  const [tableData, setTableData] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, whiteSidenav, darkMode, auth, currentUser, currentBrand, currentRole } =
    controller;

  const [snackbar, setSnackbar] = useState({
    status: false,
  });

  const columns = [
    {
      headerName: "#",
      field: "id",
      align: "center",
      flex: 1,
    },
    {
      headerName: "Brand",
      field: "brand_name",
      flex: 1,
    },
    {
      headerName: "Name",
      field: "display_name",
      flex: 2,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 4,
    },
    {
      headerName: "Updated",
      field: "updated",
      flex: 2,
      renderCell: (params) => {
        return formatDateTime(params.row.updated);
      },
    },
    {
      headerName: "Ver",
      field: "version",
      align: "center",
      flex: 1,
    },
    {
      headerName: "Actions",
      field: "actions",
      align: "center",
      flex: 1,
      renderCell: (params) =>
        currentRole?.hasAccess(PermissionType.Edit) && (
          <Tooltip title="Edit">
            <IconButton>
              <EditQuestionnaireModal
                data={params.row}
                getAndPrepareTableData={getAndPrepareTableData}
                snackbar={snackbar}
                setSnackbar={setSnackbar}
              />
            </IconButton>
          </Tooltip>
        ),
    },
  ];

  // useEffect(() => {
  //     getAndPrepareTableData();
  // }, [currentBrand?.id]);

  const [seachValue, setSearchValue] = useState("");

  const getAndPrepareTableData = async () => {
    const questionnarieList = await questionnarieListDo(networkGlobals.host, currentBrand?.id);
    return questionnarieList;
  };

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  const enterQuestionnaire = (questionnaire) => {
    navigate(`${questionRidirectURL}/${camelcaseToSnakeCase(questionnaire?.row?.name, "-")}`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TablePage
        title="Questionnaire list"
        table={{ columns, loadNext: getAndPrepareTableData, lineClick: enterQuestionnaire }}
        actions={{
          title: "Actions",
          page: pageNumber,
          addActions: [
            {
              role: PermissionType.AddNew,
              element: (
                <AddQuestionnaireModal
                key="add"
                  snackbar={snackbar}
                  setSnackbar={setSnackbar}
                  currentBrand={currentBrand?.id}
                  currentUser={currentUser?.id}
                  getAndPrepareTableData={getAndPrepareTableData}
                />
              ),
            },
          ],
        }}
      />
      <MDSnackbar
        color={snackbar?.color || "success"}
        icon={snackbar?.icon || "check"}
        title={snackbar?.title || ""}
        content=""
        dateTime=""
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}

export default QuestionnaireList;
