import { Card, CircularProgress, Grid } from "@mui/material";
import DeactivateDialog, { DeactivateDialogProps } from "./deactivateDialog";
import { PermissionType } from "models/BoRole";
import { useMaterialUIController } from "context";
import React from "react";
const MDTypography = require("components/MDTypography/index.js").default;
const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const { T } = require("components/alove/Translator");
const { BrandLogo } = require("components/alove/BrandLogo.js");

export interface ItemActiosProps {
  actions: {
    title: string;
    variant?: "contained" | "outlined" | "text";
    onClick: () => void;
    role?: PermissionType;
    color?: string;
    element?: JSX.Element;
    hidden?: boolean;
  }[];
  itemNumber: string | JSX.Element;
  itemName: string;
  actionsTitle: string;
  danger?: DeactivateDialogProps;
  brandLogoIMG?: string | undefined;
  pageNumber?: string;
}

export const ItemActions = (props: ItemActiosProps) => {
  const { actions, itemNumber, itemName, actionsTitle, danger, pageNumber, brandLogoIMG } = props;
  const [controller] = useMaterialUIController();
  const { currentRole } = controller;

  const [buttonLoader, setButtonLoader] = React.useState("");

  return (
    <Grid container spacing={1} alignItems="stretch">
      <Grid item xs={12} lg={9}>
        <Card sx={{ px: 4 }}>
          <Grid container spacing={3} alignItems="center" justifyContent="space-between">
            <Grid item sx={{ my: 4 }}>
              <MDTypography variant="h5">
                <T>{actionsTitle}</T>
              </MDTypography>
            </Grid>
            <Grid
              item
              sm={9}
              sx={{ textAlign: "right" }}
              container
              justifyContent="flex-end"
              alignItems="center"
              gap={1}
            >
              {danger && <DeactivateDialog {...danger} />}
              {actions
                .filter((action) => !action.hidden)
                .filter((action) => !action.role || currentRole.hasAccess(pageNumber, action.role))
                .map((action) => {
                  return (
                    action.element || (
                      <MDButton
                        key={action.title}
                        variant={action.variant || "outlined"}
                        color={action.color || "primary"}
                        onClick={async () => {
                          setButtonLoader(action.title);
                          await action.onClick();
                          setButtonLoader("");
                        }}
                      >
                        {buttonLoader === action.title ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <T>{action.title}</T>
                        )}
                      </MDButton>
                    )
                  );
                })}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Card sx={{ px: 3, height: "100%" }}>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Grid
              item
              xs={9}
              container
              flexDirection={"column"}
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <MDTypography variant="h5" fontWeight="medium">
                <T>{itemName}</T>
              </MDTypography>

              <MDTypography variant="body2">
                <T>{itemNumber}</T>
              </MDTypography>
            </Grid>
            <BrandLogo brandLogoIMG={brandLogoIMG} />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
