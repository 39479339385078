import { Divider, Grid } from "@mui/material";
import { ImageUploader } from "components/alove/ImageUploader";
import { ScreenCard, ScreenModel } from "models/Screen";
import { useEffect, useState } from "react";
const MDButton = require("components/MDButton/index.js").default;
const MDInput = require("components/MDInput/index.js").default;

export const CardsForm = ({ screen, setScreen }: { screen: ScreenModel; setScreen: any }) => {
  const [cards, setCards] = useState([] as ScreenCard[]);
  useEffect(() => {
    setCards(screen.cards)
  });

  const updateCard = (index: number, field: string, value: string) => {
    cards[index][field] = value;
    setScreen({ ...screen, cards });
  };

  return (
    <Grid container spacing={2} pl={2}>
      {cards.map((card, index) => {
        return (
          <Grid item xs={12} key={index} container spacing={2}>
            <Grid item xs={8}>
              <MDInput
                label="Title"
                value={card.title}
                fullWidth
                onChange={(e: any) => {
                  updateCard(index, "title", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MDButton
                variant="contained"
                color="secondary"
                onClick={() => {
                  setScreen({
                    ...screen,
                    cards: cards.filter((_, i) => i !== index),
                  });
                }}
              >
                Remove Card
              </MDButton>
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Body"
                value={card.body}
                multiline
                rows={4}
                fullWidth
                onChange={(e: any) => {
                  updateCard(index, "body", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ImageUploader
                initImage={card.image || ""}
                onUploaded={(image: string) => updateCard(index, "image", image)}
              />
            </Grid>
            <Divider style={{width: "100%"}} />
          </Grid>
        );
      })}
      <MDButton
        variant="contained"
        color="primary"
        sx={{ m: 2 }}
        onClick={() => {
          setScreen({ ...screen, cards: [...cards, { title: "", body: "", image: "" }] });
        }}
      >
        Add Card
      </MDButton>
    </Grid>
  );
};
