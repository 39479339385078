import React, { useState, useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Autocomplete, Card, Grid, TextField, Divider } from '@mui/material/';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/MDTable";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import AddNewRole from './addNewRoleModal';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { getSystemUsersDO } from "utils/systemUsersUtils";
import { getRolesDO } from 'utils/rolesUtils';
import { useMaterialUIController, setCurrentUser } from "context";

function RolePermissions() {

    const [controller, dispatch] = useMaterialUIController();
    const { currentUser, currentBrand } = controller;

    const [tableData, setTableData] = useState([]);
    const [search, setSearch] = useState('');
    const [themes, setThemes] = useState([
        // { name: 'Brands', options: ['List', 'Item'] },
        // { name: 'Users', options: ['End Users', 'Item', 'Groups', 'Item', 'System users', 'Item', 'Roles & Permissions'] },
        // { name: 'Content', options: ['App content', 'Communications', 'Languages', 'Content', 'Info Screens', 'Item'] },
        {
            name: 'Content', options: [
                { name: 'App content', access: 3.1 },
                { name: 'Communications', access: 3.2 },
                { name: 'Languages', access: 3.3 },
                { name: 'Content', access: 3.4 },
                { name: 'Info Screens', access: 3.5 },
                { name: 'Item', access: 3.6 }
            ]
        }
        // { name: 'Questionnaires', options: ['Questionnaires', 'Questions', 'Support Center - CS', 'SME Marketplace', 'Settings', 'Premium / Payments settings','Reports','Marketing (TBD)'] },
    ]);
    const [options, setOptions] = useState(['Access', 'View', 'Edit', 'Add/New', 'Delete', 'Duplicate', 'Update', 'Ask to publish', 'Publish', 'Host']);
    const [optionsCode, setOptionsCode] = useState(['Access', 'View', 100, 101, 102, 103, 104, 105, 106, 108, 109]);

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = async () => {
        setTableData(await getRolesDO('dev'));
    }
    const toolTipText = "Here come description";

    const label = { inputProps: { 'aria-label': 'Checkbox demo'}};

    const updateRole = async (data, id, access) => {
        console.log('data: ', data);
        console.log('id: ', id);
        console.log('access: ', access);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3} sx={{ mb: 1 }} alignItems="center">
                <Grid item xs={10} xl={10}>
                    <MDTypography variant="h5" fontWeight="medium">
                        Admin Role Permissions
                    </MDTypography>
                </Grid>
            </Grid>
            <Card>
                <MDBox p={3} lineHeight={1}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} xl={3} sx={{marginRight:2}}>
                            <MDInput
                                placeholder="Search..."
                                // sx={{ width: '70%' }}
                                value={search}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }) => {
                                    // setSearch(search);
                                    // onSearchChange(currentTarget.value);
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={2} xl={2}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size='small'
                                options={[]}
                                // sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="All categories" />}
                            />
                        </Grid> */}

                        {options.map((action, index) => {
                            console.log('action', action);
                            return (
                                <Grid item  xs={0.859} xl={0.859}
                                style={{
                                    display: "flex",
                                    // flexDirection: "row",
                                    // alignItems: "center",
                                    justifyContent: "center",
                                 }}>
                                    {/* <Checkbox {...label} /> */}
                                    <Tooltip title={toolTipText}>
                                        <MDTypography variant="body1" component="body1" sx={{ fontSize: 13.1, cursor: 'default' }} >
                                            {action}
                                        </MDTypography>
                                    </Tooltip>
                                </Grid>
                            )
                        })}
                        
                    </Grid>
                    <MDBox p={0} lineHeight={1} mt={3}>
                        {themes.map((theme, index) => {
                            console.log('theme', theme);
                            return (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel1-content"
                                        id='panel1-header-'
                                    >
                                        <Typography>{theme?.name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={0} alignItems="center" >
                                            {theme?.options.map((option, index) => {
                                                console.log('option', option);
                                                return (
                                                    <>
                                                        <Divider variant="middle" />
                                                        <Grid item xs={3} xl={3} >
                                                            {option.name}
                                                        </Grid>
                                                        {options.map((action, index) => {
                                                            console.log('action', action);
                                                            return (
                                                                <>
                                                                    <Grid item xs={0.90} xl={0.90} 
                                                                    style={{
                                                                        display: "flex",
                                                                        // flexDirection: "row",
                                                                        // alignItems: "center",
                                                                        justifyContent: "center",
                                                                     }}
                                                                    sx={{border: '1px solid #F8F8F8'}}
                                                                    >
                                                                        <Checkbox {...label}
                                                                        onChange={(e) => updateRole(e, optionsCode[index], option?.access)}
                                                                          />
                                                                        {/* {action} */}
                                                                    </Grid>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
    );
}

export default RolePermissions;