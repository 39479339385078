import { Autocomplete, Card, Grid } from "@mui/material";
import { GeneralCode, GeneralCodeType } from "models/GeneralCode";
import { ScreenEvent, ScreenModel, ScreenType } from "models/Screen";
import { useEffect, useState } from "react";
import { getGeneralCodes } from "utils/generalCodesUtils";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
const MDInput = require("components/MDInput/index.js").default;

export const ScreenHeader = ({ screen, setScreen }: { screen: ScreenModel; setScreen: any }) => {
  const [categories, setCategories] = useState([] as GeneralCode[]);
  useEffect(() => {
    getGeneralCodes(GeneralCodeType.ScreenCategory).then((categories) => setCategories(categories));
  }, []);
  return (
    <Card style={{ height: "100%" }}>
      <Grid container flexDirection="row" justifyContent="start" alignItems="center" p={5} gap={2}>
        <Grid item xs={5}>
          <MDInput
            fullWidth
            label="Name (for internal use)"
            value={screen.name || ""}
            onChange={(e: any) => setScreen({ ...screen, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            value={screen.categoryId || 0}
            disableClearable
            fullWidth
            getOptionLabel={(option: number) =>
              categories.find((category) => category.id === option)?.name || ""
            }
            onChange={(e, o) => setScreen({ ...screen, categoryId: o })}
            options={categories.map((category) => category.id)}
            renderInput={(params) => <MDInput {...params} label="Category" />}
          />
        </Grid>

      <Grid item xs={3}>
        <Autocomplete
          value={screen.type || ScreenType.Popup}
          fullWidth
          disableClearable
          getOptionLabel={(option: number) => camelCaseToCapitalizedWords(ScreenType[option])}
          onChange={(e, o) => setScreen({ ...screen, type: o })}
          options={Object.values(ScreenType)
            .map((key) => Number(key))
            .filter((key) => !isNaN(key))}
          renderInput={(params) => <MDInput {...params} label="Screen Type" />}
        />
      </Grid>
      </Grid>
    </Card>
  );
};
