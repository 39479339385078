import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { Card, Grid, Autocomplete, Divider, TextField, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MDSnackbar from "components/MDSnackbar";

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

import { getRolesDO, getPagesDO } from 'utils/rolesUtils';
import { postNewUserDO, getSystemUserByIDDO, updateUserDO, getBrandsDO } from 'utils/systemUsersUtils';
import { useMaterialUIController } from "context";

function AddNewSystemUser() {

    const [queryParameters] = useSearchParams();
    const [brandsData, setBrandsData] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [host, setHost] = useState('dev');
    const [isUpdateMode, setisUpdateMode] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [selectedBrands, setSelectedBrands] = useState();
    const [snackbar, setSnackbar] = useState({
        status: false,
        text: '',
        color: ''
    });
    const [controller] = useMaterialUIController();
    const [user, setUser] = useState(
        {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            lang: 'en',
            password: '',
            brands: [],
            role: []
        }
    );

    const [inputErrors, setInputErrors] = useState({
        nameError: false,
        lastNameError: false,
        emailError: false,
        phoneError: false,
        passwordError: false
    });

    useEffect(() => {
        getBrands(host);
        getRoles(host);
        getPages(host);
        if (queryParameters.get("id")) {
            getUserByID(queryParameters.get("id"));
        }
    }, [])

    const buildSelected = (where, userData, selectedData) => {

        let returnedPositions = [];
        let pos;

        if (selectedData.length > 0 && userData) {
            for (let value of userData?.[where]) {
                pos = selectedData.find((p) => p?.id == value)
                if (pos) {
                    if (where === 'brands') {
                        returnedPositions.push({ name: pos?.name })
                    } else if (where === 'role') {
                        returnedPositions.push({ role_name: pos?.role_name })
                    }
                }
            }
            return returnedPositions;
        }
    }

    const getBrands = async (host) => {
        const brands = await getBrandsDO(host);
        setBrandsData(brands);
    }

    /** get and prepare pages */
    const getPages = async (host) => {
        const pages = await getRolesDO(host);
        console.log('getPages: ', getPages);
        // setRolesData(roles);
    }

    const getRoles = async (host) => {
        const roles = await getRolesDO(host);
        setRolesData(roles);
    }

    const getUserByID = async (userID) => {
        const brands = await getBrandsDO(host);
        setBrandsData(brands);
        const user = await getSystemUserByIDDO(host, userID);
        if (user) {
            setisUpdateMode(true);
            setUser(...user,
                //     {
                //     phone: user[0].phone,
                //     email: user[0].email,
                //     name: user[0].first_name,
                //     lastName: user[0].last_name
                // }
            );
        }
    }

    const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

    // Generate a random password
    const randomPassword = () => {
        setUser({ ...user, password: Math.random().toString(36).slice(-8) });
        setShowPassword(true);
    };

    // Reset all input fields
    const resetAllFields = () => {
        setUser({
            first_name: '',
            last_name: '',
            avatar: 'https://png.pngtree.com/element_our/png/20181206/users-vector-icon-png_260862.jpg'
            // email: '',
            // phone: '',
            // lang: 'en',
            // password: ''
        });
    };

    const validateInput = (value, minLength) => {
        return value?.trim().length >= minLength;
    };

    const validateForm = () => {
        let isValid = true;

        const isNameValid = validateInput(user.first_name, 1);
        const a = { ...inputErrors };

        setInputErrors({ ...a, nameError: !isNameValid });
        isValid = isValid && !isNameValid;

        const isLastNameValid = validateInput(user.last_name, 1); // Assuming last name cannot be empty
        setInputErrors({ ...inputErrors, lastNameError: !isLastNameValid });
        isValid = isValid && isLastNameValid;

        const isEmailValid = validateInput(user.email, 1); // Assuming last name cannot be empty
        setInputErrors({ ...inputErrors, emailError: !isEmailValid });
        isValid = isValid && isEmailValid;
        return isValid;
    };


    const upddateSelect = (where, values) => {
        let userSelected = [];
        for (let value of values) {
            if (!value?.id) {
                if (where === 'brands') {
                    const res = brandsData.find((b) => b.name === value.name);
                    userSelected.push(res?.id);

                } else {
                    const res = rolesData.find((b) => b.role_name === value.role_name);
                    userSelected.push(res?.id);
                }

            } else {
                userSelected.push(value?.id);
            }
        }
        setUser({ ...user, [where]: userSelected });
    }

    // Create a new user
    const createNewUserHandle = async () => {

        if (!validateForm()) {
            // If the form is not valid, return without creating a new user
            // return;
        }

        const values = {
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            password: user.password,
            brands: `[${user.brands}]`,
            role: `[${user.role}]`,
            // role:`[1]`,
            lang: 'en',//user.lang
            avatar: 'https://png.pngtree.com/element_our/png/20181206/users-vector-icon-png_260862.jpg'
        };

        const postResult = await postNewUserDO('dev', values);
        if (postResult === 201) {
            setSnackbar({ ...snackbar, status: true });
        }
    };

    // Update user
    const updateUserHandle = async () => {
        // Form validation logic goes here...

        if (!validateForm()) {
            // If the form is not valid, return without creating a new user
            // return;
        }

        const values = {
            id: queryParameters.get("id"),
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            password: user.password,
            brands: `[${user.brands}]`,
            role: `[${user.role}]`,
            lang: 'en',//user.lang
            avatar: 'https://png.pngtree.com/element_our/png/20181206/users-vector-icon-png_260862.jpg'
        };

        const updateResult = await updateUserDO('dev', values);
        if (updateResult === 200) {
            await setSnackbar({ ...snackbar, status: true });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {/* User details card */}
            <MDBox pt={2} pb={3}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                <MDAvatar src={user?.avatar} alt="profile-image" size="xl" shadow="sm" />
                            </Grid>
                            <Grid item>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="h5" fontWeight="medium">
                                        ID #{user?.id}
                                    </MDTypography>
                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                        {user?.is_active === "Active"? 'Active': 'Not active'} 
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox p={3} lineHeight={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} xl={4}>
                                {/* {inputErrors.nameError.toString()} */}
                                <MDInput
                                    placeholder="Name"
                                    value={user?.first_name}
                                    size="small"
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, first_name: e.target.value })}
                                    error={inputErrors.nameError}
                                />
                            </Grid>
                            {/* {inputErrors.lastNameError.toString()} */}
                            <Grid item xs={12} xl={4}>
                                <MDInput
                                    placeholder="Last name"
                                    value={user?.last_name}
                                    size="small"
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, last_name: e.target.value })}
                                    error={inputErrors.lastNameError}
                                />
                            </Grid>
                            {/* {inputErrors.emailError.toString()} */}
                            <Grid item xs={12} xl={4}>
                                <MDInput
                                    inputProps={{ type: "email" }}
                                    placeholder="Email"
                                    value={user?.email}
                                    size="small"
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                                    error={inputErrors.emailError}
                                />
                            </Grid>
                        </Grid>
                    </MDBox>

                    <MDBox p={3} lineHeight={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} xl={4}>
                                <MDInput
                                    inputProps={{ type: "phone" }}
                                    placeholder="Phone"
                                    value={user?.phone}
                                    size="small"
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, phone: e.target.value })}
                                    error={inputErrors.phoneError}
                                />
                            </Grid>
                            <Grid item xs={12} xl={4}>
                                <Autocomplete
                                    defaultValue='en'
                                    value={user?.lang}
                                    size="small"
                                    id="controllable-states-demo"
                                    options={['en']}
                                    renderInput={(params) => <TextField {...params} label="Language" />}
                                />
                            </Grid>
                            <Grid item xs={12} xl={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} xl={8}>
                                        <MDInput
                                            inputProps={{ type: !showPassword ? "password" : "text" }}
                                            placeholder="Password"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setUser({ ...user, password: e.target.value })}
                                            value={user?.password}
                                            error={inputErrors.passwordError}
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        //   onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} xl={4}>
                                        <MDButton
                                            variant="gradient"
                                            // color={action.color}
                                            fullWidth
                                            onClick={randomPassword}
                                        >
                                            random
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MDBox>

                </Card>
            </MDBox>

            <MDBox pt={2} pb={3}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Account & Roles
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>

                    <MDBox pl={3} pb={3} lineHeight={1}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} xl={3}>
                                {user.first_name !== '' ? (
                                    <Autocomplete
                                        size="small"
                                        isOptionEqualToValue={(positions, value) => positions.name === value.name}
                                        multiple
                                        defaultValue={buildSelected('brands', user, brandsData)}
                                        options={brandsData}
                                        disableCloseOnSelect
                                        onChange={(e, value) => upddateSelect('brands', value)}
                                        getOptionLabel={(positions) => positions.name}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props} key={option.name} sx={{ height: 20 }}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                // variant="standard"
                                                label="Brands"
                                                placeholder="More..."
                                                size="small"
                                            />
                                        )}
                                    />
                                ) : null}
                            </Grid>

                            <Grid item xs={12} xl={3}>
                                {rolesData.length && user.first_name !== '' ? (
                                    <Autocomplete
                                        size="small"
                                        isOptionEqualToValue={(positions, value) => positions.role_name === value.role_name}
                                        multiple
                                        defaultValue={buildSelected('role', user, rolesData)}
                                        options={rolesData}
                                        disableCloseOnSelect
                                        onChange={(e, value) => upddateSelect('role', value)}
                                        getOptionLabel={(positions) => positions.role_name}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props} key={option.role_name} sx={{ height: 20 }}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.role_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                // variant="standard"
                                                label="Roles"
                                                placeholder="More..."
                                                size="small"
                                            />
                                        )}
                                    />
                                ) : null}

                            </Grid>

                            <Grid item xs={12} xl={3}>
                                {user.first_name !== '' ? (
                                    <Autocomplete
                                        size="small"
                                        isOptionEqualToValue={(positions, value) => positions.name === value.name}
                                        multiple
                                        // defaultValue={buildSelected('brands', user, brandsData)}
                                        // options={brandsData}
                                        options={[]}
                                        disableCloseOnSelect
                                        // onChange={(e, value) => upddateSelect('brands', value)}
                                        getOptionLabel={(positions) => positions.name}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props} key={option.name} sx={{ height: 20 }}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                // variant="standard"
                                                label="Pages"
                                                placeholder="More..."
                                                size="small"
                                            />
                                        )}
                                    />
                                ) : null}
                            </Grid>

                        </Grid>
                    </MDBox>
                    <Divider />
                    <MDBox p={1} lineHeight={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} xl={8.5}></Grid>
                            <Grid item xs={6} xl={1.5}>
                                {isUpdateMode ? (
                                    <MDButton
                                        variant="gradient"
                                        size="small"
                                        // color={action.color}
                                        fullWidth
                                        onClick={() => disableUser()}
                                    >
                                        disable user
                                    </MDButton>

                                ) : (

                                    <MDButton
                                        variant="gradient"
                                        size="small"
                                        // color={action.color}
                                        fullWidth
                                        onClick={() => resetAllFields()}
                                    >
                                        Reset
                                    </MDButton>
                                )}
                            </Grid>
                            <Grid item xs={6} xl={2}>

                                {updateLoader ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) :
                                    !isUpdateMode ? (
                                        <MDButton
                                            // variant="gradient"
                                            fullWidth
                                            size="small"
                                            onClick={() => createNewUserHandle()}
                                            color="info"
                                        >
                                            Save
                                        </MDButton>

                                    ) :
                                        <MDButton
                                            // variant="gradient"
                                            fullWidth
                                            size="small"
                                            onClick={() => updateUserHandle()}
                                            color="info"
                                        >

                                            update
                                        </MDButton>
                                }

                                {/*  */}
                            </Grid>
                        </Grid>
                    </MDBox>
                </Card>
            </MDBox>


            <MDSnackbar
                color="success"
                icon="check"
                title="User was saved"
                content=""
                dateTime=""
                open={snackbar.status}
                onClose={closeSnackBar}
                close={closeSnackBar}
            />

        </DashboardLayout>

    );
}

export default AddNewSystemUser;
