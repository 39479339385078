import React, { useState, useEffect } from 'react';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import { postLogDO } from 'utils/logsUtils';
import { copyTranslationDO } from 'utils/translateUtils';
import { Grid, Autocomplete, TextField, IconButton } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from 'context';
import { PermissionType } from 'models/BoRole';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CopyTranslation(props) {
    const { translation, currentUser, currentBrand, setSnackbar, snackbar } = props;

    const [controller, dispatch] = useMaterialUIController();
    const { currentRole } = controller;

    const [modalOpen, setModalOpen] = useState(false);
    const [copyToHost, setCopyToHost] = useState('dev');
    const [copyToBrand, setCopyToBrand] = useState({ id: 100, name: 'a-love' });
    const [brands, setBrands] = useState([
        { id: 100, name: 'a-love' },
        { id: 101, name: 'mujual' },
        { id: 102, name: 'ZUUG' }
    ]);

    const hosts = ['prod', 'staging', 'dev'];

    const handleClickOpen = () => {
        setModalOpen(true);

        /**remove current brand --> you can not copy to same brand */
        // prepareBrand();
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    /**remove current brand --> you can not copy to same brand */
    const prepareBrand = () => {
        const index = brands.findIndex((brand) => brand?.id === currentBrand?.id);
        console.log('index: ', index);
        console.log('brands: ', brands);
        brands.splice(index, 1);
        setBrands(brands);
        setCopyToBrand(brands[0]);
    }

    const copyToAnotherBrandHost = async () => {
        console.log('copyToHost: ', copyToHost);
        console.log('copyToHocopyToBrandst: ', copyToBrand?.id);
        console.log('translation: ', translation);
        let copyRes;
        if (translation && copyToHost && copyToBrand?.id && (copyToBrand?.id !== currentBrand?.id)) {
            console.log('start copy');
            copyRes = await copyTranslationDO(copyToHost, copyToBrand?.id, translation, currentUser?.id);
            console.log('copyRes: ', copyRes);
        } else {
            console.log('in else');
        }
        if (copyRes?.status == 201) {
            console.log('success');
            handleClose(false);
            setSnackbar({ ...snackbar, status: true })
        }
    }

    return (
        <React.Fragment>
            <IconButton size="small" disableRipple onClick={handleClickOpen} disabled={!currentRole.hasAccess("3.1",PermissionType.Duplicate)}>
                <Tooltip title={'Copy to another brand'}>
                    <FileCopyIcon />
                </Tooltip>
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Copy to another brand/host
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} xl={12}>
                            <Autocomplete
                                size="small"
                                isOptionEqualToValue={(positions, value) => positions.name === value.name}
                                disableClearable
                                // id="checkboxes-tags-demo"
                                value={copyToBrand}
                                options={brands}
                                onChange={(e, value) => setCopyToBrand(value)}
                                getOptionLabel={(positions) => positions.name}

                                renderInput={(params) => (
                                    <TextField {...params}
                                        label="To brand" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                            <Autocomplete
                                size="small"
                                isOptionEqualToValue={(positions, value) => positions.name === value.name}
                                disableClearable
                                value={copyToHost}
                                options={hosts}
                                onChange={(e, value) => setCopyToHost(value)}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        label="To host" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6} xl={6}>
                            <MDButton
                                variant="gradient"
                                fullWidth
                                onClick={() => handleClose()}
                            >
                                cancel
                            </MDButton>
                        </Grid>
                        <Grid item xs={6} xl={6}>
                            <MDButton
                                variant="gradient"
                                color={'info'}
                                fullWidth
                                onClick={() => copyToAnotherBrandHost()}
                            >
                                Copy
                            </MDButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
