import { Grid, Switch } from "@mui/material";
import { VerticalDivider } from "components/alove/VerticalDivider";
const MDTypography = require("components/MDTypography/index.js").default;

export const SUNotificationsSettings = (props) => {
  return (
    <Grid container gap={5} alignItems="center" flexDirection="row">
      <Switch disabled />
      <MDTypography variant="body2">Email Notification</MDTypography>
      <VerticalDivider />
      <Switch disabled />
      <MDTypography variant="body2">System Notification</MDTypography>
      <VerticalDivider />
      <Switch disabled />
      <MDTypography variant="body2">SMS Notification</MDTypography>
    </Grid>
  );
};
