import axios from "axios";
import { PermissionType } from "models/BoRole";
import { networkGlobals } from "./networkUtils";


/** get list of roles */
export const getRolesDO = async (host,brandID) => {
    try {
        const res = await axios.get(`${networkGlobals.serverHost}/api/roles/?host=${host}&brandIDs=${brandID}`);
        return res?.data?.rows;
    } catch (error) {
        console.error('Error fetching roles:', error);
        throw error;
    }
};

export const filterToMyRoles = (roles, currentUser, currentRole) => {    
    return roles.filter(role => {   
        const managerRole = roles.find(r => r.type == role.type && r.is_manager);

        return role?.id !== 1 &&
            (currentUser?.roles?.[role.brand_id]?.roles?.includes(role?.id) ||
             currentUser?.roles?.[role.brand_id]?.roles?.includes(managerRole?.id) ||
                currentRole?.hasAccess("2.5", PermissionType.Manager))
    })

}

/** get a specific role */
export const getRoleDO = async (host, id) => {
    try {
        const res = await axios.get(`${networkGlobals.serverHost}/api/roles/role/?host=${host}&id=${id}`);
        return res?.data?.rows;
    } catch (error) {
        console.error('Error fetching role:', error);
        throw error;
    }
};

/** get a by array and brand */
export const getRoleByArrayDO = async (host, array, brand) => {
    if (!array?.length) {
        return [];
    }
    try {
        const res = await axios.get(`${networkGlobals.serverHost}/api/roles/rolesByArray/?host=${host}&array=${array}&brand=${brand||networkGlobals.brand.id}`);
        return res?.data?.rows;
    } catch (error) {
        console.error('Error fetching role:', error);
        throw error;
    }
};

/** get pages list */
export const getPagesDO = async (host) => {
    try {
        const res = await axios.get(`${networkGlobals.serverHost}/api/roles/pages/?host=${host}`);
        return res?.data?.rows;
    } catch (error) {
        console.error('Error fetching pages:', error);
        throw error;
    }
};

/** update role */
export const updateRoleDo = async (host, data, id) => {
    console.log('Updating role with data:', data);
    try {
        const res = await axios.put(`${networkGlobals.serverHost}/api/roles/role`, { host, data, id });
        console.log('Update role response status:', res);
        return res?.status;
    } catch (error) {
        console.error('Error updating role:', error);
        throw error;
    }
};

/** update role */
export const updatePermissions = async (host, permissions, id) => {
    try {
        const res = await axios.put(`${networkGlobals.serverHost}/api/roles/permissions`, { host, permissions, id });
        return res?.status;
    } catch (error) {
        console.error('Error updating role:', error);
        throw error;
    }
};

/** delete role */
export const deleteRoleDO = async (host, id) => {
    console.log('Deleting role with id:', id);
    try {
        const res = await axios.post(`${networkGlobals.serverHost}/api/roles/delete`, { host, id });
        console.log('Delete role response status:', res?.status);
        return res?.status;
    } catch (error) {
        console.error('Error deleting role:', error);
        throw error;
    }
};

/** create a new role */
export const postRoleDO = async (host, brandID, data) => {
    try {
        const res = await axios.post(`${networkGlobals.serverHost}/api/roles/`, { host, brandID, data });
        console.log('Create role response status:', res);
        return res?.status;
    } catch (error) {
        console.error('Error creating role:', error);
        throw error;
    }
};

/** get roles types */
export const getRolesType = async (host) => {
    try {
        const res = await axios.get(`${networkGlobals.serverHost}/api/roles/rolesType/?host=${host}`);
        return res?.data?.rows;
    } catch (error) {
        console.error('Error fetching roles type:', error);
        throw error;
    }
};

/** create a new role type*/
export const postRoleTypeDO = async (host, data) => {
    try {
        const res = await axios.post(`${networkGlobals.serverHost}/api/roles/roleType`, { host, data });
        console.log('Create role type:', res);
        return res?.status;
    } catch (error) {
        console.error('Error creating role:', error);
        throw error;
    }
};

/** copy role */
export const copyRoleDO = async (host, brands, id, userID) => {
    try {
        const res = await axios.post(`${networkGlobals.serverHost}/api/roles/copy`, { host, brands, id, userID });
        return res?.status;
    } catch (error) {
        console.error('Error on copy role:', error);
        throw error;
    }
};

