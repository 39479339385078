import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import selectData from "./data/data";
import TextField from "@mui/material/TextField";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";
// import MDDropzone from "components/MDDropzone"; --> for image upload
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import QuestionSettings from "./components/questionsettings";
import Chart from "./components/chart";
import Configuration from "./components/configuration";
import Response from "./components/response";
import Question from "./components/question";
import Buttons from "./components/buttons";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import {
  getQuestionsTypeDO,
  getQuestionsFactorDO,
  getQuestionsDO,
  getQuestionsCategoryDO,
  getQuestionDO,
  saveNewQuestionDO,
  updateQuestionDO,
  getResponseTypeDO,
  questionnarieListDo,
  responsesByQuestionIDDO,
  questionsListDO,
  questionsListWithAnswersDO,
  getAutomaticAnswersDO,
} from "utils/questionsUtils";

import { getBrandsDO } from "utils/brandsUtils";

import { setCurrentBrand } from "context";

import boards from "layouts/applications/kanban/data";
import Board from "@asseinfo/react-kanban";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
import AttributeConf from "./components/attributeConf";
import { Card } from "@mui/material";
import { questionnaires } from "utils/questionsUtils";
import { snakeCaseToCamelCase } from "utils/globalsUtils";
import { networkGlobals } from "utils/networkUtils";

function AddNewQuestion() {
  const navigate = useNavigate();
  let { questionID,bname } = useParams();
  let questionnaireID = questionnaires[snakeCaseToCamelCase(bname || "")];
  questionID = questionID == "new" ? null : questionID;

  const [controller, dispatch] = useMaterialUIController();

  const { auth, currentBrand, currentUser } = controller;

  const [saveLoader, setSaveLoader] = useState(false);

  const [questionsCategory, setQuestionsCategory] = useState([]);

  const [question, setQuestion] = useState({
    questionType: null,
    scale: "",
    category: null,
    valueStep: 1,
    range_max: 9999,
    range_min: 0,
    range_inc: 1,
    questionTexts: {},
    questionTitle: "",
    responseType: null,
    layout: { id: 1, name: "" },
    addToQuestionnarie: [],
    relayToQuestion: [],
    pairWith: "",
    ordinalNewNumber: 0,
    automaticlAnswers: [],
  });

  const [questionForEdit, setQuestionForEdit] = useState([]);
  const [questionResponseForEdit, setQuestionResponseForEdit] = useState([]);
  const [batchQuestionList, setBatchQuestionList] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [factor, setFactor] = useState([]);
  const [responseTypes, setResponseTypes] = useState([]);
  const [addToQuestionarie, setAddToQuestionarie] = useState([]);
  const [snackbar, setSnackbar] = useState({
    status: false,
  });

  const [attribute, setAttribute] = useState(null);

  const [localQuestionsWithAnswers, setLocalQuestionsWithAnswers] = useState([]);

  const closeSnackBar = () =>
    setSnackbar({
      ...snackbar,
      status: false,
      color: "error",
      icon: "error",
      title: "something goes wrong",
    });

  const [singleChoiceDropdownValues, setSingleChoiceDropdownValues] = useState([
    { id: 1, response: "", ordinal: 0, values: { id: 0, name: "Default" }, canDelete: false, isCore: false },
  ]);

  const [inputErrors, setInputErrors] = useState({
    isMinValueError: false,
    isMaxValueError: false,
    questionTextError: false,
    addToQuestionnarie: false,
    questionTypeError: false,
  });

  const minValueErrorMessage = "Minimum number can't be bigger then maximum number";
  const maxValueErrorMessage = "Maximum number is 999";
  const stepValueErrorMessage = "Step can't be bigget then half of maximum";

  const [responseSwitcher, setResponseSwitcher] = useState({
    linear: true,
    displaySelectedValue: false,
    displayMinMaxValues: false,
    reversedItem: false,
    displayOnUserProfile: false,
    userDecideIfDisplayOnProfile: false,
    preferNotSay: false,
    openToAll: false,
    iDontMind: false,
  });

  const [configSwitcher, setConfigwitcher] = useState({
    isCoreQuestion: false,
    isEnableSkip: false,
    isExcludeLearning: false,
    ignorePref: false,
    dealBraker: false,
  });

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    if (!currentBrand?.id) return
    if (questionID) {
      getQuestion();
    } else {
      getFirstData();
    }
    if (questionnaireID) {
    }
   

  }, [currentBrand?.id]);

  const addAutoResponse = (questionID, responseID) => {
    console.log("questionID: ", questionID);
    console.log("responseID: ", responseID);
    console.log("addAutoResponse: ", question?.automaticlAnswers);

    const newAutomatcAnswers = [...question?.automaticlAnswers];
    newAutomatcAnswers.push({
      id: Math.floor(Math.random() * 10) + 1,
      question_id: questionID,
      answer_id: responseID,
      relay_to_reponse_id: responseID,
      relay_to_question_id: questionID,
    });
    setQuestion({ ...question, automaticlAnswers: newAutomatcAnswers });
  };

  const removeAutoResponse = (id) => {
    console.log("in remove");
    console.log("id: ", id);
  };

  async function prepareSingleChoiseResponsesList(responses, questionData) {
    console.log("--=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
    console.log("responses from prepare : ", responses);
    console.log("questionData from prepare : ", questionData);
    console.log("questionData[0]?.response_type: ", questionData[0]?.response_type);
    if ([1,2, 3, 8].includes(questionData[0]?.response_type)) {
      // if (questionData[0]?.response_type === 3) {
      console.log("in if");
      let buildResponses = [];
      for (const response of responses) {
        console.log("response from prepare: ", response);
        console.log("response.type: ", response?.type);
        const preBehavior = selectData?.responseBehavior?.find((rb) => rb?.id === response?.type);
        const behavior = preBehavior?.id ? preBehavior : { id: 0, name: "Default" };
        console.log("behavior: ", behavior);
        buildResponses.push({
          id: response?.id,
          response: response?.txt,
          ordinal:response?.ordinal,
          values: behavior,
          canDelete: true,
          isCore: response?.is_core,
          txtID: response?.txt_id,
        });
      }
      console.log("responses after build: ", buildResponses);
      setSingleChoiceDropdownValues(buildResponses);
    }
  }

  /** update values in response */
  async function updateResponseSingleChoiceDropdownValues(id, value) {
    console.log("id: ", id);
    console.log("value: ", value);
    const index = singleChoiceDropdownValues.findIndex((obj) => obj.id === id);
    const updatedItems = [...singleChoiceDropdownValues];
    updatedItems[index] = {
      ...updatedItems[index],
      ...value,
    };
    updatedItems.sort((a, b) => a.ordinal - b.ordinal);
    setSingleChoiceDropdownValues(updatedItems);
  }

  /** add more responses for scale range */
  async function addMoreResponseSingleChoiceDropdownValues() {
    console.log("----------");
    const id = "new" + Math.floor(1000 + Math.random() * 15000);
    const cloneResponse = [...singleChoiceDropdownValues];
    console.log("cloneResponse: ", cloneResponse);

    const newResponse = { id, response: null, canDelete: true, isCore: false };
    console.log("newResponse: ", newResponse);

    cloneResponse.push(newResponse);
    console.log("cloneResponse after: ", cloneResponse);
    setSingleChoiceDropdownValues(cloneResponse);
  }

  /** remove response from scale range */
  async function removeResponseSingleChoiceDropdownValues(id) {
    const newArray = [...singleChoiceDropdownValues];
    const place = singleChoiceDropdownValues.findIndex((i) => i.id === id);
    newArray.splice(place, 1);
    setSingleChoiceDropdownValues(newArray);
  }

  /**get question */
  async function getQuestion() {
    const questionsType = await getQuestionsTypeDO();
    setQuestionTypes(questionsType);

    /** load response types */
    const responseTypes = await getResponseTypeDO();
    setResponseTypes(responseTypes);

    /** load questionarie list  */
    const questionnarieList = await questionnarieListDo();
    setAddToQuestionarie(questionnarieList);

    const realBrands = await getBrandsDO();
    setBrands(realBrands);

    questionnaireID ||= questionnarieList.find((q) => q?.name === snakeCaseToCamelCase(bname || ""))?.id;

    const questionData = await getQuestionDO(questionID, questionnaireID);
    console.log("questionData: ", questionData);
    setQuestionForEdit(questionData);

    const responses = await responsesByQuestionIDDO(questionData[0]?.id);
    console.log("question responses: ", responses);
    if (responses && questionData) {
      prepareSingleChoiseResponsesList(responses, questionData);
    }

    const qc = await getQuestionsCategoryDO()
    setQuestionsCategory(qc)
    // setQuestionResponseForEdit(responses);

    /** get and set to globals automatic answers */
    const automaticAnswers = await getAutomaticAnswersDO(questionID);
    console.log("automaticAnswers: ", automaticAnswers);
    setQuestion({ ...question, automaticlAnswers: automaticAnswers });

    let belongsToQuestionarie;

    /** set brand by edited question */
    if (realBrands.length && questionData?.length) {
      const questionBrand = realBrands.find((brand) => brand?.id == questionData[0]?.brand_id);
      setCurrentBrand(dispatch, { id: questionBrand?.id, name: questionBrand?.name });
    }

    /** set add to questionarie */
    if (questionnarieList.length && questionData?.length) {
      belongsToQuestionarie = questionnarieList.find(
        (question) => question?.id == questionData[0]?.batch_id
      );
    }

    const questionTexts = questionData[0]?.questionTexts || {};

    const questionsWithAnswers = await questionsListWithAnswersDO(
      questionnaireID
    );
    console.log("questionsWithAnswers: ", questionsWithAnswers);
    setLocalQuestionsWithAnswers(questionsWithAnswers);

    /** check if loaded/load responses and show them */
    if (questionData?.length && responseTypes?.length) {
      const currentResponseType = responseTypes.find(
        (r) => r.id === questionData[0]?.response_type
      );
      const currentLayout = selectData.layout.find((l) => l.id === questionData[0]?.layout_id);
      const reponseType = questionsType.find((q) => q.id === questionData[0]?.question_type);
      const category = qc.find(
        (c) => c.id == questionData[0]?.question_category_id
      );
      console.log("currentLayout: ", currentLayout);

      setQuestion({
        ...question,
        questionType: reponseType,
        responseType: currentResponseType,
        category: category,
        layout: currentLayout,
        addToQuestionnarie: [belongsToQuestionarie?.id],
        questionTexts,
        automaticlAnswers: automaticAnswers,
        vis_settings: questionData[0]?.vis_settings,
        response_settings: questionData[0]?.response_settings,
        range_max: questionData[0]?.range_max,
        range_min: questionData[0]?.range_min,
        range_inc: questionData[0]?.range_inc,
        default_response: questionData[0]?.default_response,
        pre_validation: questionData[0]?.pre_validation,
        bcid: questionData[0]?.bcid,
        is_deal_breaker: questionData[0]?.is_deal_breaker,
        scoring_type_id: questionData[0]?.scoring_type_id,
        weight: questionData[0]?.weight,
        importance: questionData[0]?.importance,
        used_for_scoring: questionData[0]?.used_for_scoring,
        txt_id: questionData[0]?.txt_id,
        skip_allowed: questionData[0]?.skip_allowed,
        attribute:questionData[0].attribute,
        tolerance: questionData[0]?.tolerance,
        is_reversed: questionData[0]?.is_reversed,
        isInternal: questionData[0]?.isInternal,
      });

      setConfigwitcher({
        ...configSwitcher,
        isCoreQuestion: questionData[0]?.is_core,
        isEnableSkip: questionData[0]?.skip_allowed,
      });

      if (questionData[0].attribute) {
        setAttribute({
          ...questionData[0].attribute,
        });
      }
    }
  }

  async function getFirstData() {
    const responseTypes = await getResponseTypeDO();
    setResponseTypes(responseTypes);

    const questionsType = await getQuestionsTypeDO();
    setQuestionTypes(questionsType);

    const questionsFactor = await getQuestionsFactorDO();
    setFactor(questionsFactor);


   
    /** load it second time if it's still empty */
    let defineQuestionarie;
    if (!addToQuestionarie?.length) {
      console.log("in if !addToQuestionarie?.length");
      console.log("currentBrand?.id: ", currentBrand?.id);
      const questionnarieList = await questionnarieListDo();
      console.log("questionnarieList: ".questionnarieList);
      setAddToQuestionarie(questionnarieList);
      console.log("here in defineQuestionarie");
      const questionnaireData = questionnarieList.find((q) => q?.id === questionnaireID);
      console.log("questionnaireData: ", questionnaireData);
      if (questionnaireData) {
        defineQuestionarie = [{ id: questionnaireData?.id, name: questionnaireData?.name }];
        console.log("in set");
        // setQuestion({ ...question, addToQuestionnarie: [{ id: questionnaireData?.id, name: questionnaireData?.name }] })
        console.log("defineQuestionarie2: ", defineQuestionarie);
      }
    }
    /** get and prepare question list */
    const questionsList = await questionsListDO(questionnaireID);
    let newQuestionList = [];
    if (questionsList) {
      console.log(
        "questionsList[questionsList?.length]?.ordinal: ",
        questionsList[questionsList?.length - 1]?.ordinal + 1
      );
      console.log("defineQuestionarie3; ", defineQuestionarie);
      if (defineQuestionarie) {
        console.log("defineQuestionarie4: ", defineQuestionarie);
        setQuestion({
          ...question,
          ordinalNewNumber: questionsList[questionsList?.length - 1]?.ordinal + 1,
          addToQuestionnarie: defineQuestionarie,
        });
      }
      for await (const question of questionsList) {
        if (question?.question_id !== null) {
          newQuestionList.push({ id: question?.question_id, name: question?.text });
        }
      }
      setBatchQuestionList(newQuestionList);
    }

    const questionsWithAnswers = await questionsListWithAnswersDO(
      questionnaireID
    );
    console.log("questionsWithAnswers: ", questionsWithAnswers);
    setLocalQuestionsWithAnswers(questionsWithAnswers);
  }

  const updateQuestion = async () => {
    setSaveLoader(true);
    const data = {
      questionForEdit,
      question,
      responseSwitcher,
      configSwitcher,
      singleChoiceDropdownValues,
      user: currentUser?.id,
      singleChoiceDropdownValues,
      attribute,
    };
    console.log("data: ", data);
    const updateStatus = await updateQuestionDO(
      questionID,
      data,
    );
    if (updateStatus === 200) {
      console.log("in status 200");
      await setSnackbar({
        ...snackbar,
        status: true,
        color: "success",
        icon: "check",
        title: "question was updated",
      });
      getQuestion();
      setTimeout(() => {
        setSaveLoader(false);
      }, 500);
    } else {
      console.log("in else");
      await setSnackbar({
        ...snackbar,
        status: true,
        color: "error",
        icon: "error",
        title: "something goes wrong",
      });
      setSaveLoader(false);
    }
  };

  function fillRequiredFields() {
    setSnackbar({
      ...snackbar,
      status: true,
      color: "error",
      icon: "error",
      title: "Fill required fields",
    });
  }

  async function saveNewQuestion() {
    setInputErrors({ ...inputErrors, questionTextError: false });
    setInputErrors({ ...inputErrors, questionAdditionalTextError: false });
    console.log("in save new question");
    const data = { question, responseSwitcher, configSwitcher, singleChoiceDropdownValues };
    // const data = { ...question, ...responseSwitcher, ...configSwitcher, ...singleChoiceDropdownValues };
    inputErrors.questionTextError = !question.questionTexts["txt_id"]
  
    inputErrors.questionTypeError = !question.questionType
  
    inputErrors.addToQuestionnarie = question.addToQuestionnarie.length === 0

    inputErrors.responseTypeError = !question.responseType

  
    setInputErrors({ ...inputErrors });
    if (Object.values(inputErrors).includes(true)) {
      return fillRequiredFields();
    }

    console.log("current user: ", currentUser?.id);
    console.log("current brand: ", currentBrand?.id);
    console.log("data: ", data);
    setSaveLoader(true);
    const saveStatus = await saveNewQuestionDO(
      data
    );
    if (saveStatus === 201) {
      setSaveLoader(false);
      await setSnackbar({
        ...snackbar,
        status: true,
        color: "success",
        icon: "check",
        title: "new question was added",
      });
      navigate(-1)
    } else {
      setSaveLoader(false);
      await setSnackbar({
        ...snackbar,
        status: true,
        color: "error",
        icon: "error",
        title: "something went wrong",
      });
    }
  }

  // async function getProfilesList() {
  //     const profilesList = await getProfilesListDO(host);
  //     setProfilesListTableData(profilesList);
  // }

  function showAnswers() {
    console.log("singleChoiceDropdownValues: ", singleChoiceDropdownValues);
    console.log("question?.automaticlAnswers: ", question?.automaticlAnswers);
  }

  function createAttribute() {
    const attributeKey =
      Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5);
    setAttribute({
      name: attributeKey,
      related_batch_id: questionnaireID
    });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Header */}
      {question?.ordinal}
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} xl={9}>
          <MDBox pt={0} pb={0}>
            {/* <Card> */}
            <MDBox lineHeight={2}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} xl={6.5}>
                  {question?.questionTitle == ""
                    ? questionID
                      ? "Edit question"
                      : "New question"
                    : question?.questionTitle}
                  {/* {questionID ? 'Edit ' : 'New '} Question */}
                  {/* <MDTypography variant="caption" fontWeight="bold" color="text"> */}
                  &nbsp;#{questionID}&nbsp; {question?.questionText}
                  {/* </MDTypography> */}
                  <MDTypography variant="caption" fontWeight="bold" color="text">
                    &nbsp;(Name - not displayed in app)
                  </MDTypography>
                </Grid>
                <Grid item xs={6} xl={0.5}>
                  <Tooltip title={"Back to question list"} placement="top">
                    <IconButton disableRipple size="small" 
                     onClick={() => navigate(`/questionList?id=${Number(questionnaireID)}`)}>
                      <KeyboardBackspaceIcon
                        // sx={{ color: "#27b" }}
                       
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} xl={0.5}>
                  <Tooltip title={"Refresh page"} placement="top">
                    <IconButton disableRipple size="small"
                    onClick={() => (questionID ? getQuestion() : getFirstData())}>
                      <RefreshIcon
                        // sx={{ color: "#27b" }}
                        
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} xl={2.5}></Grid>
                <Grid item xs={12} xl={2} sx={{ textAlign: "right" }}>
                  <Autocomplete
                    isOptionEqualToValue={(positions, value) => positions.name === value.name}
                    defaultValue={selectData.language[0]}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                      setQuestion({ ...question, questionType: newValue });
                    }}
                    size="small"
                    // id="controllable-states-demo"
                    options={selectData.language}
                    renderInput={(params) => <TextField {...params} label="Language" />}
                  />
                </Grid>
              </Grid>
            </MDBox>
            {/* </Card> */}
          </MDBox>
        </Grid>
      </Grid>

      {/* Questions switcher */}
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} xl={9}>
          <QuestionSettings
            question={question}
            setQuestion={setQuestion}
            questionTypes={questionTypes}
            inputErrors={inputErrors}
            factor={factor}
            responseTypes={responseTypes}
            questionID={questionID}
            questionForEdit={questionForEdit}
            questionsCategory={questionsCategory}
          />

          <Question
            question={question}
            inputErrors={inputErrors}
            setQuestion={setQuestion}
            addToQuestionarie={addToQuestionarie}
            attribute={attribute}
            responses={singleChoiceDropdownValues}
          />
        </Grid>

        <Grid item xs={12} xl={3}>
          <Configuration
            configSwitcher={configSwitcher}
            setConfigwitcher={setConfigwitcher}
            question={question}
            setQuestion={setQuestion}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">
        {/* Response */}
        <Grid item xs={12} xl={9}>
          <Response
            question={question}
            setQuestion={setQuestion}
            responseSwitcher={responseSwitcher}
            setResponseSwitcher={setResponseSwitcher}
            // responsesText={responsesText}
            singleChoiceDropdownValues={singleChoiceDropdownValues}
            updateResponseSingleChoiceDropdownValues={updateResponseSingleChoiceDropdownValues}
            addMoreResponseSingleChoiceDropdownValues={addMoreResponseSingleChoiceDropdownValues}
            removeResponseSingleChoiceDropdownValues={removeResponseSingleChoiceDropdownValues}
            questionResponseForEdit={questionResponseForEdit}
            questionID={questionID}
            batchQuestionList={batchQuestionList}
            localQuestionsWithAnswers={localQuestionsWithAnswers}
            addAutoResponse={addAutoResponse}
            removeAutoResponse={removeAutoResponse}
          />
        </Grid>

        {/* graph */}
        <Grid item xs={12} xl={3}>
          <Chart />
        </Grid>
        <Grid item sx={12} xl={9}>
          {" "}
          {questionID && <MDBox pt={2} pb={3}>
            <Card>
              {attribute ? (
                <AttributeConf
                  attribute={attribute}
                  setAttribute={setAttribute}
                  question={question}
                  setQuestion={setQuestion}
                />
              ) : (
                <Grid
                  p={2}
                  gap={2}
                  justifyContent="center"
                  alignItems="center"
                  container
                  flexDirection="column"
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    No attribute connected to this question
                  </MDTypography>
                  <MDButton onClick={createAttribute} variant="outlined" color="primary">
                    Create Attribute
                  </MDButton>
                </Grid>
              )}
            </Card>
          </MDBox>}
        </Grid>

        {/* bottom buttons */}
        <Grid container spacing={3} alignItems="center">
          <Buttons
            updateQuestion={updateQuestion}
            saveNewQuestion={saveNewQuestion}
            questionID={questionID}
            saveLoader={saveLoader}
            question={question}
            questionForEdit={questionForEdit}
            getQuestion={getQuestion}
            showAnswers={showAnswers}
            currentUser={currentUser?.id}
          />
        </Grid>
      </Grid>

      <MDSnackbar
        color={snackbar?.color || ""} //"success"
        icon={snackbar?.icon || ""} //"check"
        title={snackbar?.title} //"Question was saved"
        content=""
        dateTime=""
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}
export default AddNewQuestion;
