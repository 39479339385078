import { Autocomplete, Card, Grid, Switch, TextField } from "@mui/material";
import { addAlert, useMaterialUIController } from "context";
import React from "react";
import { updateUserDO } from "utils/systemUsersUtils";
const MDButton = require("components/MDButton/index.js").default;
const MDInput = require("components/MDInput/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const SMEBasic = ({
  formData,
  setFormData,
  inputErrors,
  updateAttribute,
  isAdmin,
  withTitle = false,
  canEdit = false,
  isNew = false,
  showPassword = true,
  showBirthdate = false,
  csHidden = false,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const [password, setPassword] = React.useState("");
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    inputErrors.email = re.test(email) ? "" : "Invalid email";
    setFormData({ ...formData, email });
  };

  const validatePhone = (phone) => {
    const re = /^[\+\-\(\)\d]{9,}$/;
    inputErrors.phone = re.test(phone) ? "" : "Invalid phone";
    setFormData({ ...formData, phone });
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);
  };

  const resetPassword = async () => {
    await updateUserDO("dev", { password, id: formData.id });
    addAlert(dispatch, "Password updated");
    setPassword("");
  };

  return (
    <Grid container spacing={2} alignItems="start">
      {withTitle && (
        <Grid item xs={2}>
          <Autocomplete
            fullWidth
            disabled={!canEdit}
            placeholder="Mr., Ms. Mrs. etc.."
            options={['Mr.', 'Mrs.', 'Miss.', 'Ms.', 'Dr.', 'Prof.', 'Rabbi', 'Reb.']}
            value={formData?.attributes?.title}
            onChange={(e, value) => updateAttribute("attributes.title", value)}
            renderInput={(params) => <MDInput {...params} variant="outlined" label="Title" />}
          />
        </Grid>
      )}
      <Grid item xs={3}>
        <MDInput
          fullWidth
          disabled={!canEdit}
          label="First name"
          value={formData?.firstName || ""}
          onChange={(e) => {
            setFormData({ ...formData, firstName: e?.target?.value });
            inputErrors.firstName = !e?.target?.value;
          }}
          error={inputErrors?.firstName}
        />
      </Grid>
      <Grid item xs={3}>
        <MDInput
          fullWidth
          disabled={!canEdit}
          label="Last name"
          value={formData?.lastName || ""}
          onChange={(e) => {
            setFormData({ ...formData, lastName: e?.target?.value });
            inputErrors.lastName = !e?.target?.value;
          }}
          error={inputErrors?.lastName}
        />
      </Grid>
      <Grid item xs={3}>
        <MDInput
          label={"Email address" + (!canEdit ? " (Contact admin to change)" : "")}
          value={formData?.email || ""}
          disabled={!canEdit}
          onChange={(e) => validateEmail(e?.target?.value)}
          error={inputErrors?.email}
          fullWidth
        />
      </Grid>
      {csHidden && (
        <Grid item xs={2} container alignItems="center">
          <Switch
            checked={formData?.hidden_from_cs || false}
            onChange={(e) => updateAttribute("hidden_from_cs", e?.target?.checked)}
          />
          <MDTypography variant="body2">Hidden from CS</MDTypography>
        </Grid>
      )}
      <Grid item xs={3}>
        <MDInput
          label={"Phone number" + (!canEdit ? " (Contact admin to change)" : "")}
          value={formData?.phone || ""}
          disabled={!canEdit}
          onChange={(e) => validatePhone(e?.target?.value)}
          error={inputErrors?.phone}
          fullWidth
        />
      </Grid>
      {showBirthdate && (
        <Grid item xs={3}>
          <MDInput
            label="Birthdate"
            value={formData?.birthdate || ""}
            disabled={!canEdit}
            type="date"
            onChange={(e) => updateAttribute("birthdate", e?.target?.value)}
            error={inputErrors?.birthdate}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={3}>
        <MDInput label="Language" value="English" disabled fullWidth />
      </Grid>
      {showPassword && (
        <Grid item xs={3}>
          <MDInput
            label={"Password"}
            placeholder="Type new password"
            type="password"
            inputProps={{ autoComplete: "new-password" }}
            disabled={!canEdit}
            value={isNew ? formData?.password : password}
            onChange={
              isNew
                ? (e) => {
                    setFormData({ ...formData, password: e?.target?.value });
                    inputErrors.password = !e?.target?.value;
                  }
                : passwordChange
            }
            fullWidth
            error={inputErrors?.password}
          />
        </Grid>
      )}
      {!isNew && showPassword && (
        <Grid item xs={2}>
          <MDButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={resetPassword}
            disabled={password === ""}
          >
            Update Password
          </MDButton>
        </Grid>
      )}
    </Grid>
  );
};
