import { GridColDef } from "@mui/x-data-grid";
import { TablePage } from "components/alove/TablePage";
import { getAttributes} from "utils/attributesUtil";

const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

export const AttributesList = () => {
  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
    },
    {
        headerName: "Category",
        field: "attribute_category",
        flex: 1,
    },
    {
        headerName: "Group",
        field: "attribute_group",
        flex: 1,
    },
    {
        headerName: "Questions",
        field: "questions",
        renderCell: (params) => {
            return params.row.questions.map((q: any) => q.id).join(", ");
        },
        flex: 1,
    }

  ];
  const loadNext = async (host: string, filters: any, page: number) => {
    if (!filters["brand"]) return []

    const response = await getAttributes(host, filters["brand"])
    return response;
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TablePage
        title="Attributes"
        table={{
          columns,
          loadNext,
        }}
      />
    </DashboardLayout>
  );
};
