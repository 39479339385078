import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { VerticalDivider } from "./VerticalDivider";

const { T } = require("components/alove/Translator");

const { Grid, Icon, Divider, Card } = require("@mui/material");
const MDTypography = require("components/MDTypography/index.js").default;

export interface StatsPanelProps {
  fields: {
    name: string;
    title: string;
    color?: string;
    filterLabel?: string;
    filterValue?: string;
    onClick?: () => void;
  }[];

  title: string;
  stats: any;
  mb?:number
  filterClicked?: (label: string, value: string) => void;
}

export const StatsPanel = (props: StatsPanelProps): JSX.Element => {
  const { fields, title, stats, filterClicked,mb=1 } = props;

  return (
    <Card sx={{ px: 4, mb: mb }} style={{ width: "100%" }}>
      <Grid container alignItems="center" justifyContent={"space-between"}>
        <Grid item sx={{ my: 4 }}>
          <MDTypography variant="h5" style={{ width: "250px" }}>
            <T>{title}</T>
          </MDTypography>
        </Grid>
        {fields.map((field) => [
          <VerticalDivider key={field.name + "d"} />,
          <Grid
            key={field.name}
            item
            flexDirection="row"
            alignItems="center"
            display="flex"
            gap={1}
            onClick={() =>
              filterClicked ? filterClicked(field.filterLabel!, field.filterValue!) : null
            }
            style={{ cursor: field.onClick ? "pointer" : "default" }}
          >
            <MDTypography
              color={field.color}
              fontWeight="bold"
              style={{ lineHeight: "normal" }}
              variant="h1"
            >
              {parseFloat(parseFloat(stats[field.name]).toFixed(1)) || 0}
            </MDTypography>
            <MDTypography
              color={field.color}
              width="150px"
              style={{ lineHeight: "normal", fontSize: "16px" }}
            >
              <T>{field.title}</T>
            </MDTypography>
          </Grid>,
        ])}
        {new Array(4 - fields.length)
          .fill(0)
          .map((a,i) => [<VerticalDivider hidden key={"d"+i} />, <Grid item width="150px" key={i + "1"} />])
          .flat()}
      </Grid>
    </Card>
  );
};
