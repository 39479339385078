import { AloveDialog, DialogType } from "components/alove/AloveDialog"
import { addAlert, useMaterialUIController } from "context";
import { useState } from "react";
import { mmAction } from "utils/matchmakerUtils";

const MDInput = require("components/MDInput/index.js").default;

export const ForgotPasswordDialog = (props) => {
    const [email, setEmail] = useState('');
    const [controller, dispatch] = useMaterialUIController();


    const handleConfirm = async () => {
        await mmAction(null, 'resetPassword', null, email);
        addAlert(dispatch,'forgotPasswordSuccess');

    }


    return (
        <AloveDialog
            dialogType={DialogType.ForgotPassword}
            dialogTitle='forgotPasswordTitle'
            dialogBody='forgotPasswordBody'
            dialogConfirm='send'
            handleConfirm={handleConfirm}
            valid={/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
        >
            <MDInput
                label='Email'
                type='email'
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
        </AloveDialog>
    )
}