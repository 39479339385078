import { Autocomplete, Card, Divider, Grid } from "@mui/material";
import { GeneralCode, GeneralCodeType } from "models/GeneralCode";
import { appPages, NextScreenEvent, ScreenEvent, ScreenModel, ScreenType } from "models/Screen";
import { useEffect, useState } from "react";
import { getGeneralCodes } from "utils/generalCodesUtils";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { getScreens } from "utils/screenUtils";
const MDInput = require("components/MDInput/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const ScreenWorkflow = ({ screen, setScreen }: { screen: ScreenModel; setScreen: any }) => {
  const [screens, setScreens] = useState([] as ScreenModel[]);
  useEffect(() => {
    getScreens().then((screens) => setScreens(screens));
  }, []);

  return (
    <Grid container flexDirection="row" justifyContent="start" alignItems="center" gap={2}>
      <Grid item xs={2}>
        <MDTypography variant="body2">Trigger Event</MDTypography>
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          value={screen.event || ScreenEvent.afterApprove}
          fullWidth
          disableClearable
          getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
          onChange={(e, o) => setScreen({ ...screen, event: o })}
          options={Object.values(ScreenEvent).filter((key) => isNaN(Number(key)))}
          renderInput={(params) => <MDInput {...params} label="Trigger Event" />}
        />
      </Grid>
      <Grid item xs={3}>
        <MDInput
          fullWidth
          label="Param"
          value={screen.param || ""}
          onChange={(e: any) => setScreen({ ...screen, param: e.target.value })}
        />
      </Grid>
      <Divider style={{ width: "100%" }} />

      <Grid item xs={2}>
        <MDTypography variant="body2">Next Event</MDTypography>
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          value={screen.nextEventType || ""}
          fullWidth
          disableClearable
          getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
          onChange={(e, o) => setScreen({ ...screen, nextEventType: o })}
          options={Object.values(NextScreenEvent).filter((key) => isNaN(Number(key)))}
          renderInput={(params) => <MDInput {...params} label="Event Type" />}
        />
      </Grid>
      {screen.nextEventType === NextScreenEvent.genericScreen ? (
        <Grid item xs={3}>
          <Autocomplete
            value={screens.find((s) => s.id == screen.actionUrl) || ({} as ScreenModel)}
            fullWidth
            disableClearable
            getOptionLabel={(option: ScreenModel) => option.name}
            onChange={(e, o) =>
              setScreen({ ...screen, actionUrl: o.id })
            }
            options={screens}
            renderInput={(params) => <MDInput {...params} label="Next Screen" />}
          />
        </Grid>
      ) : (
        <Grid item xs={3}>
          <Autocomplete
            value={screen.actionUrl || ""}
            fullWidth
            freeSolo
            disableClearable
            getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
            onChange={(e, o) => setScreen({ ...screen, actionUrl: o })}
            options={appPages}
            renderInput={(params) => <MDInput {...params} label="Action URL" />}
          />
        </Grid>
      )}  <Divider style={{ width: "100%" }} />

      <Grid item xs={2}>
        <MDTypography variant="body2">Next Event 2</MDTypography>
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          value={screen.nextEventType2 || ""}
          fullWidth
          disableClearable
          getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
          onChange={(e, o) => setScreen({ ...screen, nextEventType2: o })}
          options={Object.values(NextScreenEvent).filter((key) => isNaN(Number(key)))}
          renderInput={(params) => <MDInput {...params} label="Event Type 2" />}
        />
      </Grid>
      {screen.nextEventType === NextScreenEvent.genericScreen ? (
        <Grid item xs={3}>
          <Autocomplete
            value={screens.find((s) => s.id == screen.actionUrl2) || ({} as ScreenModel)}
            fullWidth
            disableClearable
            getOptionLabel={(option: ScreenModel) => option.name}
            onChange={(e, o) =>
              setScreen({ ...screen, actionUrl2: o.id })
            }
            options={screens}
            renderInput={(params) => <MDInput {...params} label="Next Screen 2" />}
          />
        </Grid>
      ) : (
        <Grid item xs={3}>
          <Autocomplete
            value={screen.actionUrl || ""}
            fullWidth
            freeSolo
            disableClearable
            getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
            onChange={(e, o) => setScreen({ ...screen, actionUrl: o })}
            options={appPages}
            renderInput={(params) => <MDInput {...params} label="Action URL" />}
          />
        </Grid>
      )}
    </Grid>
  );
};
