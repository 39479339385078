import axios from "axios";
import { visibiltyOptions } from "./attributesUtil";
import { getLambadaSubdomain, networkGlobals } from "./networkUtils";
import { IntroductionStatus } from "models/Introduction";
import { MMStatuses } from "models/Matchmaker";

export const statusColors = {
  5: "primary",
  8: "error",
  9: "primary",
  10: "primary",
  11: "primary",
  12: "primary",
  13: "success",
  14: "error",
  15: "error",
  16: "error",
};

export const shortName = (fullName) =>
  fullName
    .split(" ")
    .map((n) => n[0])
    .join(". ");

export const fullName = (user, status, isAdmin) => {
  const name =
    (user?.first_name || user?.firstName) + " " + (user?.last_name || user?.lastName || "");
  return status == STATUSES.NEW && !isAdmin ? shortName(name) : name;
};

export enum STATUSES {
  NEW = 5,
  AVAILABILE = 9,
  TAKING_ON = 10,
  CONTACTED = 11,
  ANSWERS_RECORDED = 12,
  DATING = 13,
  ENGAGED = 14,
  BUSY = 15,
  ENDED = 16,
}

export const statusWithAction = [
  IntroductionStatus.MATCHED,
  IntroductionStatus.MM_AVAILABLE,
  IntroductionStatus.MM_TAKING_ON,
  IntroductionStatus.MM_CONNECTED,
  IntroductionStatus.MM_RECORDED,
  IntroductionStatus.DATING,
  IntroductionStatus.ON_HOLD,
];

export const HIDDEN_ATTRIBUTES = ["gender", "birthdate", "livingPlace", "height"];

export const shouldColorBackground = (request) =>
  [IntroductionStatus.MATCHED, IntroductionStatus.MM_AVAILABLE].includes(request.status) &&
  (request.statusReason?.initiatorStatus || request.statusReason?.responderStatus);

const makeRequest = (
  url: string,
  options: { method?: string; body?: any; auth?: boolean } = {}
) => {
  const { method, body, auth = true } = options;
  return axios({
    method: method || "GET",
    url,
    data: body,
    headers: auth
      ? {
          Authorization: localStorage.getItem("BOToken"),
        }
      : {},
  })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response && err.response.status === 401 || err.code == "ERR_NETWORK") {
        localStorage.removeItem("BOToken");
        window.location.href = "#"
        return;
      }
      console.log(err);
      networkGlobals.errorCallbacks.forEach((cb) => cb(err));
    });
};

export const isUrget = (request) =>
  ![STATUSES.DATING, STATUSES.ENGAGED, STATUSES.ENDED, STATUSES.ANSWERS_RECORDED].includes(
    request.status
  ) && new Date(request.mustRespondUntil) < new Date(new Date().getTime() + 24 * 60 * 60 * 500);

const MMServicePATH = () => `https://${getLambadaSubdomain()}.algoaitech.io/matchmakers/`;

export const getShidduchRequests = async (page, status, from, to, matchmakerId, shidduchId) =>
  makeRequest(
    MMServicePATH() +
      `introductions?mmStatuses=true&brandId=${networkGlobals.brand.id}&page=${page}&status=${status}&before=${to}&matchmakerId=${matchmakerId}&after=${from}&introId=${shidduchId}`
  );
export const getShidduchById = async (id) => makeRequest(MMServicePATH() + `introductions/${id}`);

export const addMMComment = async (id, comment) =>
  makeRequest(MMServicePATH() + `introductions/${id}/comment`, {
    method: "POST",
    body: { comment },
  });

export const updateIntroStatus = async (id, status, extra?) =>
  makeRequest(MMServicePATH() + `introductions/${id}/status`, {
    method: "POST",
    body: { status, ...extra },
  });

export const declineIntroduction = (id, declineReason, nextMM?) =>
  makeRequest(MMServicePATH() + `introductions/${id}/decline`, {
    method: "PUT",
    body: { declineReason, nextMM },
  });

export const getMMList = async ({
  statusFilter,
  page,
  withDeleted,
  user,
}: {
  statusFilter?: MMStatuses;
  page?: number;
  withDeleted?: boolean;
  user?: string;
}) =>
  makeRequest(
    MMServicePATH() +
      `admin/matchmakers?page=${page || 0}&brandID=${networkGlobals.brand.id}&` +
      (user ? `user=${user}&` : "") +
      (withDeleted ? `withDeleted=true&` : "") +
      (statusFilter ? `&status=${statusFilter}` : "")
  );

export const getAdminStats = async () =>
  makeRequest(MMServicePATH() + `admin/stats?brandID=${networkGlobals.brand.id}`);

export const getSMEStats = async () =>
  makeRequest(MMServicePATH() + `admin/matchmakers/stats?brandID=${networkGlobals.brand.id}`);

export const getSMEProfile = async (id) => makeRequest(MMServicePATH() + `matchmakers/${id}`);

export const getMMForm = async (): Promise<any> =>
  makeRequest(MMServicePATH() + `mmForm`, { auth: false });

export const mmAction = async (id, action, declineReason, email?,profileId?) =>
  makeRequest(MMServicePATH() + `private/action`, {
    method: "POST",
    body: { id, action, declineReason, email, profileId },
  });

export const updateMM = async (id, data) =>
  makeRequest(MMServicePATH() + `matchmakers/${id}`, { method: "PUT", body: data });

export const getSinglMMStats = async (id) =>
  makeRequest(MMServicePATH() + `admin/matchmakers/${id}/stats`);
