import CardItem from "components/alove/CardItem";
import ItemPageContainer from "layouts/itemPageContainer";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { Introduction, IntroductionStatus } from "models/Introduction";
import { useEffect, useState } from "react";
import { doAction, OptionsMenu, RequiredAction } from "./components/requiredAction";
import { MMProgress } from "./components/mmProgress";
import { AttributesTable } from "../../introductions/viewIntroduction/attributesTable";
import { ContactDetails } from "./components/contactDetails";
import { useNavigate, useParams } from "react-router-dom";
import { fullName, getShidduchById, statusWithAction } from "utils/matchmakerUtils";
import { formatDate, snakeCaseToCamelCaseAttributes } from "utils/globalsUtils";
import { MMComments, MMLogs } from "./components/logs";
import { ProfileCards } from "../../introductions/viewIntroduction/profileCard";
import { useMaterialUIController } from "context";
import { PermissionType } from "models/BoRole";
import { Profile } from "models/Profile";
import { EndUser } from "models/EndUser";

const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

const ShidduchRequestView = () => {
  const pageNumber = "7.2";
  const [shRequest, setShRequest] = useState(new Introduction());
  const [loading, setLoading] = useState(true);
  const [openReassign, setOpenReassign] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;
  const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.Manager);

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    getShById();
  }, [id]);

  const getShById = async () => {
    const shidduch = await getShidduchById(id);
    if (!shidduch) {
      navigate("/shidduch-requests");
      return;
    }
    const intro: Introduction = Object.assign(new Introduction(), shidduch);
    intro.initiatorProfile = Object.assign(
      new Profile(),
      snakeCaseToCamelCaseAttributes(intro.initiatorProfile)
    );
    intro.responderProfile = Object.assign(
      new Profile(),
      snakeCaseToCamelCaseAttributes(intro.responderProfile)
    );
    intro.initiatorUser = Object.assign(new EndUser(), snakeCaseToCamelCaseAttributes(intro.initiatorUser));
    intro.responderUser = Object.assign(new EndUser(), snakeCaseToCamelCaseAttributes(intro.responderUser));

    setShRequest(intro);
    setLoading(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ItemPageContainer
        loader={loading}
        navItems={[
          { label: "requestDetails", ref: "details" },
          { label: "requiredAction", ref: "action" },
          { label: "matchProgress", ref: "progress" },
          { label: "singlesDetails", ref: "singles" },
          { label: "matchHandler", ref: "handler" },
          { label: "matchProgressUpdate", ref: "logs" },
          { label: "comments", ref: "comments" },
        ]}
        actions={{
          itemName: `shidduchRequest`,
          itemNumber: `Request: ${id}`,
          actionsTitle: "requestActions",
          actions: !isAdmin || [IntroductionStatus.TERMINATED, IntroductionStatus.ENGAGED].includes(shRequest.statusId)
            ? []
            : [
                {
                  title: "Reassign",
                  onClick: () => setOpenReassign(true),
                },
              ],
        }}
        itemStatus={{
          title: "requestStatus",
          item: shRequest,
          isManager: false,
        }}
        itemHeader={
          <SMEHeader
            title="requestDetails"
            formData={{}}
            showImage={false}
            indications={[
              {
                title: "Created:",
                value: formatDate(shRequest?.created),
              },

              {
                hidden: !isAdmin,
                title: "assignedTo",
                value: shRequest?.boUser?.first_name || "notAssigned",
                onClick: () =>
                  shRequest?.boUser?.first_name
                    ? navigate(`/systemUsers/${shRequest?.boUser?.id}`)
                    : setOpenReassign(true),
              },

              {
                title: "lastUpdate",
                value: formatDate(shRequest?.updated),
              },
              {
                title: "Between:",
                value:
                  fullName(shRequest?.initiatorUser, shRequest?.status, isAdmin) +
                  " & " +
                  fullName(shRequest?.responderUser, shRequest?.status, isAdmin),
              },
            ]}
            saveProfile={() => {}}
          />
        }
      >
        {statusWithAction.includes(shRequest.status) && (
          <CardItem title="requiredAction" id="action">
            <RequiredAction
              shRequest={shRequest}
              refresh={getShById}
              openReassign={openReassign}
              setOpenReassign={setOpenReassign}
            />
          </CardItem>
        )}

        {shRequest.statusId != IntroductionStatus.TERMINATED && (
          <CardItem title="matchProgress" id="progress">
            <MMProgress shRequest={shRequest} />
          </CardItem>
        )}

        <CardItem title="singlesDetails" id="singles">
          <ProfileCards shRequest={shRequest} isAdmin={isAdmin} isShidduch />
        </CardItem>

        {(isAdmin || shRequest.statusId != IntroductionStatus.MATCHED) && [
          <CardItem title="matchHandler" id="handler" key="handler">
            <ContactDetails shRequest={shRequest} />
          </CardItem>,

          <CardItem
            title="matchProgressUpdate"
            id="progressUpdate"
            halfWidth
            key="progressUpdate"
          >
            <MMLogs shRequest={shRequest} />
          </CardItem>,

          <CardItem title="comments" id="logs" halfWidth key="logs">
            <MMComments shRequest={shRequest} />
          </CardItem>,
        ]}
      </ItemPageContainer>
    </DashboardLayout>
  );
};

export default ShidduchRequestView;
