/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Grid, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ReactComponent as ArrowBack } from "assets/images/icons/back.svg";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";

function Breadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);
  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <Grid container gap={1} alignItems="center">
        <Icon style={{ opacity: 0.5 }}>{icon}</Icon>

        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            fontSize="12px"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
          >
            Home &gt;
          </MDTypography>
        </Link>
        {routes.map((el, i) => (
          <Link key={i} to={`/${routes.slice(0, i + 1).join("/")}`}>
            <MDTypography
              component="span"
              fontSize="12px"
              variant="body2"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              textTransform="capitalize"
            >
              {camelCaseToCapitalizedWords(el.replaceAll("-", " "))} &gt;
            </MDTypography>
          </Link>
        ))}
        <Link to="">
          <MDTypography
            component="span"
            fontSize="12px"
            variant="body2"
            color={light ? "white" : "dark"}
            textTransform="capitalize"
          >
            {camelCaseToCapitalizedWords(title.replaceAll("-", " "))}
          </MDTypography>
        </Link>
      </Grid>
      <Grid container gap={2} alignItems="center" style={{ marginLeft: "3px" }}>
        {title != "dashboard" && (
          <Link to={`/${routes.join("/")}`}>
            <ArrowBack/>
          </Link>
        )}
        <MDTypography
          fontSize="22px"
          fontWeight="bold"
          component="span"
          color={light ? "white" : "dark"}
          textTransform="capitalize"
        >
          {title.replaceAll("-", " ")}
        </MDTypography>
      </Grid>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
