import { Card, Chip, Autocomplete, Grid, TextField } from "@mui/material";
import { T } from "components/alove/Translator";
import colors from "assets/theme/base/colors";

import { Statusable } from "models/BoUser";
const MDTypography = require("components/MDTypography/index.js").default;

export interface ItemStatusProps {
  title: string;
  item: Statusable;
  isManager?: boolean;
  changeStatus?: (status: any) => Promise<void>;
  alternativeStatuses?:any,
  alternativeCurrentStatus?:any
}

export const ItemStatus = (props: ItemStatusProps) => {
  const { title, item, isManager, changeStatus, alternativeStatuses, alternativeCurrentStatus } = props;

  const color = colors[item.statusColor]?.main;
  
  return (
    <Card sx={{ pt: 3, px: 4, height: "125px" }}>
      <Grid
        container
        alignItems="center"
        sx={{ height: "100%" }}
        direction="column"
      >
        <Grid item alignSelf="flex-start" xs>
          <MDTypography variant="h5" style={{ lineHeight: "1" }}>
            <T>{title}</T>
          </MDTypography>
        </Grid>
        <Grid item xs>
          <Autocomplete
            disablePortal
            disableClearable
            value={alternativeCurrentStatus?
              alternativeStatuses?.filter(item => item.id == Number(alternativeCurrentStatus)).map(({ key, value }) => ({ key, value }))[0]
              :item.statuses?.find((status) => status.key == item.status) || {key:'', value:''} }
            // value={item.statuses?.find((status) => status.key == item.status) || {key:'', value:''}}
            getOptionLabel={(option) => option.value}
            readOnly={!isManager}
            onChange={(event, newValue) => {
              changeStatus?.(newValue.key);
            }}
            isOptionEqualToValue={(option, value) => option.key == value.key}
            size="small"
            // options={item.statuses || []}
            options={alternativeStatuses? alternativeStatuses:(item.statuses || [])}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    borderRadius: "50px",
                    margin: 0,
                  },
                }}
                sx={{
                  input: {
                    color: color,
                    fontSize: "14px",
                    padding: "0px 10px !important",
                    margin: 0,
                    width: "100%!important",
                    textAlign: "center",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${color}`,
                  },
                  ".MuiSvgIcon-fontSizeInherit": {
                    color: color + " !important",
                  },
                  ".MuiOutlinedInput-root": {
                    padding: "5px !important",
                  },
                  ".MuiAutocomplete-endAdornment": {
                    visibility: isManager ? "visible" : "hidden",
                  },

                }}
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <MDTypography variant="body2">{item.statusReason || ""}</MDTypography>
        </Grid>
      </Grid>
    </Card>
  );
};
