import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
} from "@mui/material";
import { T } from "components/alove/Translator";
import { setCurrentDialog, useMaterialUIController } from "context";
import React from "react";
const MDButton = require("../MDButton").default;

export enum DialogType {
  AddBrandToUser = "addBrandToUser",
  DeactivateEntitiy = "deactivateEntity",
  IntroList = "introList",
  SnoozeSME = "snoozeSME",
  DeleteWarning = "deleteWarning",
  AddGeneralCode = "addGeneralCode",
  AddPredictorRelation = "addPredictorRelation",
  ForgotPassword = "forgotPassword",
}

interface AloveDialogProps {
  dialogType: DialogType;
  handleDismis?: () => void;
  handleConfirm?: () => Promise<any>;
  children?: JSX.Element[] | JSX.Element;
  img?: string;
  dialogTitle: string;
  dialogBody?: string;
  dialogConfirm?: string;
  error?: boolean;
  valid?: boolean;
}

export const AloveDialog = (props: AloveDialogProps) => {
  const [controller, dispatch] = useMaterialUIController();
  const { currentDialog } = controller;
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const {
    handleDismis,
    handleConfirm,
    children,
    dialogTitle,
    img,
    dialogBody,
    valid,
    error,
    dialogConfirm,
    dialogType,
  } = props;

  const handleClose = () => {
    setCurrentDialog(dispatch, "");
    if (handleDismis) {
      handleDismis();
    }
  };

  return (
    <Dialog
      open={currentDialog === dialogType}
      sx={{
        "& .MuiDialog-paper": {
          padding: "35px",
        },
      }}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Icon
        onClick={handleClose}
        style={{ position: "absolute", left: "24px", top: "24px", cursor: "pointer" }}
      >
        close
      </Icon>
      <Grid
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="stretch"
        gap={1}
      >
        <DialogTitle
          color="error"
          style={{ display: img ? "flex" : "none", justifyContent: "center" }}
        >
          <img src={img} height="42px" />
        </DialogTitle>
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "center"}}
        >
          <T>{dialogTitle}</T>
        </DialogTitle>
        {dialogBody && (
          <DialogContent style={{ display: "flex", justifyContent: "center" }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ textAlign: "center", textWrap: "balance", whiteSpace: "pre-line" }}
            >
              <T>{dialogBody}</T>
            </DialogContentText>
          </DialogContent>
        )}
        {children}
        <Grid display="flex" justifyContent="end" flexDirection="row" alignItems="center" gap={1}>
          {handleConfirm && (
            <MDButton
              onClick={async (e) => {
                setButtonLoader(true);
                try {
                  await handleConfirm();
                  setCurrentDialog(dispatch, "");
                } finally {
                  setButtonLoader(false);
                }
              }}
              variant="contained"
              color={error ? "error" : "primary"}
              disabled={!valid && children}
            >
              {buttonLoader ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <T>{dialogConfirm}</T>
              )}
            </MDButton>
          )}
          <MDButton onClick={handleClose} variant="outlined" color={error ? "error" : "primary"}>
            <T>Cancel</T>
          </MDButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};
