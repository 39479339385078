import { Autocomplete, Card, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, TextField } from "@mui/material";

const MDInput = require("components/MDInput/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const { T } = require("components/alove/Translator");
const MDBox = require("components/MDBox/index.js").default;

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { RootRef } from "@material-ui/core";
import { tr } from "utils/globalsUtils";



export const MatchmakerDetails = (props) => {
  const { formData, setFormData, inputErrors, inputLists, updateAttribute } = props;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }
    const itemsNew = reorder(
      formData.attributes.preferContact,
      result?.source?.index,
      result?.destination?.index
    );
    formData.attributes.preferContact = itemsNew;
    setFormData({ ...formData });
  };


  return (
    <Grid container spacing={2} alignItems="start">
      
      <Grid item xs={6}> 

      <Autocomplete
        fullWidth
        options={inputLists.ageRange}
        value={formData?.population?.singleAges || []}
        onChange={(e, value) => updateAttribute("population.singleAges", value)}
        multiple
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={tr("ageRangeMMz")} />
        )}
      />
      </Grid>
      <Grid item xs={6}> 
      <Autocomplete
        fullWidth
        disableCloseOnSelect
        options={inputLists.singlePlan}
        value={formData?.population?.singlePlans || []}
        multiple
        onChange={(e, value) => updateAttribute("population.singlePlans", value)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={tr("singlePlanMMz")} />
        )}
      />
      </Grid>
      <Grid item xs={6}> 
      <Autocomplete
        fullWidth
        disableCloseOnSelect
        options={inputLists.singleType}
        value={formData?.population?.singleTypes || []}
        multiple
        onChange={(e, value) => updateAttribute("population.singleTypes", value)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={tr("singleTypeMMz")} />
        )}
      />
      </Grid>
      <Grid item xs={6}> 

      <Autocomplete
        options={inputLists.experience}
        fullWidth
        disableCloseOnSelect
        value={formData?.attributes?.experience || []}
        onChange={(e, value) => updateAttribute("attributes.experience", value)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={tr("experienceMMz")} />
        )}
      />
      </Grid>
      <Grid item xs={6}> 

      <MDInput
        label={tr("specialInstructions")}
        placeholder={tr("specialInstructionsPlaceholder")}
        rows={10}
        fullWidth
        multiline
        value={formData?.attributes?.special_intstructions || ""}
        onChange={(e) => updateAttribute("attributes.special_intstructions", e?.target?.value)}
        error={inputErrors?.special_intstructions}
      />
      </Grid>
      <Grid item xs={6}> 

      <MDInput
        label={tr("shortDescriptionMMz")}
        placeholder={tr("shortDescriptionPlaceholder")}
        rows={10}
        fullWidth
        multiline
        value={formData?.short_description}
        onChange={(e) => setFormData({ ...formData, short_description: e?.target?.value })}
        error={inputErrors?.short_description}
      />
      </Grid>
      <Grid item xs={6}>
        <MDTypography variant="body2" style={{ lineHeight: "1", marginBottom: "8px" }}>
          <T>prefredContantHeader</T>
        </MDTypography>
        {/* start dragable content  */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided1, snapshot) => {
              return (
                <RootRef rootRef={provided1.innerRef}>
                  <List sx={{}}>
                    {formData.attributes?.preferContact &&
                      formData.attributes?.preferContact?.map((item, index) => {
                        return (
                          <Draggable key={item} draggableId={item} index={index}>
                            {(provided, snapshot) => (
                              <>
                                <ListItem
                                  ContainerComponent="li"
                                  ContainerProps={{ ref: provided.innerRef }}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Grid
                                    container
                                    gap={2}
                                    alignItems="center"
                                    direction="row"
                                    justifyContent="flex-start"
                                  >
                                    <Grid item xs={1} xl={1}>
                                      <ListItemIcon>
                                        <MenuIcon />
                                      </ListItemIcon>
                                    </Grid>
                                    <Grid item>
                                      <MDTypography
                                        variant="body2"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {item}
                                      </MDTypography>
                                    </Grid>
                                  </Grid>
                                  <ListItemSecondaryAction />
                                </ListItem>
                              </>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided1.placeholder}
                  </List>
                </RootRef>
              );
            }}
          </Droppable>
        </DragDropContext>
        {/* end dragable cocntent */}
      </Grid>

      {/* <Grid item xs={6}>
        <MDInput
          label="Communication directions"
          value={formData?.attributes?.communicationDirection || ""}
          onChange={(e) => updateAttribute("attributes.communicationDirection", e?.target?.value)}
          rows={10}
          full
          multiline
          placeholder="For example: “Don’t message me through Whatsapp”"
          fullWidth
        />
      </Grid> */}
    </Grid>
  );
};
