import Grid from "@mui/material/Grid";

import Sidenav, { SidenavItemProps } from "./components/navbar";
import { Card, CircularProgress } from "@mui/material";
import DeactivateDialog, { DeactivateDialogProps } from "./components/deactivateDialog";
import { ItemStatus, ItemStatusProps } from "./components/itemStatus";
import { ItemActions, ItemActiosProps } from "./components/itemActions";
import React from "react";
import { setCurrentDialog, useMaterialUIController } from "context";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { DeleteItem, DeleteItemProps } from "./components/deleteItem";

const MDBox = require("components/MDBox/index.js").default;

export interface ItemPageContainerProps {
  brandLogoIMG?: string | undefined;
  navItems: SidenavItemProps[];
  actions?: ItemActiosProps;
  children: React.ReactNode;
  itemStatus?: ItemStatusProps;
  itemHeader?: React.ReactNode;
  loader?: boolean;
  deleteItem?: DeleteItemProps | boolean;
}

function ItemPageContainer(props: ItemPageContainerProps) {
  const { navItems, actions, children, itemHeader, itemStatus, brandLogoIMG, loader, deleteItem } =
    props;

  return loader ? (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: "80vh" }}>
      <CircularProgress />
    </Grid>
  ) : (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {actions && <ItemActions {...actions} brandLogoIMG={brandLogoIMG} />}
        </Grid>
        <Grid item xs={12} lg={2}>
          <Sidenav items={navItems} />
        </Grid>
        <Grid item xs={12} lg={10}>
          <MDBox mb={3}>
            <Grid container spacing={1}>
              {itemHeader && (
                <Grid item xs={10}>
                  {itemHeader}
                </Grid>
              )}
              {itemStatus && (
                <Grid item xs={2}>
                  {itemStatus && <ItemStatus {...itemStatus} />}
                </Grid>
              )}
              <Grid item container gap={1} alignItems={"start"}>
                {children}
              </Grid>
            </Grid>
          </MDBox>
          {deleteItem && <DeleteItem {...(deleteItem as DeleteItemProps)} />}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ItemPageContainer;
