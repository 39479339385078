import axios from "axios";
import { makeRequest, networkGlobals } from "./networkUtils";
import { Setting } from "models/Setting";
const serverHost = 'https://api.a-dmin.ai';
const activeFeatures = {}

export const hasFeature = async (host, brandID, featureKey) => {
    host ||= networkGlobals.host
    brandID ||= networkGlobals.brand.id
    const key = `${host}-${brandID}`
    if (!activeFeatures[key]) {
        activeFeatures[key] = await getSettingsDO('app_features')
    }
    return activeFeatures[key].find((f) => f.param_name === featureKey)?.param_value === "true"
}



/** get list of settings */
export const getSettingsDO = async (type): Promise<Setting[]> => {
    return makeRequest('settings?type=' + type)
}

/** put settings */
export const updateSettingsDO = async (data) => {
    return makeRequest('settings', { method: 'PUT', body: data })
}

export const deploySettingsDO = async (type) : Promise<any> => {
    return makeRequest('settings/deploy', { method: 'POST', body: { type } })
} 