import { Avatar, Chip, Divider, Grid, Table, Card, Tooltip } from "@mui/material";
import { MMAvatar } from "components/alove/MMAvatar";
import { ResumeIcon } from "components/alove/ResumeIcon";
import React, { useEffect, useState } from "react";
import { column } from "stylis";
import { getShidduchById, STATUSES } from "utils/matchmakerUtils";
import { fullName } from "utils/matchmakerUtils";
import { T } from "components/alove/Translator";
import { updateIntroStatus } from "utils/matchmakerUtils";
import { Introduction, IntroductionStatus } from "models/Introduction";
import { AttributesTable } from "./attributesTable";
import ResumePaper from "assets/images/icons/resume_paper.svg";
import { formtHeight, getDistanceInMiles, tr } from "utils/globalsUtils";
import form from "layouts/pages/users/new-user/schemas/form";
import { HeaderIndication } from "layouts/itemPageContainer/components/headerIndication";
import { VerticalDivider } from "components/alove/VerticalDivider";
import { EndUser } from "models/EndUser";
import { Profile } from "models/Profile";

const MDTypography = require("components/MDTypography").default;
const MDButton = require("components/MDButton").default;
const MDBox = require("components/MDBox").default;

const imageStatus = (profile, status) => {
  const policy = profile.attributesValues?.image_policy?.at(0) || "onlyMM";
  let text = "";
  if (status == STATUSES.NEW) {
    return null;
  } else if (policy == "onlyMM") {
    text = "Not to be shared (?)";
  } else if (policy == "showToBoth") {
    text = "Already revealed  (?)";
  } else if (status == STATUSES.AVAILABILE) {
    text = "Will be shared  (?)";
  } else {
    text = "Already shared  (?)";
  }
  return (
    <Chip
      label={text}
      sx={{
        maxWidth: "none",
      }}
      style={{
        position: "absolute",
        top: "-15px",
        left: "40px",
        backgroundColor: "white",
        fontSize: "10px",
      }}
      variant="outlined"
    />
  );
};

const age = (user: EndUser, partnerProfile: Profile, partnerName: string) => {
  const years = user.age;
  const partnerAgePref = partnerProfile.attributesValues?.prefAge;
  const color = partnerAgePref
    ? partnerAgePref[0] <= years && partnerAgePref[1] >= years
      ? "success"
      : "error"
    : "default";
  return (
    <HeaderIndication
      title="Age"
      value={years}
      color={color}
      tooltip={partnerName + " Preffered Age: " + partnerAgePref?.[0] + " - " + partnerAgePref?.[1]}
    />
  );
};

const height = (profile: Profile, partnerProfile: Profile, partnerName: string) => {
  const h = profile.attributesValues?.height?.[0];
  const partnerHeightPref = partnerProfile.attributesValues?.heightPref;
  const color = partnerHeightPref
    ? partnerHeightPref[0] <= h && partnerHeightPref[1] >= h
      ? "success"
      : "error"
    : "default";
  return (
    <HeaderIndication
      title="Height"
      value={formtHeight(h)}
      color={color}
      tooltip={
        partnerName +
        " Preffered Height: " +
        formtHeight(partnerHeightPref?.[0]) +
        " - " +
        formtHeight(partnerHeightPref?.[1])
      }
    />
  );
};
const address = (profile: Profile, partnerProfile: Profile, partnerName: string) => {
  if (profile.attributesValues?.livingPlace && profile.attributesValues?.livingPlace[0]) {
    const location = `${profile.attributesValues?.livingPlace[0]?.city}, ${profile.attributesValues?.livingPlace[0]?.state}`;
    const partnerPrefedDistance = partnerProfile.attributesValues?.prefHowFar?.[0] || 0;
    const partnerLocation = partnerProfile.attributesValues?.livingPlace?.[0];
    const distance = getDistanceInMiles(profile.attributesValues?.livingPlace[0], partnerLocation);
    const partnerPrefDistanceVal = partnerProfile.attributesValues?.preferred_location?.[0];
    return (
      <HeaderIndication
        title="Location"
        value={location}
        color={partnerPrefedDistance  ? (distance <= partnerPrefedDistance ? "success" : "error") : "default"}
        tooltip={
          partnerPrefDistanceVal ? (partnerName + " Preffered Region: " + tr(partnerPrefDistanceVal)) : 
          partnerPrefedDistance ? partnerName + " Preffered Distance: " + partnerPrefedDistance + " miles" : ""}
      />
    );
  }
  return <Chip label="Unknown Location" color="warning" />;
};

const gender = (profile: Profile, partnerProfile: Profile, partnerName: string) => {
  let gender = profile.attributesValues?.gender?.[0];
  if (!gender) return null;
  let partnerGenderPref = partnerProfile.attributesValues.prefGender || [];
  let partnerGender = partnerProfile.attributesValues.gender?.[0];

  if (!isNaN(gender)) {
    gender = gender == 4 ? "Male" : "Female";
    partnerGender = partnerGender == 4 ? "Male" : "Female";
    partnerGenderPref = partnerGenderPref?.[0] == 15 ? ["Female"] : ["Male"];
  }

  return (
    <HeaderIndication
      title="Gender"
      value={gender}
      tooltip={partnerName + " Preffered Gender: " + partnerGenderPref?.join(", ")}
      color={
        partnerGender
          ? partnerGenderPref.includes(partnerGender)
            ? "success"
            : "error"
          : "default"
      }
    />
  );
};

const ProfileCard = ({
  request,
  isFirst,
  refresh,
  isAdmin,
  isShidduch,
}: {
  request: Introduction;
  isFirst?: boolean;
  refresh: () => void;
  isAdmin: boolean;
  isShidduch: boolean;
}) => {
  const profile = (isFirst ? request.initiatorProfile : request.responderProfile) || {};
  const partnerProfile = (isFirst ? request.responderProfile : request.initiatorProfile) || {};
  const resume = isFirst ? request.initiatorResume : request.responderResume;
  const user = (isFirst ? request.initiatorUser : request.responderUser) || {};
  const partnerUser = (isFirst ? request.responderUser : request.initiatorUser) || {};
  return (
    <Grid gap={1} container alignItems="center" flexDirection="column">
      <Grid container alignItems="center" gap={1} justifyContent={"center"}>
        <Grid item>
          <div style={{ position: "relative" }}>
            <MMAvatar request={request} isFirst={!!isFirst} size="100px" isAdmin={isAdmin} />
            {isShidduch && imageStatus(profile, request.status)}
          </div>
        </Grid>
        {(request.status != IntroductionStatus.MATCHED || isAdmin) && (
          <Grid item>
            <MDTypography variant="h5" fontWeight="medium">
              {fullName(user, request.status, isAdmin)}
            </MDTypography>
          </Grid>
        )}
      </Grid>
      {(request.status != IntroductionStatus.MATCHED || isAdmin) && (
        <Grid display="flex" flexDirection="row" gap={1} container item xs justifyContent="center">
          <VerticalDivider height="45px" />
          {!isShidduch && (
            <Grid item>{gender(profile, partnerProfile, partnerUser.firstName!)}</Grid>
          )}
          {!isShidduch && <VerticalDivider height="45px" />}
          <Grid item>{age(user, partnerProfile, partnerUser.firstName!)}</Grid>
          <VerticalDivider height="45px" />
          <Grid item>{height(profile, partnerProfile, partnerUser.firstName!)}</Grid>
          <VerticalDivider height="45px" />

          <Grid item>{address(profile, partnerProfile, partnerUser.firstName!)}</Grid>

          {isShidduch && (
            <MDBox
              style={{
                height: "77px",
                width: "77px",
                borderRadius: "50%",
                backgroundColor: "white",
                borders: "1px solid",
                padding: "20px",
              }}
            >
              <ResumeIcon
                resume={resume}
                enabled={request.statusId != IntroductionStatus.MATCHED}
                big
              />
            </MDBox>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export const ProfileCards = ({
  shRequest,
  isAdmin,
  isShidduch,
}: {
  shRequest: Introduction;
  isAdmin: boolean;
  isShidduch?: boolean;
}) => {
  return (
    <Grid display={"flex"} flexDirection={"column"} gap={1}>
      <Grid display={"flex"} flexDirection={"row"} gap={1}>
        <Grid item xs={6}>
          <ProfileCard
            request={shRequest}
            isFirst
            refresh={() => {}}
            isAdmin={isAdmin}
            isShidduch={!!isShidduch}
          />
        </Grid>
        <Grid item xs={6}>
          <ProfileCard
            request={shRequest}
            refresh={() => {}}
            isAdmin={isAdmin}
            isShidduch={!!isShidduch}
          />
        </Grid>
      </Grid>
      <Divider />
      {(isAdmin || shRequest.statusId != IntroductionStatus.MATCHED) && (
        <AttributesTable request={shRequest} isShidduch={!!isShidduch} />
      )}
    </Grid>
  );
};
