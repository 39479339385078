import { MenuScreenItem, ScreenCard, ScreenModel, ScreenType } from "models/Screen";
import React from "react";
import ReactDOM from "react-dom";
import { Collapse, Divider, Grid, ListItemIcon } from "@mui/material";
import iphone from "assets/images/alove/iphone.png";
import { BrandLogo } from "components/alove/BrandLogo";
import { Setting } from "models/Setting";
import MenuIcon from "@mui/icons-material/Menu";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { groupBy } from "utils/globalsUtils";

const MDTypography = require("components/MDTypography/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

export const ScreenPreview = ({ screen, colors }: { screen: ScreenModel; colors: Setting[] }) => {
  const [slides, setSlides] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (screen.type === ScreenType.SliderScreen) {
      try {
        setSlides(JSON.parse(screen.body));
      } catch (e) {
        setSlides([]);
      }
    }
  }, [screen.body]);

  const getColor = (colorName: string) => {
    if (colorName == "black") {
      return "#000000";
    }
    const value = colors
      .find((color) => color.paramName === colorName)
      ?.paramValue?.replace("0xFF", "");
    return value ? "#" + value : "";
  };

  const fileds = {
    image: screen.image ? (
      <img
        style={{
          height: "100px",
        }}
        src={screen.image}
      />
    ) : screen.styles?.showAppIcon ? (
      <BrandLogo size="100px" />
    ) : null,
    title: screen.title && (
      <MDTypography variant="h3" align="center">
        {screen.title}
      </MDTypography>
    ),
    subtitle: screen.subtitle && (
      <MDTypography variant="h5" align="center">
        {screen.subtitle}
      </MDTypography>
    ),

    body:
      screen.type === ScreenType.SliderScreen ? (
        <div style={{ width: "100%", overflow: "scroll" }}>
          <Grid
            container
            flexDirection="row"
            style={{
              display: "flex",
              flexDirection: "row",
              width: slides.length * 300,
            }}
          >
            {slides.map((text: string, index: number) => (
              <div style={{ width: "280px", padding: "10px" }} key={index}>
                <MDTypography key={index} variant="body2" align="center">
                  {text}
                </MDTypography>
              </div>
            ))}
          </Grid>
        </div>
      ) : (
        screen.body && (
          <MDTypography variant="body2" style={{ whiteSpace: "pre-line" }}>
            {screen.body}
          </MDTypography>
        )
      ),
    button1Title: screen.button1Title && (
      <MDButton
        variant="contained"
        bgColor={getColor(screen.styles?.button1Color)}
        color="primary"
        fullWidth
        circular
      >
        {screen.button1Title}
      </MDButton>
    ),
    button2Title: screen.button2Title && (
      <MDButton
        variant="contained"
        color="primary"
        fullWidth
        circular
        bgColor={getColor(screen.styles?.button2Color)}
      >
        {screen.button2Title}
      </MDButton>
    ),
    cancelTitle: screen.cancelTitle && (
      <MDButton
        variant={getColor(screen.styles?.cacnelColor) ? "outlined" : "text"}
        color="text"
        fullWidth
        circular
        bgColor={getColor(screen.styles?.cacnelColor)}
      >
        {screen.cancelTitle}
      </MDButton>
    ),
    note: screen.note && (
      <MDTypography variant="body2" align="center">
        {screen.note}
      </MDTypography>
    ),
  };

  const cardsScreen = () => {
    const [openedCard, setOpenedCard] = React.useState<number | null>(null);
    return (
      <Grid p={2} container>
        {screen.cards.map((card: ScreenCard, index) => {
          return (
            <Grid
              key={index}
              container
              onClick={() => setOpenedCard(openedCard === index ? null : index)}
              style={{ cursor: "pointer" }}
              flexDirection="column"
            >
              <MDTypography variant="h5">{card.title}</MDTypography>
              <Collapse in={openedCard === index}>
                <MDTypography variant="body1">{card.body}</MDTypography>
                {card.image && (
                  <img
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                    }}
                    src={card.image}
                  />
                )}
              </Collapse>
              <Divider />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const menuScreen = () => {
    return (
      <Grid p={2} container>
        {Object.entries(groupBy(screen.menuItems, (m) => m.panel)).map(([panel, group]) => {
          return (
            <Grid container justifyContent="space-between">
              <MDTypography variant="h5" color="primary">{panel}</MDTypography>
              {(group as []).map((card: MenuScreenItem, index) => {
                return (
                  <Grid flexDirection="row" container justifyContent="space-between" key={index}>
                    <Grid
                      key={index}
                      container
                      style={{ cursor: "pointer" }}
                      flexDirection="column"
                      item
                      xs={9}
                    >
                      <MDTypography variant="h5">{card.title}</MDTypography>
                      <MDTypography variant="body1">{card.subtitle}</MDTypography>

                      <Divider />
                    </Grid>
                    <Grid item xs={2} container justifyContent="center" alignItems="center">
                      <ArrowForwardIos />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const getLayout = (screenType: ScreenType) => {
    switch (screenType) {
      case ScreenType.Popup:
        return (
          <Grid
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "gray",
            }}
            container
            alignContent="center"
            justifyContent="center"
          >
            <Grid
              container
              alignItems="center"
              flexDirection={"column"}
              gap={2}
              mx={2}
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                padding: "20px",
              }}
            >
              {screen.fieldsOrder?.map((field) => fileds[field])}
            </Grid>
          </Grid>
        );
      case ScreenType.Curtain:
        return (
          <Grid
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "gray",
            }}
            container
            alignContent="end"
          >
            <Grid
              container
              alignItems="center"
              flexDirection={"column"}
              gap={2}
              justifyContent="space-evenly"
              wrap="nowrap"
              style={{
                height: "80%",
                width: "100%",
                backgroundColor: "white",
                borderRadius: "20px 20px 0 0",
                padding: "20px",
                overflow: "scroll",
              }}
            >
              {screen.fieldsOrder?.map((field) => fileds[field])}
            </Grid>
          </Grid>
        );

      case ScreenType.FullScreen:
      case ScreenType.SliderScreen:
        return (
          <Grid
            justifyContent="space-evenly"
            alignItems="center"
            flexDirection={"column"}
            wrap="nowrap"
            container
            style={{
              height: "100%",
              width: "100%",
              overflow: "scroll",
            }}
            px={1}
          >
            {screen.fieldsOrder?.map((field) => fileds[field])}
          </Grid>
        );
      case ScreenType.CardsScreen:
        return cardsScreen();
      case ScreenType.MenuScreen:
        return menuScreen();
      default:
        return "Error";
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${iphone})`,
        backgroundSize: "cover",
        width: "349px",
        height: "700px",
        padding: "80px 20px 80px 22px",
      }}
    >
      {getLayout(screen.type)}
    </div>
  );
};
