import React, { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// import PositionImageViewModal from './viewImageModal';

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StatsPanel } from "components/alove/StatsPanel";
import ItemPageContainer from "layouts/itemPageContainer";
import {
  getSinglMMStats,
  getSMEProfile,
  getMMForm,
  updateMM,
  mmAction,
} from "utils/matchmakerUtils";

import CardItem from "components/alove/CardItem";
import { SMEBasic } from "layouts/itemPageContainer/components/basic";
import { getSystemUserByIDDO } from "utils/systemUsersUtils";
import { title } from "process";
import { create } from "domain";
import {
  avatarUrl,
  camelCaseToCapitalizedWords,
  formatDate,
  formatDateTime,
  snakeCaseToCapitalizedWords,
} from "utils/globalsUtils";
import { BrandsTable } from "layouts/itemPageContainer/userItem/brandsTable";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { BoUser } from "models/BoUser";
import {
  getByUserId,
  getQuestionnaireStats,
  updateEndUser,
  updateStatus,
} from "utils/enduserUtils";
import { EndUser } from "models/EndUser";
import { addAlert, setCurrentDialog, useMaterialUIController } from "context";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import DeactivateDialog from "layouts/itemPageContainer/components/deactivateDialog";
import { PackageDetails } from "layouts/endUsers/viewEndUser/components/package";
import { Profile, ProfileAsset, ProfileStatus } from "models/Profile";
import { PermissionType } from "models/BoRole";
import { MMStatuses } from "models/Matchmaker";
import { Autocomplete, CircularProgress } from "@mui/material";
import { Intros } from "./compenents/intros";
import { profile } from "console";
import { Bio } from "./compenents/bio";

const MDInput = require("components/MDInput/index.js").default;
const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;
let newStatus = ProfileStatus.Active;

function ViewEndUserIntroductions() {
  const pageNumber = "2.7";
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentBrand, currentRole, currentHost } = controller;
  const isAdmin = true;
  const [inputErrors, setInputErrors] = useState({});

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);

  const [userData, setUserData] = React.useState(new EndUser());
  const [profileData, setProfileData] = React.useState({} as Profile);
  const [profileAssets, setProfileAssets] = React.useState([] as ProfileAsset[]);

  let { id } = useParams();

  const [queryParameters] = useSearchParams();
  const [filter, setFilter] = useState(queryParameters.get("filter") || "");

  useEffect(() => {
    setLoader(true);
    getProfile();
  }, [id]);

  const getProfile = async () => {
    const { user, profile, profileAssets } = await getByUserId(id || "me");
    user.avatar = avatarUrl(profile.profileId, profileAssets?.[0]?.uri);

    setUserData(Object.assign(new EndUser(), user));
    setProfileData(Object.assign(new Profile(), profile));
    setProfileAssets(profileAssets);

    setLoader(false);
  };

  const format = (title) => {
    let t = camelCaseToCapitalizedWords(title)
    return t.replace("Me", userData.firstName || "Me")
  }

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={loader ? "Loading..." : userData.firstName} />
      <ItemPageContainer
        loader={loader}
        navItems={[
          { label: "Profile & Status" },
          { label: "Bio & Preferences", ref: "bio_pref" },
          { label: "Introductions", ref: "intros" },
        ]}
        actions={{
          itemName: `System User`,
          itemNumber: `User ID: ${id}`,
          actionsTitle: "User Actions",
          actions: [],
        }}
        itemStatus={{
          title: "User Status",
          item: profileData,
          isManager: false,
        }}
        itemHeader={
          <SMEHeader
            formData={userData}
            indications={[
              {
                title: "Created:",
                value: formatDate(userData.created),
              },
              {
                title: "Last Login:",
                value: formatDate(userData.lastLogin),
              },
            ]}
            saveProfile={() => {}}
          />
        }
      >
        <CardItem title="Bio & Preferences" id="bio_pref">
          <Bio profile={profileData} user={userData} />
        </CardItem>
        <CardItem
          title={format(filter)}
          id="intros"
          filters={[
            {
              options: ["introductions", "matched", "approvedByMe", "approvedByOther", "declinedByMe", "declinedByOther", "ignoredByMe", "ignoredByOther", "terminated"],
              currentValue: filter,
              getLabel: (v) => format(v),
              onChange: (v) => {
                setFilter(v || "");
              },
            },
          ]}
        >
          <Intros profileId={profileData.profileId!} filter={filter} />
        </CardItem>
      </ItemPageContainer>
    </DashboardLayout>
  );
}

export default ViewEndUserIntroductions;
