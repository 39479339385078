import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getShidduchRequests } from "utils/matchmakerUtils";
import { useNavigate } from "react-router-dom";
import { Box, List, ListItem } from "@mui/material";
import { ticketsColumns } from "./components/requestItem";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import { STATUSES } from "utils/matchmakerUtils";
import { T } from "components/alove/Translator";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { ShRequestsFilters } from "./components/filters";
import { getAdminStats } from "utils/matchmakerUtils";
import { TablePage } from "components/alove/TablePage";
import { PermissionType } from "models/BoRole";
import { getCustomerSupportTopicsDO, getCustomerSupportStatusesDO, getTicketsDO, getAllUSersDO } from 'utils/customerSupportUtils';
import { getBrandsDO } from "utils/brandsUtils";

let navigate;
const host = 'dev';
const now = new Date();

const shouldColorBackground = (request) =>
  request.status <= 9 &&
  (request.statusReason?.initiatorStatus || request.statusReason?.responderStatus);

const action = (request, refresh) => {};

function CustomerSupportTickets() {
  const pageNumber = "6.1"
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentRole } = controller;
  const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.Manager);
  navigate = useNavigate();

  const [mmList, setMmList] = useState([]);
  const [stats, setAdminStats] = useState({
    tickets: 0,
    oneTimeRate: 0,
    assigneeTickets: 0,
    slaExpared: 0
  });
  const [topics, setTopics] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [brands, setBrands] = useState([]);
  const [realBrands, setRealBrands] = useState( [
    { id: 100, key: 'a-love', label: 'a-love' },
    { id: 101, key: 'mujual', label: 'mujual' },
    { id: 102, key: 'zuug', label: 'ZUUG' }
  ]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setTopics(await getCustomerSupportTopicsDO(host));
    // const tickets = await getTicketsDO(host);
    // setAdminStats({ ...stats, tickets: tickets?.length });
    setBrands(await getBrandsDO(host));
    setAllUsers(await getAllUSersDO(host));
    setStatuses((await getCustomerSupportStatusesDO(host)).map(s => ({ ...s, key: s.id })));
  }

  const getTableData = async (host, filters, page) => {
    
    if (filters.hasOwnProperty('topic') && filters.topic) {
      const topic = topics.find(t => t.key === filters.topic);
      if (topic) {
        filters.topic = topic.id;
      } 
    }
    
    if (filters.hasOwnProperty('brand') && filters.brand) {
      const brand = brands.find(b => b.folder === filters.brand);
      if (brand) {
        filters.brand = brand.id;
      } 
    }

    const tickets = await getTicketsDO(host, encodeURIComponent(JSON.stringify(filters)));

    let edxpired = 0;
    const updatedTickets = tickets?.map(ticket => {
      const createdDate = new Date(ticket.created);
      const slaDeadline = new Date(createdDate.getTime() + ticket.sla_first * 60 * 60 * 1000);
      const adjustedNow = new Date(now.getTime() -3 * 60 * 60 * 1000);
      const timeDiff = slaDeadline.getTime() - adjustedNow.getTime();
      const totalMinutesLeft = timeDiff / (1000 * 60);  
      const hours = Math.floor(totalMinutesLeft / 60);
      const minutes = Math.floor(Math.abs(totalMinutesLeft % 60)); 
      if(timeDiff< 0){
        edxpired++;
      }
    return {
      ...ticket,
      sla: hours+':'+("0"+minutes).slice(-2)
    };
  });
  setAdminStats({...stats, slaExpared: edxpired, tickets: tickets?.length});
    
    console.log('updatedTickets:', updatedTickets);
    
    return updatedTickets;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Customer Support Tickets" />
      <Box
        sx={{
          '& .urgent': {
            color: '#F4357A'
          },
          '& .hidden': {
            color: 'transparent !important'
          }
        }}
      >
        <TablePage
          title="Support Tickets List"
          table={{
            columns: ticketsColumns(isAdmin, action, allUsers),
            loadNext: getTableData,
            pagination: true,
            lineClick: (row) => navigate(`/customerSupportTickets/${Number(row?.id)}`)
          }}
          filters={[
            {
              label: "ticketID",
              type: "search",
            },
            {
              label: "topic",
              options: topics
            },
            {
              label: "brand",
              hidden: isAdmin ? false : null,
              options: [
                { id: 100, key: 'a-love', label: 'a-love' },
                { id: 101, key: 'mujual', label: 'mujual' },
                { id: 102, key: 'zuug', label: 'ZUUG' }
              ]
            },
            {
              label: "status",
              options: statuses
            }
          ]}
          stats={{
            title: "Support Statistics",
            fields: [
              {
                name: "tickets",
                title: "Tickets",
                color: "primary",
                filterLabel: "status",
                filterValue: "",
              },
              {
                name: "oneTimeRate",
                title: "% On time rate",
              },
              {
                name: "assigneeTickets",
                title: "Tickets per assignee",
              },
              {
                name: "slaExpared",
                title: "SLA Expired",
                color: "error",
                filterValue: "warning",
              },
            ],
            stats,
          }}
        />
      </Box>
    </DashboardLayout>
  );
}
export default CustomerSupportTickets;