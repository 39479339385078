import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDButton";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import selectData from "./../data/data";
import {RelatedQuestionModal} from './questionModal';
import { PrevalidateModal } from "./prevalidateModal";
import { Slider } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const scoringTypes = {
    1: "Nominal",
    2: "Ordinal",
    3: "Interval",
    4: "Interval date",
    5: "Interval location",
    6: "Reverse Nominal",
}

export default function Question(props) {

    const { question, inputErrors, setQuestion, addToQuestionarie, responses,attribute } = { ...props };

    return (
        <MDBox pt={2} pb={3}>
            <Card>
                <MDBox p={3} lineHeight={1}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} xl={3}>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h6" fontWeight="medium">
                                    Question
                                </MDTypography>
                            </MDBox>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={9} xl={9}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} xl={12}>
                                    <MDInput
                                        label="Question text"
                                        placeholder="Question text"
                                        value={question.questionTexts["txt_id"] || ""}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => {
                                            question.questionTexts["txt_id"] = e.target.value;
                                            setQuestion({ ...question });
                                        }}
                                        error={inputErrors.questionTextError}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={12} sx={{ ml: "auto" }}>
                                    <MDInput
                                        value={question.questionTexts["vis_settings.txt_header2"] || ""}
                                        label="Question additional information (i)"
                                        placeholder="Question additional information (i)"
                                        size="small"
                                        fullWidth
                                        onChange={(e) => {
                                            question.questionTexts["vis_settings.txt_header2"] = e.target.value;
                                            setQuestion({ ...question });
                                        }}
                                        error={inputErrors.questionAdditionalTextError}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={12}>
                                    <Autocomplete
                                        size="small"
                                        multiple
                                        value={addToQuestionarie.filter((a) => question.addToQuestionnarie.includes(a.id))}
                                        // defaultValue={buildPositions(s?.position)}
                                        options={addToQuestionarie}
                                        disableCloseOnSelect
                                        disabled={question.addToQuestionnarie}
                                        onChange={(e, value) => setQuestion({ ...question, addToQuestionnarie: value.map((a) => a.id) })}
                                        // onChange={(e, value) => updatePosition(index, value)}
                                        getOptionLabel={(positions) => positions.display_name}
                                        renderOption={(props, option, { selected }) =>
                                        (
                                            <li {...props} key={option.name} sx={{ height: 20 }}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.display_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                // variant="standard"
                                                label="Add to questionarie"
                                                placeholder="More..."
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>
                               
                                <Grid item xs={12} xl={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={2.3} xl={2.3}>
                                            {/* <MDTypography variant="body2" fontWeight="medium" > */}
                                            <MDTypography variant="caption" fontWeight="bold" color="text">
                                                Add Hyperlink
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={7} xl={7}>
                                            <MDInput
                                                label="Hyper link"
                                                placeholder="Hyper link"
                                                size="small"
                                                fullWidth
                                                // error={inputErrors.questionAdditionalTextError}
                                                onChange={(e) => setQuestion({ ...question, questionTitle: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={2.7} xl={2.7}>
                                            <MDInput
                                                label="Label"
                                                placeholder="Label"
                                                size="small"
                                                fullWidth
                                                error={inputErrors.questionAdditionalTextError}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} xl={12} container justifyContent="space-between">
                                    <Grid item xs={4} xl={3}>
                                        <MDTypography variant="caption" fontWeight="bold" color="text">
                                            Weight
                                        </MDTypography>
                                        <Slider
                                            valueLabelDisplay="auto"
                                            value={question.weight || 0}
                                            onChange={(e) => setQuestion({ ...question, weight: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={4} xl={3}>
                                        <MDTypography variant="caption" fontWeight="bold" color="text">
                                            Importance
                                        </MDTypography>
                                        <Slider
                                            valueLabelDisplay="auto"
                                            value={question.importance || 0}
                                            onChange={(e) => setQuestion({ ...question, importance: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={4} xl={4}>
                                        <Autocomplete
                                            options={Object.keys(scoringTypes)}
                                            getOptionLabel={(a) => scoringTypes[a]}
                                            size="small"
                                            value={question.scoring_type_id || 1}
                                            onChange={(e, v) => setQuestion({ ...question, scoring_type_id: v })}
                                            renderInput={(params) => <TextField {...params} label="Scoring type" />}
                                        />
                                        </Grid>
                                    <Grid item xs={4} xl={3}>
                                        <MDTypography variant="caption" fontWeight="bold" color="text">
                                            Tolerance
                                        </MDTypography>
                                        <Slider
                                            valueLabelDisplay="auto"
                                            value={question.tolerance || 0}
                                            onChange={(e) => setQuestion({ ...question, tolerance: e.target.value })}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* </Grid> */}

                        </Grid>
                        <Grid item xs={3} xl={3}>
                            <Grid container spacing={1} alignItems="center">
                                    <Tooltip title={'Upload Image'} placement="top">
                                    <MDButton
                                        variant="outlined"
                                        sx={{mt:2}}
                                        color="primary"
                                        fullWidth
                                    >
                                            Upload Image
                                            {/* {action.label}&nbsp; */}
                                            {/* <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon> */}
                                        </MDButton>
                                    </Tooltip>

                                    <PrevalidateModal q={question} bcid={question.bcid} />
                               
                                    <RelatedQuestionModal responses={responses} attrName={attribute?.name}  />
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
            </Card>
        </MDBox>
    )
}

