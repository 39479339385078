import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Select,
} from "@mui/material";
import { ScreenEvent, ScreenModel, ScreenType } from "models/Screen";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { RootRef, styled } from "@material-ui/core";
import { useEffect } from "react";
import { ImageUploader } from "components/alove/ImageUploader";
import { Setting } from "models/Setting";
import { Slides } from "./slides";

const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const ScreenForm = ({ screen, setScreen }: { screen: ScreenModel; setScreen: any }) => {
  useEffect(() => {
    if (!screen.fieldsOrder) {
      setScreen({
        ...screen,
        fieldsOrder: [
          "image",
          "title",
          "subtitle",
          "body",
          "button1Title",
          "button2Title",
          "cancelTitle",
          "note",
        ],
      });
    }
  }, [screen]);

  const onDragEnd = (result) => {
    let itemsNew = screen.fieldsOrder.filter((field, index) => index !== result.source.index);
    itemsNew.splice(result.destination.index, 0, screen.fieldsOrder[result.source.index]);
    setScreen({ ...screen, fieldsOrder: itemsNew });
  };

  const item = (key, child, index) => {
    return (
      <Draggable key={key} draggableId={key} index={index}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <ListItem>
              <Grid
                container
                my={1}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                {screen.type != ScreenType.SliderScreen && (
                  <Grid item xs={1} xl={1}>
                    <ListItemIcon>
                      <MenuIcon />
                    </ListItemIcon>
                  </Grid>
                )}
                <Grid item xs={11}>
                  {child}
                </Grid>
              </Grid>
            </ListItem>
          </div>
        )}
      </Draggable>
    );
  };

  const elements: { [key: string]: React.ReactNode } = {
    image: (
      <Grid item container gap={2} alignItems="center">
        <Grid item xs={7}>
          <ImageUploader
            initImage={screen.image || ""}
            onUploaded={(image: string) =>
              setScreen({ ...screen, image, styles: { ...screen.styles, showAppIcon: false } })
            }
          />
        </Grid>
        <Grid item xs={1}>
          <Checkbox
            checked={screen.styles?.showAppIcon || false}
            onChange={(e) =>
              setScreen({ ...screen, styles: { ...screen.styles, showAppIcon: e.target.checked } })
            }
          />
        </Grid>

        <Grid item xs={3}>
          <MDTypography variant="body2">Show App Icon</MDTypography>
        </Grid>
      </Grid>
    ),
    title: (
      <MDInput
        fullWidth
        label="Screen Title"
        value={screen.title || ""}
        onChange={(e: any) => setScreen({ ...screen, title: e.target.value })}
      />
    ),
    subtitle: (
      <MDInput
        fullWidth
        label="Screen Subtitle"
        value={screen.subtitle || ""}
        onChange={(e: any) => setScreen({ ...screen, subtitle: e.target.value })}
      />
    ),
    body:
      screen.type == ScreenType.SliderScreen ? (
        <Slides screen={screen} setScreen={setScreen} />
      ) : (
        <MDInput
          fullWidth
          label="Screen Body"
          value={screen.body || ""}
          multiline
          rows={10}
          onChange={(e: any) => setScreen({ ...screen, body: e.target.value })}
        />
      ),

    button1Title: (
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <MDInput
            fullWidth
            label="Button 1 Title"
            value={screen.button1Title || ""}
            onChange={(e: any) => setScreen({ ...screen, button1Title: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            getOptionLabel={(option) => camelCaseToCapitalizedWords(option)}
            value={screen.styles?.button1Color || ""}
            onChange={(e, o) =>
              setScreen({ ...screen, styles: { ...screen.styles, button1Color: o } })
            }
            options={["primary", "secondary", "black", "error"]}
            renderInput={(params) => <MDInput {...params} label="Button 1 Color" />}
          />
        </Grid>
      </Grid>
    ),
    button2Title: (
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <MDInput
            fullWidth
            label="Button 2 Title"
            value={screen.button2Title || ""}
            onChange={(e: any) => setScreen({ ...screen, button2Title: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            value={screen.styles?.button2Color || ""}
            getOptionLabel={(option) => camelCaseToCapitalizedWords(option)}
            onChange={(e, o) =>
              setScreen({ ...screen, styles: { ...screen.styles, button2Color: o } })
            }
            options={["primary", "secondary", "black", "error"]}
            renderInput={(params) => <MDInput {...params} label="Button 2 Color" />}
          />
        </Grid>
      </Grid>
    ),
    cancelTitle: (
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <MDInput
            fullWidth
            label="Cancel Button Title"
            value={screen.cancelTitle || ""}
            onChange={(e: any) => setScreen({ ...screen, cancelTitle: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            value={screen.styles?.cacnelColor || ""}
            getOptionLabel={(option) => camelCaseToCapitalizedWords(option)}
            onChange={(e, o) =>
              setScreen({ ...screen, styles: { ...screen.styles, cacnelColor: o } })
            }
            options={["primary", "secondary", "black", "error"]}
            renderInput={(params) => <MDInput {...params} label="Cancel Button Color" />}
          />
        </Grid>
      </Grid>
    ),
    note: (
      <MDInput
        fullWidth
        label="Additional Text"
        value={screen.note || ""}
        onChange={(e: any) => setScreen({ ...screen, note: e.target.value })}
      />
    ),
  };

  return (
    <Grid container flexDirection="column" p={4} gap={2}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <RootRef rootRef={provided.innerRef}>
                <List sx={{}}>
                  {screen.fieldsOrder?.map((field, index) => {
                    return item(field, elements[field], index);
                  })}
                  {provided.placeholder}
                </List>
              </RootRef>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};
