import { Content } from "models/Content";
import { makeRequest } from "./networkUtils";

export const getContents = (q: {
  page?: string;
  letter?: string;
  search?: string;
  status?: string;
  position?: string;
  translated?:string;
  langCode?:string
}): Promise<Content[]> => {
  const { page, letter, search, status, position, translated, langCode } = q;
  return makeRequest(
    "contents?letter=" +
      (letter || "") +
      "&search=" +
      (search || "") +
      "&status=" +
      (status || "") +
      "&position=" +
      (position || "") +
      "&translated=" +
      (translated || "") +
      "&langCode=" +
      (langCode || "") +
      "&page=" +
      (page || "0")
  ).then((res) => res.map((c) => Object.assign(new Content(), c)));
};
