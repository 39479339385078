import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes, { object } from "prop-types";
import { Autocomplete, Divider, TextField, List, Link, Icon, Tooltip, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import SidenavCollapse from "components/Sidenav/SidenavCollapse";
import SidenavList from "components/Sidenav/SidenavList";
import SidenavItem from "components/Sidenav/SidenavItem";
// Custom styles for the Sidenav
import SidenavRoot from "components/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Sidenav/styles/sidenav";
// Otis Admin PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setCurrentBrand,
  setCurrentRole,
  setCurrentHost,
} from "context";

import a_love from "assets/images/brands/a-love.png";
import mujual from "assets/images/brands/mujual.png";
import zuug from "assets/images/brands/zuug.png";

const realBrand = [
  { id: 100, name: "a-love", imgUrl: a_love, folder: "a-love" },
  { id: 101, name: "mujual", imgUrl: mujual, folder: "mujual" },
  {
    id: 102,
    name: "ZUUG",
    imgUrl: zuug,
    folder: "zuug",
  },
];

import { styled } from "@mui/material/styles";
import { T } from "components/alove/Translator";
import MDAvatar from "components/MDAvatar";
import { item } from "./styles/sidenavItem";
import { BrandLogo } from "components/alove/BrandLogo";
import { getRoleByArrayDO, getRoleDO } from "utils/rolesUtils";
import { capitalize } from "@material-ui/core";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "&.Mui-focused .MuiInputLabel-outlined": {
    color: "white",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "white",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      // Default left padding is 6px
      paddingLeft: 26,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
  },
});

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    auth,
    currentUser,
    currentBrand,
    currentRole,
    currentHost,
  } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const [allowedBrans, setAllowedBrans] = useState([]);
  const [roles, setRoles] = useState([]);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  /** building brands list by access */
  const prepareAlowedBrands = (currentUser) => {
    let brands = [];
    for (const brand of realBrand) {
      if (currentUser?.brands.includes(brand?.id)) {
        brands.push({ id: brand.id, name: brand.name });
      }
      setAllowedBrans(brands);

    const localStorageBrand = JSON.parse(localStorage.getItem("currentBrand")) ||  brands[0]
      setCurrentBrand(dispatch,localStorageBrand);
    }
  };

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
    if (currentUser !== null) {
      prepareAlowedBrands(currentUser);
      getRoleByArrayDO("dev", currentUser?.roles[currentBrand?.id]?.roles).then((res) => {
        setRoles(res);
      });
    } else {
      prepareAlowedBrands(JSON.parse(localStorage.getItem("BOCurrentUser")));
    }
  }, [currentUser]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) => {
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      );
    });
    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key, hide, accessNumber }) => {
      let returnValue;
      if (collapse) {
        if (!hide && (currentRole?.pages?.includes(accessNumber) || accessNumber === 0)) {
          returnValue = (
            <SidenavItem
              key={key}
              color={color}
              name={name}
              active={key === itemParentName ? "isParent" : false}
              open={openNestedCollapse === key}
              onClick={({ currentTarget }) =>
                openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        }
      } else {
        if (!hide && (currentRole?.pages?.includes(accessNumber) || accessNumber === 0)) {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavItem color={color} name={name} active={key === itemName} />
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem color={color} name={name} active={key === itemName} />
            </NavLink>
          );
        }
      }
      if (returnValue) {
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      }
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, hide, accessNumber }) => {
      let returnValue;
      // console.log(JSON.stringify(currentRole));
      if (
        type === "collapse" &&
        (accessNumber == 0 || currentRole?.pages?.includes(accessNumber))
      ) {

        if (name === "User") {
          icon = <MDAvatar src={currentUser?.avatar} alt="User" size="sm" />;
        }
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <SidenavCollapse
            key={key}
            name={
              name === "User"
                ? currentUser?.first_name + " " + currentUser?.last_name?.[0] + "."
                : name
            }
            icon={icon}
            active={key === collapseName}
            open={openCollapse === key}
            onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
          >
            {collapse ? renderCollapse(collapse) : null}
          </SidenavCollapse>
        );
      } else if (type === "title") {
        console.log("in else title");
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={"divider-22"}
            sx={{ mx: 2, my: 1 }}
            style={{
              color: "white",
              borderStyle: "dotted",
              borderWidth: "2px",
            }}
          />
        );
      }
      return returnValue;
    }
  );

  const changeBrand = (event, newValue) => {
    const role = JSON.parse(localStorage.getItem("BORoles" + newValue?.id));
    setCurrentBrand(dispatch, newValue);
    setCurrentRole(dispatch, role);
  };

  const roleTitle = currentUser?.roles?.[currentBrand?.id]?.roles
    ?.map((item) => roles?.find((role) => role?.id === item)?.role_name)
    .join(", ");

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      style={{ zIndex: 500, position: "absolute" }}
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={2} pb={1} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        {/* <MDBox component={NavLink} to="/" display="flex" alignItems="center"> */}

        <MDBox display="flex" alignItems="start" sx={(theme) => item(theme, { active: false })}>
          <MDBox sx={{mt: 2}}>
          {
            brand && <BrandLogo allowedBrans={allowedBrans}/>
            // matchMaker : zuug} alt="Brand logo" width="2rem" />
          }
          </MDBox>

          <Grid
            width={!brandName && "100%"}
            container
            gap={1}
            sx={(theme) =>
              sidenavLogoLabel(theme, {
                miniSidenav,
                padding: currentUser?.brands?.length === 1 ? 1 : 0,
              })
            }
          >
            {currentUser?.brands?.length === 1 ? (
              [
                <MDTypography
                  component="h3"
                  variant="button"
                  fontWeight="medium"
                  color={textColor}
                  style={{ fontSize: "18px" }}
                >
                  {currentBrand?.name?.toUpperCase()}
                </MDTypography>,
                <Tooltip title={roleTitle}>
                  <MDTypography
                    component="h6"
                    variant="button"
                    fontWeight="light"
                    color={textColor}
                    style={{ textOverflow: "ellipsis", width: "150px" }}
                  >
                    {roleTitle}
                  </MDTypography>
                </Tooltip>,
              ]
            ) : (
              <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  style={{ color: "#FFFFFFFF", width: 140 }}
                  value={currentBrand}
                  onChange={(event, newValue) => {
                    changeBrand(dispatch, newValue);
                  }}
                  size="small"
                  isOptionEqualToValue={(positions, value) => positions.name === value.name}
                  getOptionLabel={(option) => option.name || ""}
                  options={allowedBrans}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderRadius: "50px",
                          padding: "0px 0px 0px 10px",
                          margin: 0,
                        },
                      }}
                      sx={{
                        input: { color: "#FFFFFFFF", fontSize: "18px", padding: 0, margin: 0 },
                      }}
                    />
                  )}
                />
              </MDTypography>
            )}

            {["localhost", "a-dmin.ai", "www.a-dmin.ai", "dev.a-dmin.ai"].includes(window.location.hostname) && (
              <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  style={{ color: "#FFFFFFFF", width: 140 }}
                  value={currentHost}
                  onChange={(event, newValue) => {
                    setCurrentHost(dispatch, newValue);
                  }}
                  getOptionLabel={(option) => capitalize(option) || ""}
                  size="small"
                  options={["dev", "staging", "prod"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderRadius: "50px",
                          padding: "0px 0px 0px 10px",
                          margin: 0,
                        },
                      }}
                      sx={{
                        input: { color: "#FFFFFFFF", fontSize: "18px", padding: 0, margin: 0 },
                      }}
                    />
                  )}
                />
              </MDTypography>
            )}
          </Grid>
        </MDBox>
      </MDBox>

      <Divider
        sx={{ mx: 2, my: 1 }}
        style={{
          color: "white",
          borderStyle: "dotted",
          borderWidth: "2px",
        }}
      />
      {/* {currentUser?.pages_access?.length ? <List>{renderRoutes}</List> : null} */}
      {currentRole ? <List>{renderRoutes}</List> : null}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: "",
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.instanceOf(Array).isRequired,
};

export default Sidenav;
