import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  TextField,
  Grid,
  Card,
  Box, List, ListItem
} from "@mui/material";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { TablePage } from "components/alove/TablePage";
import { PermissionType } from "models/BoRole";
import AddNewLanguage from './components/addNewLanguageModal';
import { translationsLanguagesDO, updateIsCompletedStatuDO } from 'utils/translateUtils';
import languages from './components/languaesList.json';
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
const MDSnackbar = require("components/MDSnackbar").default;

const host = 'dev';

const shouldColorBackground = (request) =>
  request.status <= 9 &&
  (request.statusReason?.initiatorStatus || request.statusReason?.responderStatus);

const action = (request, refresh) => { };

function LanguageSettings() {
  const pageNumber = "3.7"
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentRole, currentBrand } = controller;
  const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.Manager);
  const navigate = useNavigate();

  const [topics, setTopics] = useState([]);
  const [brands, setBrands] = useState([
    { id: 100, key: 'a-love', label: 'a-love' },
    { id: 101, key: 'mujual', label: 'mujual' },
    { id: 102, key: 'zuug', label: 'ZUUG' }
  ]);
  const [choosenBrand, setChoosenBrand] = useState(brands[0]);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [snackbar, setSnackbar] = useState({ status: false, color: "", icon: "", title: "" });
  const [refresh, setRefresh] = useState(false);
  const [newLangs, setNewLangs] = useState();

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  const getSettings = async (host, filters, page) => {
    const langs = await translationsLanguagesDO(host);
    const totalCount = parseInt(langs[0].translation_count);
    langs.forEach(item => {
      const count = parseInt(item.translation_count);
      item.percentage = ((count / totalCount) * 100).toFixed(2) + "%";
    });

    const existsLangCodes = new Set(langs.map(lang => lang.language_code));
    const updatedLangs = languages.filter(lang => !existsLangCodes.has(lang.code));
    setNewLangs(updatedLangs);
    return langs;
  };

  const renderModal = (onChange, s) => (
    <AddNewLanguage
      newLangs={newLangs}
      snackbar={snackbar}
      setSnackbar={setSnackbar}
      setRefresh={setRefresh}
      refresh={refresh}
      host={host}
    />
  );

  const SLAcolumns = (isAdmin, action) => [
    {
      headerName: 'Language',
      field: "language",
      width: 200
    },
    {
      headerName: 'Words',
      field: "translation_count",
      width: 150,
    },
    {
      headerName: '% translated',
      field: 'percentage',
      width: 150,
    },
    {
      field: "is_completed",
      headerName: "Display",
      width: 150,
      type: "boolean",
      align: "center",
      hidden: (params) =>
        !currentRole?.hasAccess(pageNumber, PermissionType.Manager),
      editable: true,
    },
  ];

  const processRowUpdate = async (newRow) => {
    console.log('newRow: ', newRow);
    const updateStatus = await updateIsCompletedStatuDO(host, newRow?.id, newRow?.is_completed)
    console.log('updateStatus: ', updateStatus);
    setSnackbar({
      status: true,
      color: updateStatus === 200 ? "success" : "error",
      icon: updateStatus === 200 ? "check" : "error",
      title: updateStatus === 200 ? "Status was updated" : "Something was wrong",
  });
     
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          '& .urgent': {
            color: '#F4357A'
          },
          '& .hidden': {
            color: 'transparent !important'
          }
        }}
      >
        <TablePage
          title="Translations list"
          refresh={refresh}
          disableClearAll
          // onCellClick={(params, events) => {
          //   console.log('params from cell click: ', params);
          //   console.log('event rom cell click: ', event);
          // }}
          table={{
            columns: SLAcolumns(isAdmin, action),
            loadNext: getSettings,
            pagination: true,
            processRowUpdate: processRowUpdate,
            lineClick: (row) =>{
               navigate(`/translations_list/${row?.row?.language_code}`)
            }
          }}
          filters={[
            {
              label: "Screen",
              renderFilter: renderModal,
              clearOtherFilters: true,
            }
          ]}
        />
      </Box>
      <MDSnackbar
        color={snackbar.color || "success"}
        icon={snackbar.icon || "check"}
        title={snackbar.title || ""}
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}

export default LanguageSettings;

