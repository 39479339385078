import { makeRequest, networkGlobals } from "./networkUtils";


const requestCache = {};
export const getIntroductions = async (page, status, from, to, matchmakerId, shidduchId) => {
  const url =
    `introductions?brandId=${networkGlobals.brand.id}&page=${page}&status=${status}&` +
    `before=${to}&matchmakerId=${matchmakerId}&after=${from}&introId=${shidduchId}`;

  if (!requestCache[url + networkGlobals.host]) {
    requestCache[url + networkGlobals.host] = makeRequest(url);
  }

  return requestCache[url + networkGlobals.host];
};

export const getIntroductionDO = async (id) => {
  return makeRequest(`introductions/${id}`);
}
