import React, { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// import PositionImageViewModal from './viewImageModal';

import { useNavigate, useParams } from "react-router-dom";
import { StatsPanel } from "components/alove/StatsPanel";
import ItemPageContainer from "layouts/itemPageContainer";
import CardItem from "components/alove/CardItem";
import { SMEBasic } from "layouts/itemPageContainer/components/basic";
import { getSystemUserByIDDO } from "utils/systemUsersUtils";
import { title } from "process";
import { create } from "domain";
import { avatarUrl, formatDate, formatDateTime } from "utils/globalsUtils";
import { BrandsTable } from "layouts/itemPageContainer/userItem/brandsTable";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { BoUser } from "models/BoUser";
import {
  deleteProfile,
  getByUserId,
  getQuestionnaireStats,
  updateEndUser,
  updateStatus,
} from "utils/enduserUtils";
import { EndUser } from "models/EndUser";
import { addAlert, setCurrentDialog, useMaterialUIController } from "context";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import DeactivateDialog from "layouts/itemPageContainer/components/deactivateDialog";
import { PackageDetails } from "layouts/endUsers/viewEndUser/components/package";
import { Predictorsetails } from "./components/predictor";
import { IntroStats } from "./components/introStats";
import { PersonalImages } from "./components/images";
import { CSTickets } from "./components/tickets";
import { Profile, ProfileAsset, ProfileStatus } from "models/Profile";
import { PermissionType } from "models/BoRole";
import { MMStatuses } from "models/Matchmaker";
import { CircularProgress, Grid } from "@mui/material";
import { UsageStats } from "./components/usageStats";
import { EUNotificationsSettings } from "./components/notifications";
import { Bio } from "../endUserIntroductions/compenents/bio";
import { AttributesTable } from "layouts/introductions/viewIntroduction/attributesTable";
import { BioPrefList } from "./components/bioPref";

const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;
let newStatus = ProfileStatus.Active;

function ViewEndUser() {
  const pageNumber = "2.7";
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentBrand, currentRole, currentHost } = controller;
  const canEdit = currentRole.hasAccess(pageNumber, PermissionType.Edit);
  const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.ViewAllOptions);
  const [inputErrors, setInputErrors] = useState({});

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);

  const [userData, setUserData] = React.useState(new EndUser());
  const [profileData, setProfileData] = React.useState({} as Profile);
  const [profileAssets, setProfileAssets] = React.useState([] as ProfileAsset[]);

  let { id } = useParams();

  useEffect(() => {
    setLoader(true);
    getProfile();
  }, [id]);

  const getProfile = async () => {
    const { user, profile, profileAssets } = await getByUserId(id || "me");
    user.avatar = avatarUrl(profile.profileId, profileAssets?.[0]?.uri);
    if (!isAdmin) {
      user.email = `${user.email?.substring(0, 3)}...${user.email?.substring(user.email?.indexOf("@"))}`;
      user.phoneNumber = `...${user.phoneNumber?.substring(user.phoneNumber?.length - 3)}`;
    }

    setUserData(Object.assign(new EndUser(), user));
    setProfileData(Object.assign(new Profile(), profile));
    setProfileAssets(profileAssets);

    setLoader(false);
  };

  const updateAttribute = (key, value) => {
    userData[key] = value;
    setUserData(Object.assign(new EndUser(), userData));
  };

  const saveProfile = () => {
    if (Object.values(inputErrors).some((el) => el)) {
      addAlert(dispatch, "Please fix the errors", true);
      return;
    }
    setSaveLoader(true);
    // setUserData({ ...userData });
    updateEndUser(profileData.profileId!, userData).then((res) => {
      setSaveLoader(false);
      console.log(res);
      if (res.message) {
        addAlert(dispatch, "Profile updated successfully",
        );
      } else {
        addAlert(dispatch, "Error updating profile", true);
      }
    });
  };

  const setStatus = async (status: ProfileStatus) => {
    newStatus = status;
    if (status == ProfileStatus.Active) {
      updateEUStatus("");
    } else {
      setCurrentDialog(dispatch, DialogType.DeactivateEntitiy);
    }
  };

  const updateEUStatus = async (statusReason: string) => {
    profileData.statusId = newStatus;
    profileData.attributesValues["statusReason"] = [statusReason];
    setProfileData(Object.assign(new Profile(), profileData));
    await updateStatus(profileData!.profileId!, newStatus, statusReason);
    addAlert(dispatch, "Status updated successfully");
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    addAlert(dispatch, "Copied to clipboard");
  };
  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={loader ? "Loading..." : userData.firstName} />
      <ItemPageContainer
        loader={loader}
        navItems={[
          { label: "Profile & Status" },
          { label: "Personal details", ref: "personal" },
          { label: "Bio & Preferences", ref: "bio_pref" },
          { label: "Package details", ref: "package" },
          { label: "Questionnaire Details", ref: "predictors" },
          { label: "Personal Images", ref: "images" },
          { label: "User Statistics", ref: "statistics" },
          { label: "Usage Statistics", ref: "usage" },
          { label: "CS Tickets", ref: "tickets" },
          { label: "Notifications", ref: "notifications" },
        ]}
        actions={{
          itemName: `System User`,
          itemNumber: (
            <Grid container style={{ cursor: isAdmin ? "pointer" : "default" }}>
              <Grid onClick={isAdmin ? () => copyToClipboard(id!) : undefined} item>
                User ID: ...{id?.substring(24)}
              </Grid>
              <Grid
                onClick={isAdmin ? () => copyToClipboard(profileData.profileId!) : undefined}
                item
              >
                Profile ID: ...{profileData.profileId?.substring(24)}
              </Grid>
            </Grid>
          ),
          actionsTitle: "User Actions",
          actions: [
            {
              hidden: !canEdit,
              title: "Save",
              onClick: saveProfile,
              variant: "contained",
            },
          ],
        }}
        itemStatus={{
          title: "User Status",
          item: profileData,
          changeStatus: setStatus,
          isManager: canEdit,
        }}
        itemHeader={
          <SMEHeader
            formData={userData}
            indications={[
              {
                title: "Created:",
                value: formatDate(userData.created),
              },
              {
                title: "Last Login:",
                value: formatDate(userData.lastLogin),
              },
              {
                title: "Gender:",
                value: profileData?.gender
              },
              {
                title: "Age:",
                value: userData.age,
              },
              {
                title: "Location:",
                value: profileData.location,
              },
              {
                hidden: !profileData.isInternal,
                title: "",
                color: "error",
                value: "Internal",
              },
            ]}
            saveProfile={saveProfile}
          />
        }
        deleteItem={
          currentRole.hasAccess(pageNumber, PermissionType.Delete) && {
            buttonText: "Delete End User",
            contetnText: "Are you sure you want to delete this user?",
            onDelete: () => deleteProfile(profileData.profileId!).then(() => navigate(-1)),
          }
        }
      >
        <CardItem title="Personal details" id="personal">
          <SMEBasic
            formData={userData}
            updateAttribute={updateAttribute}
            setFormData={setUserData}
            isAdmin={isAdmin}
            inputErrors={inputErrors}
            showPassword={false}
            canEdit={canEdit}
            showBirthdate
          />
        </CardItem>
        {isAdmin && (
          <CardItem title="Bio & Preferences" id="bio_pref">
            <BioPrefList profile={profileData} />
            {/* <Bio profile={profileData} user={userData} /> */}
          </CardItem>
        )}

        <CardItem title="Package details" id="package">
          <PackageDetails profile={profileData} />
        </CardItem>

        {isAdmin && (
          <CardItem title="Questionnaire Details" id="predictors">
            <Predictorsetails profileId={profileData.profileId || ""} />
          </CardItem>
        )}

        <CardItem title="Personal Images" id="images">
          <PersonalImages profileId={profileData.profileId || ""} />
        </CardItem>

        {isAdmin && (
          <CardItem title="User Statistics" id="statistics">
            <IntroStats
              profileId={profileData.profileId || ""}
              profileName={userData.firstName || ""}
            />
          </CardItem>
        )}

        {isAdmin && (
          <CardItem title="Usage Statistics" id="usage">
            <UsageStats profileId={profileData.profileId || ""} />
          </CardItem>
        )}

        <CardItem title="CS Tickets" id="tickets">
          <CSTickets profileId={profileData.profileId || ""} />
        </CardItem>

        <CardItem title="Notifications" id="notifications">
          <EUNotificationsSettings profileId={profileData.profileId || ""} />
        </CardItem>
      </ItemPageContainer>

      <DeactivateDialog
        title="changeStatusProfileDialogTitle"
        content="changeStatusProfileDialogContent"
        onSubmit={(reason) => updateEUStatus(reason)}
        reasonLabel="changeStatusProfileReason"
        confirm="Update End User"
      />
    </DashboardLayout>
  );
}

export default ViewEndUser;
