import { Delete, DeleteOutline } from "@mui/icons-material";
import { Autocomplete, Avatar, Chip, Grid, TextField } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import CardItem from "components/alove/CardItem";
import { useMaterialUIController } from "context";
import { BoRole } from "models/BoRole";
import { BoUser } from "models/BoUser";
import { Brand } from "models/Brand";
import { useEffect, useState } from "react";
import { brandLogo, getBrandsDO } from "utils/brandsUtils";
import { filterToMyRoles, getRoleByArrayDO, getRoleDO, getRolesDO } from "utils/rolesUtils";
import { updateUserDO } from "utils/systemUsersUtils";

const MDTypography = require("components/MDTypography/index.js").default;

interface BrandsTableProps {
  user: BoUser;
  canEdit?: boolean;
}
export const BrandsTable = ({ user, canEdit }: BrandsTableProps) => {
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentRole, currentBrand } = controller;
  const [brands, setBrands] = useState([] as any[]);
  const [roles, setRoles] = useState([] as BoRole[]);
  const [columns, setColumns] = useState([] as GridColDef[]);

  useEffect(() => {
    const cols: GridColDef[] = [
      {
        headerName: "Last Active",
        field: "status",
      },
      {
        headerName: "Role(s)",
        field: "roles",
        flex: 1,
        renderCell: (params) => (
          <Autocomplete
            multiple
            size="small"
            sx={{ mt: 1 }}
            disabled={!canEdit}
            defaultValue={params.row.roles}
            options={roles.filter((r) => r.brand_id === params.id)}
            getOptionLabel={(option) => option.role_name}
            onChange={(e, value) => chnageRoles(value, params.id)}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        ),
      },
    ];
    if (currentBrand.isAlove) {
      cols.splice(0, 0, {
        headerName: "Brand",
        field: "name",
        width: 200,
        renderCell: (params) => {
          return (
            <Grid container gap={2} alignItems="center" flexDirection="row" sx={{ height: "100%" }}>
              <Avatar
                src={brandLogo(params.row)}
              />
              <MDTypography variant="body2">{params.row.name}</MDTypography>
            </Grid>
          );
        },
      });
      cols.push({
        field: "actions",
        type: "actions",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<DeleteOutline />}
            label="Delete"
            onClick={() => removeBrand(params.id)}
          />,
        ],
      });
    }
    setColumns(cols);
  }, [currentBrand,roles]);

  const chnageRoles = (value, brandId) => {
    user.role[brandId].roles = value.map((v) => v.id);
    updateUserDO("dev", user);
  };

  const removeBrand = (brandId) => {
    user.brands = user.brands.filter((b) => b !== brandId);
    delete user.role[brandId];
    updateUserDO("dev", user).then((res) => {
      if (res) {
        setBrands(brands.filter((b) => b.id !== brandId));
      }
    });
  };

  useEffect(() => {
    if (!user?.role) return;
    Promise.all([
      getBrandsDO("dev"),
      getRoleByArrayDO(
        "dev",
        Object.values(user?.role || {})
          .map((r) => r.roles)
          .flat()
      ),
    ]).then(([brands, roles]) => {
      setBrands(
        brands
          .filter((brand) => user?.brands?.includes(brand.id))
          .map((brand) => ({
            ...brand,
            roles: user?.role?.[brand.id]?.roles?.map((r) => roles?.find((role) => role.id === r)),
          }))
      );
    });
    getRolesDO("dev", user?.brands).then((res) => {
      const rs = filterToMyRoles(res, currentUser, currentRole)
      setRoles(rs);
    });
  }, [user]);
  return <DataGrid rows={brands} columns={columns} hideFooter autoHeight />;
};
