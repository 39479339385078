import { Autocomplete, TextField } from "@mui/material";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { setCurrentDialog, useMaterialUIController } from "context";
import { BoRole } from "models/BoRole";
import { BoUser, BoUserRole } from "models/BoUser";
import { Brand } from "models/Brand";
import { useEffect, useState } from "react";
import { getBrandsDO } from "utils/brandsUtils";
import { networkGlobals } from "utils/networkUtils";
import { getRoleDO, getRolesDO } from "utils/rolesUtils";
import { updateUserDO } from "utils/systemUsersUtils";

let brandId, roleIds;

export const AddNewBrand = ({ user, onAdd }: { user: BoUser; onAdd }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { currentDialog } = controller;
  const [brands, setBrands] = useState([] as Brand[]);
  const [roles, setRoles] = useState([] as BoRole[]);
  const [valid, setValid] = useState(false);
  useEffect(() => {
    if (!brands.length) {
      getBrandsDO(networkGlobals.host).then((res) => {
        setBrands(res);
      });
    }
    brandId = null;
    roleIds = [];
    setValid(false);
  }, [currentDialog]);
  const changeBrand = (e, b) => {
    getRolesDO("dev", [b.id]).then((res) => {
      setRoles(res);
    });
    brandId = b.id;
  };
  const changeRole = (e, r) => {
    setValid(r.length);
    roleIds = r.map((role) => role.id);
  };
  const onConfirm = async () => {
    user.brands.push(brandId);
    user.role[brandId] = new BoUserRole();
    user.role[brandId].roles = roleIds;
    return updateUserDO("dev", user).then((res) => {
      if (res) {
        setCurrentDialog(dispatch, "");
        onAdd();
      }
    });
  };
  return (
    <AloveDialog
      dialogType={DialogType.AddBrandToUser}
      dialogConfirm="Add Brand"
      dialogTitle="Add New Brand"
      handleConfirm={onConfirm}
      valid={valid}
    >
      <Autocomplete
        disableClearable
        id="combo-box-demo"
        options={brands.filter((b) => !user?.brands?.includes(b.id))}
        onChange={changeBrand}
        noOptionsText="This user already has access to all brands"
        getOptionLabel={(option) => option.name}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Brand" />}
      />
      <Autocomplete
        disableClearable
        multiple
        limitTags={1}
        id="combo-box-demo"
        options={roles || []}
        getOptionLabel={(option) => option.role_name}
        onChange={changeRole}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Role(s)" />}
      />
    </AloveDialog>
  );
};
