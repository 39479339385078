import axios from "axios";
import { networkGlobals } from "./networkUtils";


function getHost() {
    return networkGlobals.serverHost;
}

export const getTranslationsByInputDO = async (host, lang, letter, brandID, page) => {
    return new Promise((resolve, reject) => {
        axios.get(`${networkGlobals.serverHost}/api/translate/byInput/?host=${host}&lang=${lang}&letter=${letter}&brandID=${brandID}&page=${page}`)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get translations:', error)
                reject(error);
            })
    })
}

export const getTranslationsByStatusDO = async (host, lang, status, page, brandID) => {
    return new Promise((resolve, reject) => {
        axios.get(`${networkGlobals.serverHost}/api/translate/byStatus/?host=${host}&lang=${lang}&status=${status}&brandID=${brandID}&page=${page}`)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get translations:', error)
                reject(error);
            })
    })
}

export const getTranslationsByLetterDO = async (host, lang, letter, page, brandID, excludeList) => {
    return new Promise((resolve, reject) => {
        console.log('networkGlobals.serverHost: ', networkGlobals.serverHost);
        axios.post(`${networkGlobals.serverHost}/api/translate/byLetter`, { host, lang, letter, brandID, excludeList, page })
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get translations:', error)
                reject(error);
            })
    })
}

export const getTranslationsByPositionsDO = async (host, lang, position, page, brandID) => {
    return new Promise((resolve, reject) => {
        axios.get(`${networkGlobals.serverHost}/api/translate/byType/?host=${host}&lang=${lang}&position=${position}&page=${page}&brandID=${brandID}`)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get translations:', error)
                reject(error);
            })
    })
}

export const deleteTeanslationDO = async (host, translation, brandID) => {
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/translate/delete/`, { host, translation, brandID })
            .then(res => {
                console.log('res from deploy: ', res);
                resolve(res);
            })
            .catch(error => {
                console.error('error from get translation positions:', error)
                reject(error);
            })
    })
}

export const deployTranslationsDO = async (host, brandName, brandID) => {
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/translate/deploy/`, { host, brandName: brandName.toLowerCase(), brandID })
            .then(res => {
                console.log('res from deploy: ', res);
                resolve(res);
            })
            .catch(error => {
                console.error('error from get translation positions:', error)
                reject(error);
            })
    })
}

/** copy translateions */
export const copyTranslationDO = async (host, brandID, translation, userID) => {
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/translate/translationCopy/`, { host, brandID, translation, userID })
            .then(res => {
                console.log('res from deploy: ', res);
                resolve(res);
            })
            .catch(error => {
                console.error('error from get translation positions:', error)
                reject(error);
            })
    })
}

export const getPositionsDO = async (host, where) => {
    return new Promise((resolve, reject) => {
        axios.get(`${networkGlobals.serverHost}/api/translate/translationsPositions/?host=${host||networkGlobals.host}&where=${where}`)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get translation positions:', error)
                reject(error);
            })
    })
}


export const getAvailablePositionsDO = async (host, where, brandId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${networkGlobals.serverHost}/api/translate/translationsPositions/available?host=${host}&where=${where}&brandID=${brandId}`)
            .then(res => {
                resolve(res?.data);
            })
            .catch(error => {
                console.error('error from get translation positions:', error)
                reject(error);
            })
    })
}

/** add new translation */
export const addNewTranslationDO = async (name, description, positions, createdBy, host, brandID) => {
    console.log('brandID addNewTranslationDO:', brandID);
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/contents/`, { name, description, positions, createdBy, host, brandID })
            .then(res => {
                resolve(res?.status);
            })
            .catch(error => {
                console.error('error from put translate DO:', error);
                resolve(error);
                // reject(error);
            })
    })
}

/** update translation values */
export const updateTranslationValuesDO = async (host, data, brandID) => {
    return new Promise((resolve, reject) => {
        axios.put(`${networkGlobals.serverHost}/api/translate/`, { data, host, brandID })
            .then(res => {
                resolve(res?.status);
            })
            .catch(error => {
                console.error('error from put translate DO:', error)
                reject(error);
            })
    })
}

export const translationsLanguagesDO = async (host, where) => {
    return new Promise((resolve, reject) => {
        console.log('networkGlobals.serverHost1: ', networkGlobals.serverHost);
        axios.get(`${networkGlobals.serverHost}/api/translate/translationsLanguages/?host=${host}`)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error  from get new trans langs:', error)
                reject(error);
            })
    })
}

export const addNewTranslationLangDO = async (host, data) => {
    return new Promise((resolve, reject) => {
        console.log('networkGlobals.serverHost: ', networkGlobals.serverHost);
        axios.post(`${networkGlobals.serverHost}/api/translate/addNewLang`, { host, data })
            .then(res => {
                resolve(res?.status);
            })
            .catch(error => {
                console.error('error from add new permission:', error)
                reject(error);
            })
    })
}

/** update completed status */
export const updateIsCompletedStatuDO = async (host, id, status) => {
    return new Promise((resolve, reject) => {
        axios.put(`${networkGlobals.serverHost}/api/translate/completed`, { id, host, status })
            .then(res => {
                resolve(res?.status);
            })
            .catch(error => {
                console.error('error from put translate DO:', error)
                reject(error);
            })
    })
}

/** update completed status */
export const updateLangValueDO = async (host, where, id, value, brandID) => {
    console.log('brandID: ', brandID);
    return new Promise((resolve, reject) => {
        axios.put(`${networkGlobals.serverHost}/api/translate/language`, { host, where, id, value, brandID})
            .then(res => {
                resolve(res?.status);
            })
            .catch(error => {
                console.error('error from put translate DO:', error)
                reject(error);
            })
    })
}

/** update completed status */
export const publishLangsDO = async (host, brandID, brandName, langCode) => {
    console.log('brandID from utils: ', brandID);
    console.log('host from utils: ', host);
    console.log('brandName from utils: ', brandName);
    console.log('langCode from utils: ', langCode);
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/translate/publishLanguages`, {host, brandID, brandName, langCode})
            .then(res => {
                console.log('res: ', res);
                
                resolve(res?.status);
            })
            .catch(error => {
                console.error('error from put translate DO:', error)
                reject(error);
            })
    })
}


