import { Card, Grid, LinearProgress, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import { OutlinedBox } from "components/alove/OutlinedBox";
import { T } from "components/alove/Translator";
import { Introduction } from "models/Introduction";
import React from "react";

export const MMProgress = ({shRequest}:{shRequest:Introduction}) => {
  const statuses = [5, 9, 10, 11, 12, 13, 14];
  const index = statuses.indexOf(shRequest.statusId);
  let progress = (index * 100) / (statuses.length - 1);
  progress += 6 - index * 2;

  return (
        <React.Fragment>
          <Grid display="flex" justifyContent="space-between"  alignItems="start">
            {statuses.map((status, index) => (
              <Typography
                width="12%"
                key={index}
                variant="body2"
                fontSize={12}
                fontWeight="medium"
                alignContent="end"
                textAlign="center"
                color={shRequest.statusId >= status ? "#16D2BC" : "grey"}
              >
                <T>statusProgress{status}</T>
              </Typography>
            ))}
          </Grid>
          <Grid display="flex" justifyContent="space-between">
            <div
              style={{
                backgroundColor: "#16D2BC",
                width: progress + "%",
                height: "10px",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
            />
            <div
              style={{
                backgroundColor: "#EDEDED",
                width: 100 - progress + "%",
                height: "10px",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            />
          </Grid>
        </React.Fragment>
  );
};
