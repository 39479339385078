import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Chip, Divider, Icon, List } from "@mui/material";

import { GridColDef } from "@mui/x-data-grid";
import { TableColumnProps, TablePage } from "components/alove/TablePage";
import { getUsersStatsDO, getSystemUsersDO } from "utils/systemUsersUtils";
import { PermissionType } from "models/BoRole";
import { brandLogo, getBrandsDO } from "utils/brandsUtils";
import { Brand } from "models/Brand";
import { BoUser } from "models/BoUser";
import { TwoSizeChip } from "components/alove/Chips";
import { formatDateTime, formatDate } from "utils/globalsUtils";
import { networkGlobals } from "utils/networkUtils";
const { useMaterialUIController } = require("context");
const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDAvatar = require("components/MDAvatar/index.js").default;

function ViewSystemUsersList() {
  const pageNumber = "2.3";
  const [controller, dispatch] = useMaterialUIController();

  const { currentBrand, currentRole, currentUser } = controller;
  const [brands, setBrands] = useState([] as Brand[]);
  const navigate = useNavigate();
  const [stats, setStats] = useState({});
  const columns: TableColumnProps<BoUser>[] = [
    {
      headerName: "Brand(s)",
      field: "brands",
      flex: 1,
      hidden: (column) => currentBrand.id != 100,
      renderCell: (params) => renderBrands(params),
    },
    { headerName: "First Name", field: "first_name", flex: 1, minWidth: 100 },
    { headerName: "Last Name", field: "last_name", flex: 1, minWidth: 100 },
    { headerName: "Email", field: "email", flex: 1, minWidth: 200 },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 150 },
    // {
    //   headerName: "roles",
    //   field: "roles",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: (params) => (
    //     <Grid
    //       gap={1}
    //       container
    //       display="flex"
    //       flexDirection="row"
    //       alignItems="center"
    //       height="100%"
    //     >
    //       {params.row.role?.split(",").map((role, index) => (
    //         <Chip size="small" key={index} label={role} />
    //       ))}
    //     </Grid>
    //   ),
    //   editable: true,
    //   type: "singleSelect",
    //   valueOptions: ["Super Admin", "Brand Admin", "Content Editor", "Matchmaker", "Support"],
    // },
    {
      headerName: "Status",
      field: "is_active",
      renderCell: (params) => {
        const user = Object.assign(new BoUser(), params.row);
        return <TwoSizeChip label={user.statusLabel} color={user.statusColor as any} />;
      }
    },
    {
      headerName: "Created",
      field: "created",
      flex: 1,
      minWidth: 170,
      renderCell: (params) => formatDateTime(params.row.created),
    },
    {
      headerName: "Actions",
      flex: 1,
      field: "actions",

      align: "center",
      renderCell: (params) => (
        <Icon onClick={() => navigate(`/systemUsers/systemUser?id=${params.id}`)}>edit</Icon>
      ),
    },
  ];

  useEffect(() => {
    if (!currentBrand?.id) return;
    getBrandsDO(networkGlobals.host).then((res) => {
      setBrands(res);
    });
  }, [currentBrand]);

  const getSystemUser = async (host: string, filters: any, page: number) => {
    if (!currentUser) return [];
    let roles
    if (!currentRole.hasAccess(pageNumber, PermissionType.ViewAllOptions)) {
      roles = currentUser?.roles?.[ filters["brand"] || 100]?.roles  
    }

    getUsersStatsDO("dev", filters["brand"]).then((res) => {
      setStats(res);
    });

    const systemUsers = await getSystemUsersDO(host, filters["brand"],roles,page);

    
    
    return systemUsers
  };

  const renderBrands = (params) => {
    const brandsCount = Math.floor(params.colDef?.computedWidth / 44) - 1;
    return (
      <Grid
        container
        gap={1}
        alignItems="center"
        flexDirection="row"
        sx={{ height: "100%" }}
        wrap="nowrap"
      >
        {params.row.brands
          ?.slice(0, brandsCount)
          .sort((a, b) => a - b)
          .map((brand, index) => (
            <MDAvatar
            key={index}
              src={brandLogo(brands.find((b) => b.id == brand))}
              bgColor="light"
              size="sm"
            />
          ))}
          {params.row.brands?.length > brandsCount && (
            <Chip size="small" label={`+${params.row.brands.length - brandsCount}`} />
          )}
      </Grid>
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="System Users" />
      <TablePage
        title="Users List"
        table={{
          columns,
          loadNext: getSystemUser,
          lineClick: (row) => navigate(`${row.id}`),
        }}
        filters={
          currentBrand.id == 100
            ? [
                {
                  label: "brand",
                  options: brands?.map((brand) => ({ key: brand.id, label: brand.name })),
                },
              ]
            : []
        }
        actionsInFiltersRow
        actions={{
          title: "Users Actions",
          page: pageNumber,
          addActions: [
            {
              label: "User",
              onClick: () => navigate("/systemUsers/new"),
              color: "secondary",
              variant: "contained",
              role: PermissionType.AddNew,
            },
          ],
        }}
        stats={
          currentRole.hasAccess(pageNumber, PermissionType.ViewAllOptions) && {
            title: "Users Statistics",
            fields: [
              {
                title: "Total Users",
                name: "total",
              },
              {
                title: "Active Users",
                name: "active",
                color: "primary",
              },
            ],
            stats,
          }
        }
      />
    </DashboardLayout>
  );
}

export default ViewSystemUsersList;
