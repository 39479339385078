import axios from "axios";
import { Brand } from "models/Brand";

const serverHost = "https://api.a-dmin.ai";

export let networkGlobals = {
  host: window.localStorage.getItem("currentHost")  || "prod",
  brand: JSON.parse(window.localStorage.getItem("currentBrand") || "{}") as Brand,
  errorCallbacks: [] as Function[],
  serverHost: location.hostname === "localhost" ? "http://localhost:5001" : serverHost,
};

export const makeRequest = (
  url,
  { method, body, auth,basePath,withoutError }: { method?: string; body?: any; auth?: boolean, basePath?: string, withoutError?:boolean } = {}
) => {
  
  if (!url.includes("host")) {
    url += url.includes("?") ? "&" : "?";
    url += `host=${networkGlobals.host}`;
  }


  if (!url.includes("brandID") && networkGlobals.brand.id) {
    url += url.includes("?") ? "&" : "?";
    url += `brandID=${networkGlobals.brand.id}`;
  }

  console.log('body: ', body);
  console.log('url: ', url);
  
  return axios({
    method: method || "GET",
    url: (basePath || `${networkGlobals.serverHost}/api/` ) + url,
    data: body,
    headers: auth
      ? {}
      : {
          Authorization: localStorage.getItem("BOToken"),
        },
  })
    .then((res) => res.data)
    // .then((res) => console.log('res: ', res))
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        localStorage.removeItem("BOToken");
        window.location.href = "#"
        return;
      }
      if (!withoutError){
        console.log(err);
        networkGlobals.errorCallbacks.forEach((cb) => cb(err));
      } else {
        throw err;
      }

    });
};

export const getBucket = () => {
  let bucket = "";
  switch (networkGlobals.host) {
    case "dev":
      bucket = "alove-dev-assets";
      break;
    case "staging":
      bucket = "mujual-staging-assets";
      break;
    case "prod":
      bucket = "mujual-prod-assets";
      break;
    default:
      break;
  }
  return bucket;
}

export const getLambadaSubdomain = () => {
  let host = "";
  switch (networkGlobals.host) {
    case "dev":
      host = "alove-dev";
      break;
    case "staging":
      host = "mujual-staging";
      break;
    case "prod":
      host = "mujual";
      break;
    default:
      break;
  }
  return host;
}

export const uploadImage = async (base64: string) => {
  const formData = new FormData();  
  formData.append("image", base64);
  formData.append("folder", "screenImages");
  return makeRequest("utils/images", {
    method: "POST",
    body: formData
  });
}

export const fileLink = (url: string) => {
  return `${networkGlobals.serverHost}/api/utils/file?url=${url}`;
}