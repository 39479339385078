import { Divider } from "@mui/material";

export const VerticalDivider = ({ hidden, height }: { hidden?: boolean; height?: string }) => {
  return (
    <Divider
      orientation="vertical"
      style={{
        height: height || "32px",
        color: "black",
        borderStyle: "dotted",
        borderWidth: "2px",
        visibility: hidden ? "hidden" : "visible",
      }}
    />
  );
};
