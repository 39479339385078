import * as React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams } from "react-router-dom";
import { Autocomplete, Card } from "@mui/material/";
import MDBox from "components/MDBox";
const MDSnackbar = require("components/MDSnackbar").default;
const MDInput = require("components/MDInput").default;
import {
  GridColDef,
  GridEditBooleanCell,
  GridRenderEditCellParams,
  LicenseInfo,
} from "@mui/x-data-grid-pro";
import { useDemoData } from "@mui/x-data-grid-generator";
import { useMaterialUIController } from "context";
import selectData from "./data/data";
import { getRoleDO, updatePermissions } from "utils/rolesUtils";
import { TableColumnProps, TablePage } from "components/alove/TablePage";
import { render } from "@testing-library/react";
import { BoRole, Control, PermissionType } from "models/BoRole";
import { camelCaseToCapitalizedWords, snakeCaseToCapitalizedWords, uniqueBy } from "utils/globalsUtils";
import routes from "routes";

LicenseInfo.setLicenseKey(process.env.LICENSE_KEY || "test");

const visibleFields = ["commodity"];

export default function Permissions() {
  const { id } = useParams();
  const [controller] = useMaterialUIController();
  const { currentUser, currentRole } = controller;
  const [snackbar, setSnackbar] = React.useState({ status: false, color: "", icon: "", title: "" });
  const { loading } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    editable: true,
    visibleFields,
  });
  // const apiRef = useGridApiRef();
  const [role, setRole] = React.useState({} as BoRole);
  const [rows, setRows] = React.useState([] as any);

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  const prepareRows = async () => {
    try {
      const roleItems = await getRoleDO("dev", id);
      // if (role) {
      // }
      setRole(roleItems[0]);
      let rows = routes
        ?.filter((item) => location.hostname == "localhost"  ||  currentRole?.pages.includes(Number(item.accessNumber)))
        .flatMap((item) =>
          item.collapse
            ?.filter((subItem) => location.hostname == "localhost"  ||  currentRole?.pages.includes(Number(subItem.accessNumber)))
            .map((subItem) => ({
              id: subItem.accessNumber,
              commodity: item.name,
              label: subItem.name,
              acceess: roleItems[0]?.permissions?.pages.includes(Number(subItem.accessNumber)),
              edit: roleItems[0]?.permissions?.controls?.some(
                (control) =>
                  control.page === Number(subItem.accessNumber) && control.access.includes(100)
              ),
              add_new: roleItems[0]?.permissions?.controls?.some(
                (control) =>
                  control.page === Number(subItem.accessNumber) && control.access.includes(101)
              ),
              delete: roleItems[0]?.permissions?.controls?.some(
                (control) =>
                  control.page === Number(subItem.accessNumber) && control.access.includes(102)
              ),
              duplicate: roleItems[0]?.permissions?.controls?.some(
                (control) =>
                  control.page === Number(subItem.accessNumber) && control.access.includes(103)
              ),
            }))
        );
        rows = uniqueBy(rows, "id");
      setRows(rows);
      prepareTitles(rows, roleItems);
      console.log("rows: ", rows);
      return rows;
    } catch (error) {
      console.error("Failed to prepare rows:", error);
    }
  };

  const prepareTitles = (rows, roleItems) => {
    console.log("rows in prepare titles: ", rows);
    // console.log('roleItems: ', roleItems);

    let newRows: any[] = [];
    // console.log('roleItems?.permissions?.pages: ', roleItems[0]?.permissions?.pages);
    for (const roleItem of roleItems[0]?.permissions?.pages) {
      console.log("roleItem: ", roleItem);
      // console.log('aaaa',Number.isInteger(roleItem));
      if (!Number.isInteger(roleItem) && roleItem != 0) {
        console.log("true");
        const row = rows.find((row) => row.id === roleItem);
        newRows.push(row);
        console.log("row:", row);
      } else {
        console.log("false");
      }
    }
    setRows(newRows);
  };

  const handleCheckboxChange = async (newRow, oldRow) => {
    console.log("newRow: ", newRow);
    console.log("oldRow: ", oldRow);

    const fieldName = Object.keys(newRow).find((key) => newRow[key] !== oldRow[key]) as string;
    if (!fieldName) return newRow;
    const checked = newRow[fieldName];
    const rowId = newRow.id;

    const itemCode = selectData?.labels?.find((item) => item?.name === fieldName);

    await updateSetting(checked, itemCode!.id, rowId);

    // if (updateStatus === 200) {
    //   setSnackbar({
    //     status: true,
    //     color: updateStatus === 200 ? 'success' : 'error',
    //     icon: updateStatus === 200 ? 'check' : 'error',
    //     title: updateStatus === 200 ? 'Role was updated' : 'Something happened!',
    //   });
    // }

    return newRow;
  };

  const updateSetting = async (checked: boolean, itemId: PermissionType, rowId: number) => {
    let roleObj: BoRole = role;
    if (!roleObj) return;

    let control = roleObj.permissions.controls?.find((item) => Number(item?.page) == rowId);

    let pages = roleObj.permissions.pages;

    if (!control) {
      control = Object.assign(new Control(), { page: rowId, access: [] });
      roleObj?.permissions?.controls?.push(control);
    }

    const accessGroup = control.access;
    try {
      let updateStatus;
      if (checked) {
        if (itemId === 99) {
          pages.push(rowId);
          updateStatus = await updatePermissions("dev", [role], id);
        } else if (!accessGroup?.includes(itemId)) {
          if (control?.access?.length) {
            accessGroup.push(itemId);
            control.access = accessGroup;
          } else {
            control.access.push(itemId);
          }

          updateStatus = await updatePermissions("dev", [role], id);
        }
      } else if (checked === false) {
        const indexAccess = accessGroup.indexOf(itemId);
        if (indexAccess > -1) {
          accessGroup.splice(indexAccess, 1);
        }

        if (itemId === 99) {
          const indexPages = pages.indexOf(rowId);
          if (indexPages > -1) {
            pages.splice(indexPages, 1);
          }
        }
        updateStatus = await updatePermissions("dev", [role], id);
      }
    } catch (error) {
      console.error("Failed to update permissions:", error);
      setSnackbar({
        status: true,
        color: "error",
        icon: "error",
        title: "Something happened!",
      });
    }
  };

  const changeExtra = (selected: string[], oldRow) => {
    const oldPermission =
      role?.permissions?.controls
        ?.find((control) => control.page === oldRow.id)
        ?.access.filter((a) => a) || [];
    const changed = selected.filter((item) => !oldPermission!.includes(Number(item)));
    const removed = oldPermission!
      .filter((item) => !selected.includes(item.toString()))
      .filter((a) => isExtra(a));
    const itemId = changed.length ? changed[0] : removed[0];
    updateSetting(!!changed.length, Number(itemId), oldRow.id);
  };

  const columns: TableColumnProps<any>[] = [
    {
      field: "id",
      hidden: () => true,
    },
    {
      field: "acceess",
      headerName: "Access",
      align: "center",
      type: "boolean",
      flex: 1,
      editable: true,
    },
    {
      field: "edit",
      headerName: "Edit/Save",
      align: "center",
      type: "boolean",
      flex: 1,
      editable: true,
      renderEditCell: (params) => renderEditCell(PermissionType.Edit, params),
    },
    {
      field: "add_new",
      headerName: "Add/New",
      align: "center",
      type: "boolean",
      flex: 1,
      editable: true,
      renderEditCell: (params) => renderEditCell(PermissionType.AddNew, params),
    },
    {
      field: "delete",
      headerName: "Delete",
      align: "center",
      type: "boolean",
      flex: 1,
      editable: true,
      renderEditCell: (params) => renderEditCell(PermissionType.Delete, params),
    },
    {
      field: "extra",
      headerName: "Extra",
      flex: 4,
      renderCell: (params) =>
        parseFloat(params?.id.toString()) ? (
          <Autocomplete
            multiple
            size="small"
            sx={{ mt: 1 }}
            limitTags={4}
            getOptionDisabled={(option) => !currentRole.hasAccess(params.row.id, Number(option))}
            defaultValue={role?.permissions?.controls
              ?.find((control) => control.page === params.row.id)
              ?.access.filter((a) => isExtra(a))
              .map((a) => a.toString())}
            onChange={(e, v) => changeExtra(v, params.row)}
            options={Object.keys(PermissionType).filter(
              (key) => Number(key as any) && isExtra(Number(key as any))
            )}
            getOptionLabel={(option) => camelCaseToCapitalizedWords(PermissionType[option])}
            renderInput={(params) => <MDInput {...params} variant="outlined" />}
          />
        ) : null,
    },
  ];

  const renderEditCell = (permission: PermissionType, params: GridRenderEditCellParams) => {
    if (currentRole.hasAccess(params.row.id, permission)) {
      return <GridEditBooleanCell {...params} />;
    }
    return <MDBox />;
  };

  const isExtra = (id: PermissionType) => id >= PermissionType.Duplicate;

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={role?.role_name} />
      <TablePage
        title="Permissions"
        table={{
          columns,
          loadNext: prepareRows,
          getTreeDataPath: (row) => [row.commodity, row.label],
          processRowUpdate: handleCheckboxChange,
        }}
      />

      <MDSnackbar
        color={snackbar.color || "success"}
        icon={snackbar.icon || "check"}
        title={snackbar.title || ""}
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}
