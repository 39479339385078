import colors from "assets/theme/base/colors";

export enum BoRole {
  SuperAdmin = 1,
  BrandAdmin = 2,
  Matchmaker = 53,
}

export interface Statusable {
  status: string | number | any;
  statuses: { key:string; value:string }[];
  statusColor?: string;
  statusLabel?: string;
  statusReason?: string;
}

export class BoUser implements Statusable {
  id: number;
  email: string;
  password: string;
  created: Date;
  updated: Date;
  first_name: string;
  last_name: string;
  phone: string;
  lang: string;
  role: {
    [key: string]: BoUserRole;
  };
  avatar?: string;
  is_active: boolean;
  brands: any;
  is_online: boolean;
  last_active: Date;
  hidden_from_cs: boolean;
  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  get statusLabel(): string {
    return this.is_active ? "Active" : "Inactive";
  }

  get statusColor(): string {
    return this.is_active ? "success" : "error";
  }

  get statuses(): { key:string; value:string }[] {
    return [{ key: "Active", value: "Active" }, { key: "Inactive", value: "Inactive" }];
  }

  get statusReason(): string {
    return ""
  }

  get status(): string {
    return this.is_active ? "Active" : "Inactive";
  }


}

export class BoUserRole {
  roles: number[];
}