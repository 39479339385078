import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";

export default function Configuration(props) {

    const { configSwitcher, setConfigwitcher, question,setQuestion } = { ...props };

    return (
        <MDBox pt={2} pb={3}>
            <Card>
                <MDBox p={2} lineHeight={1}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} xl={12}>
                            <MDTypography variant="h6" fontWeight="medium">
                                Configuration
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12} xl={12}>
                            <MDBox display="flex" alignItems="center" mb={0.1} ml={-1.5}>
                                <MDBox mt={0.1}>
                                    <Switch
                                        checked={configSwitcher.isCoreQuestion}
                                        onChange={() => setConfigwitcher({ ...configSwitcher, isCoreQuestion: !configSwitcher.isCoreQuestion })}
                                    />
                                </MDBox>
                                <MDBox width="80%" ml={0.5}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Core Question
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} xl={12}>
                            <MDBox display="flex" alignItems="center" mb={0.1} ml={-1.5}>
                                <MDBox mt={0.1}>
                                    <Switch checked={question.skip_allowed} onChange={() => setQuestion({ ...question, skip_allowed: !question.skip_allowed })} />
                                </MDBox>
                                <MDBox width="80%" ml={0.5}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Enable Skip
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} xl={12}>
                            <MDBox display="flex" alignItems="center" mb={0.1} ml={-1.5}>
                                <MDBox mt={0.1}>
                                    <Switch checked={question.used_for_scoring} onChange={(c) => setQuestion({ ...question, used_for_scoring: c.target.checked })} />
                                </MDBox>
                                <MDBox width="80%" ml={0.5}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Used for scoring
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </Grid>
                        {question.questionType?.name === 'Bio' ? (
                            <Grid item xs={12} xl={12}>
                                <MDBox display="flex" alignItems="center" mb={0.1} ml={-1.5}>
                                    <MDBox mt={0.1}>
                                        <Switch checked={configSwitcher.ignorePref} onChange={() => setConfigwitcher({ ...configSwitcher, ignorePref: !configSwitcher.ignorePref })} />
                                    </MDBox>
                                    <MDBox width="80%" ml={0.5}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Ignore pref (For initiation)
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </Grid>
                        ) : null}
                        <Grid item xs={12} xl={12}>
                            <MDBox display="flex" alignItems="center" mb={0.1} ml={-1.5}>
                                <MDBox mt={0.1}>
                                <Switch checked={question.is_deal_breaker} onChange={(c) => setQuestion({ ...question, is_deal_breaker: c.target.checked })} />
                                </MDBox>
                                <MDBox width="80%" ml={0.5}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                    Deal Breaker
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </Grid>
                        {question.questionType?.name === "Predictor" &&
                        <Grid item xs={12} xl={12}>
                            <MDBox display="flex" alignItems="center" mb={0.1} ml={-1.5}>
                                <MDBox mt={0.1}>
                                <Switch checked={question.is_reversed} onChange={(c) => setQuestion({ ...question, is_reversed: c.target.checked })} />
                                </MDBox>
                                <MDBox width="80%" ml={0.5}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                    Reversed
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </Grid>
}
                        <Grid item xs={12} xl={12}>
                            <MDBox display="flex" alignItems="center" mb={0.1} ml={-1.5}>
                                <MDBox mt={0.1}>
                                <Switch checked={question.isInternal} onChange={(c) => setQuestion({ ...question, isInternal: c.target.checked })} />
                                </MDBox>
                                <MDBox width="80%" ml={0.5}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                    Is Internal
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </Grid>
                           
                    </Grid>
                </MDBox>
            </Card>
        </MDBox>
    )
}