import { ScreenModel } from "models/Screen"
import { makeRequest } from "./networkUtils"

export const getScreens = (): Promise<ScreenModel[]> => {
    return makeRequest("screens")
}

export const getScreen = (id: string): Promise<ScreenModel> => {
    return makeRequest(`screens/${id}`).then((screen) => {
        screen.fieldsOrder = screen.fieldsOrder?.split(",")
        return screen
    })
}

export const createScreenDO = (screen: ScreenModel): Promise<ScreenModel> => {
    return makeRequest("screens", { method: "POST", body: {...screen, fieldsOrder: screen.fieldsOrder.join(",")} })
}

export const saveScreenDO = (screen: ScreenModel): Promise<ScreenModel> => {
    return makeRequest(`screens/${screen.id}`, { method: "PUT", body: {...screen, fieldsOrder: screen.fieldsOrder.join(",")} })
}

export const deleteScreenDO = (screen: ScreenModel): Promise<void> => {
    return makeRequest(`screens/${screen.id}`, { method: "DELETE" })
}

export const publishScreens = (): Promise<void> => {
    return makeRequest("utils/publish", { method: "POST", body: { type: "screens" } })
}