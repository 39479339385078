import axios from "axios";
import { networkGlobals } from "./networkUtils";

const serverHost = 'https://api.a-dmin.ai';

/** get list of settings */
export const getAdminTasksDO = async (host) => {
    return new Promise((resolve, reject) => {
        let currentHost = networkGlobals.serverHost;
        axios.get(`${currentHost}/api/admin/adminTasks/?host=${host}`)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get logs:', error)
                reject(error);
            })
    })
}

/** post admin task */
export const postNewAdminTaskDO = async (host, task, where, by_user_id, link) => {
    return new Promise((resolve, reject) => {
        let currentHost = networkGlobals.serverHost;
        axios.post(`${currentHost}/api/admin/adminTask`, { host, task, where, by_user_id, link })
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from post logs:', error)
                reject(error);
            })
    })
}

/** update status */
export const updateStatusDO = async (host, id) => {
    return new Promise((resolve, reject) => {
        axios.put(`${currentHost}/admin/adminTaskStatus`, { host, id })
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from get profile:', error)
                reject(error);
            })
    })
}