import colors from "assets/theme/base/colors";
import { Statusable } from "./BoUser";

export enum NotificationType {
  Push = 1,
  Email = 2,
  SMS = 3,
}
export enum NotificationCategory {
  Introducions = 1,
  ChatMessages = 2,
  System = 3,
}

export interface NotificationSetting {
  channel_id: NotificationType;
  category_id: NotificationCategory;
  state: boolean;
}
export class EndUser {
  public id?: string;

  public firstName?: string;

  public lastName?: string;

  public dob?: string;

  public email?: string;

  public phoneNumber?: string;

  public username!: string;

  public identityId?: string;

  public avatarImageKey?: string;

  public fcmToken?: string;

  public fcmTokenUpdateTime?: string;

  public isEnabled?: boolean;

  public created: string;

  public lastLogin?: string;

  public avatar?: string;

  public birthdate: string;

  get age(): string {
    console.log("birthdate", this.birthdate);
    console.log("dob", this.dob);
    if (this.dob) {
      return (new Date().getFullYear() - new Date(this.dob).getFullYear()).toString();
    }
    if (this.birthdate) {
      return (new Date().getFullYear() - new Date(this.birthdate).getFullYear()).toString();
    }
    return "";
  }

  get fullName(): string {
    return this.firstName + (this.lastName ? ` ${this.lastName}` : "");
  }

  get phone(): string {
    return this.phoneNumber || "";
  }
}
