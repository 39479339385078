import { Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { T } from "components/alove/Translator";
import { Introduction, IntroductionStatus } from "models/Introduction";
import { useEffect, useState } from "react";
import { getShidduchRequests } from "utils/matchmakerUtils";

const MDTypography = require("components/MDTypography/index.js").default;

const DAY = 24 * 60 * 60 * 1000;
export const SnoozeDialog = ({ mmId, handleConfirm }) => {
  const [days, setDays] = useState(-1);
  // const [deletedIntros, setDeletedIntros] = useState([] as Introduction[]);
  // useEffect(() => {
  //   getShidduchRequests(
  //     0,
  //     [IntroductionStatus.MATCHED, IntroductionStatus.MM_AVAILABLE].join(","),
  //     "",
  //     "",
  //     mmId,
  //     ""
  //   ).then((requests) => {
  //     setDeletedIntros(requests.map((req) => req));
  //   });
  // }, []);

  return (
    <AloveDialog
      dialogType={DialogType.SnoozeSME}
      dialogTitle="confirmNotAvailableTitle"
      dialogBody="confirmNotAvailableBody"
      dialogConfirm="confirmNotAvailable"
      handleConfirm={() => handleConfirm(days).then(() => setDays(-1))}
      valid={days !== -1}
    >
      <Grid
        container
        spacing={2}
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
      >
        {/* {deletedIntros.length > 0 && (
          <MDTypography variant="body2" color="textSecondary" sx={{ fontWeight: "bold" }}>
            <T>deletedIntrosSnooze</T>
          </MDTypography>
        )}

        {deletedIntros.map((intro) => (
          <MDTypography variant="body2" color="textSecondary">
            {intro.initiatorUser?.firstName} {intro.initiatorUser?.lastName} &{" "}
            {intro.responderUser?.firstName} {intro.responderUser?.lastName}
          </MDTypography>
        ))} */}

        <MDTypography variant="body2" color="textSecondary" sx={{ fontWeight: "bold", mt: 2 }}>
        <T>howLongSnooze</T>
        </MDTypography>

        <FormControl>
          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
            <FormControlLabel
              value="option1"
              control={<Radio />}
              label="2 weeks"
              onChange={() => setDays(14 * DAY)}
            />
            <FormControlLabel
              value="option2"
              control={<Radio />}
              label="3 months"
              onChange={() => setDays(90 * DAY)}
            />
            <FormControlLabel
              value="option3"
              control={<Radio />}
              label={<T>pleaseNotReach</T>}
              onChange={() =>  setDays(0)}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </AloveDialog>
  );
};
