export class Attribute {
    ordinal: number;
    attr_name: string;
    is_public: boolean;
    responses: AttrResponse[];
    attr_group: string;
    is_sensitive: boolean;
    attr_category: string;
    response_type: string;
    user_editable: boolean;
    default_policy: number;
    pre_validation: PreValidation[];
    user_toggleable: boolean;
    related_batch_id: number;
    response_type_id: number;
    response_settings: ResponseSettings;
    editing_question_id: number;
    editing_question_txt: string;
}

export class AttrResponse {
    k: string;
    id: number;
    txt: string;
    bios: Bio[];
    ordinal: number;
}

class Bio {
    score: number;
    bio_attr_id: string;
    pref_attr_id: string;
    bio_response_id: string;
    pref_response_id: string;
}

class PreValidation {
    content: Content[];
    operand: string;
}   

class Content {
    attr: string;
    value: string;
    condition: string;
}

class ResponseSettings {
    max_length: number;
    validation: string;
    max_selections: string;
    min_selections: string;
}