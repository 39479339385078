import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { T } from "components/alove/Translator";
import { useEffect, useState } from "react";
import { loadAttributes } from "utils/attributesUtil";
import { updatePrevalidation } from "utils/attributesUtil";
import { Attribute, AttrResponse } from "models/Attribute";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { PreValidation, PreValidationCondition, PreValidationOperand } from "models/PreValidation";

const MDButton = require("components/MDButton/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const PrevalidateModal = (props) => {
  const { q, bcid } = props;
  const [open, setOpen] = useState(false);
  const [attributes, setAttributes] = useState([] as Attribute[]);

  useEffect(() => {
    loadAttributes().then((attr) => {
        setAttributes(attr);
    });
  }, []);
  const PreValidateDialog = (props) => {
    const [preValidationState, setPreValidationState] = useState([] as PreValidation[]);

    const handleClose = () => {
      setOpen(false);
    };

    const handleSave = () => {
      updatePrevalidation(preValidationState, bcid);
      q.pre_validation = preValidationState;
      setOpen(false);
    };

    useEffect(() => {
      setPreValidationState(q.pre_validation || []);
    }, [q.pre_validation]);

    const handleListItemClick = (value) => {};

    return (
      <Dialog onClose={handleClose} open={open} maxWidth="xl" fullWidth>
        <DialogTitle>Pre-validation</DialogTitle>
        <List>
          {preValidationState?.map((pr, pri) => [
            <ListItem key={pri}>
              <Grid container alignItems="center">
                <Grid item xs={10} container gap={2}>
                  {pr.content.map((prc, prci, arr) => {
                    const attr = attributes.find((a) => a.attr_name === prc.attr);
                    return (
                      <Grid container px={2} gap={2} key={prci}>
                        <Grid item xs={5}>
                          <Autocomplete
                            options={attributes}
                            getOptionLabel={(a) => a.editing_question_txt || a.attr_name}
                            value={attr || new Attribute()}
                            onChange={(e, v) => {
                              arr[prci].attr = v?.attr_name || "";
                              setPreValidationState([...preValidationState]);
                            }}
                            renderInput={(params) => <TextField {...params} label="Attribute" />}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Autocomplete
                            disableClearable
                            options={Object.values(PreValidationCondition)}
                            getOptionLabel={(a) => camelCaseToCapitalizedWords(a)}
                            value={prc.condition}
                            onChange={(e, v) => {
                              arr[prci].condition = v;
                              setPreValidationState([...preValidationState]);
                            }}
                            renderInput={(params) => <TextField {...params} label="Condition" />}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Autocomplete
                            options={attr?.responses || []}
                            getOptionLabel={(a:any) => a.txt || a}
                            freeSolo
                            multiple
                            onChange={(e, v) => {
                              arr[prci].value = v.map((vv) => vv.k || vv);
                              setPreValidationState([...preValidationState]);
                            }}
                            value={
                              attr?.responses?.filter((r) =>
                                prc.value?.toString().split(",").includes(r.k)
                              ) ||
                              [prc.value]
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Responses"
                                onChange={(e) => {
                                  if (e.target.value) {
                                    arr[prci].value = [e.target.value];
                                    setPreValidationState([...preValidationState]);
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Tooltip title="Remove condition">
                          <IconButton
                            onClick={() => {
                              pr.content = pr.content.filter((_, ii) => ii !== prci);
                              if (!pr.content.length) {
                                setPreValidationState(
                                  preValidationState.filter((_, ii) => ii !== pri)
                                );
                              } else {
                                setPreValidationState([...preValidationState]);
                              }
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    );
                  })}
                  <Tooltip title="Add `AND` condition">
                    <IconButton
                      sx={{ ml: 2 }}
                      onClick={() => {
                        pr.content.push({ attr: "", condition: PreValidationCondition.Equals, value: [] });
                        setPreValidationState([...preValidationState]);
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {pr.content?.length > 1 && (
                  <Grid>
                    <MDTypography>{pr.operand}</MDTypography>
                  </Grid>
                )}
              </Grid>
            </ListItem>,

            <Divider sx={{ p: 1 }} />,
          ])}
        </List>
        <DialogActions>
          <MDButton
            onClick={() => {
              preValidationState.push({
                content: [{ attr: "", condition: PreValidationCondition.Equals, value: [] }],
                operand: PreValidationOperand.AND,
              });
              setPreValidationState([...preValidationState]);
            }}
            variant="outlined"
            color="primary"
          >
            Add 'OR' condition
          </MDButton>
          <Grid item xs={true} />
          <MDButton onClick={handleClose}>Close</MDButton>
          <MDButton onClick={handleSave} variant="contained" color="primary">
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
    );
  };

  return [
    <MDButton
      variant="outlined"
      sx={{ mt: 2 }}
      color="primary"
      onClick={() => setOpen(true)}
      fullWidth
    >
      Pre-validation
    </MDButton>,
    <PreValidateDialog id="ringtone-menu" keepMounted />,
  ];
};
