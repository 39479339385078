import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ItemPageContainer from "layouts/itemPageContainer";
import {  formatDate } from "utils/globalsUtils";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { useMaterialUIController } from "context";
import { Profile, ProfileStatus } from "models/Profile";
import { getIntroductionDO } from "utils/introductionUtils";
import { Introduction, IntroductionStatus } from "models/Introduction";
import CardItem from "components/alove/CardItem";
import { AttributesTable } from "./attributesTable";
import { ProfileCards } from "./profileCard";
import { EndUser } from "models/EndUser";

const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;
let newStatus = ProfileStatus.Active;

function ViewIntroduction() {
  const pageNumber = "2.10";
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentBrand, currentRole, currentHost } = controller;

  const [introduction, setIntroduction] = useState({} as Introduction);
  const [loader, setLoader] = useState(true);
  let { id } = useParams();

  useEffect(() => {
    setLoader(true);
    getIntroduction();
  }, [id]);

  const getIntroduction = async () => {
    const res = await getIntroductionDO(id);
    setLoader(false);
    if (res) {
        const intro = Object.assign(new Introduction(), res);
        intro.initiatorUser = Object.assign(new EndUser(), res.initiatorUser)
        intro.responderUser = Object.assign(new EndUser(), res.responderUser)
        intro.initiatorProfile = Object.assign(new Profile(), intro.initiatorProfile)
        intro.responderProfile = Object.assign(new Profile(), res.responderProfile)
        setIntroduction(intro);
    }
    
  };


  return (
    <DashboardLayout>
      <DashboardNavbar  />
      <ItemPageContainer
        loader={loader}
        navItems={[
          { label: "Introduction Details" },
          { label: "Parties Details", ref: 'parties' },
        ]}
        actions={{
          itemName: `Introduction`,
          itemNumber: `Introduction ID: ${id}`,
          actionsTitle: "View Introduction",
          actions: [
          ],
        }}
        itemStatus={{
          title: "User Status",
          item: introduction
        }}
        itemHeader={
          <SMEHeader
          title="Introduction Details"
            formData={introduction}
            showImage={false}
            indications={[
              {
                title: "Created:",
                value: formatDate(introduction.created),
              },
              {
                title: "Last Updated:",
                value: formatDate(introduction.updated),
              },
             {
                hidden: ![IntroductionStatus.RAW, IntroductionStatus.INITIATOR_APPROVED].includes(introduction.status),
                title: "SLA:",
                value: formatDate(introduction.mustRespondUntil),
             }
            ]}
          />
        }
      >
        <CardItem title="Parties Details" id="parties">
            <ProfileCards shRequest={introduction} isAdmin />
        </CardItem>
      </ItemPageContainer>

   
    </DashboardLayout>
  );
}

export default ViewIntroduction;
