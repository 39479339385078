import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { GeneralCodeType } from "models/GeneralCode";
import React from "react";
import { addGeneralCodeDO } from "utils/generalCodesUtils";

const MDInput = require("components/MDInput").default;

export const AddGeneralCodeDialog = () => {
  const [name, setName] = React.useState("");
  return (
    <AloveDialog
      dialogType={DialogType.AddGeneralCode}
      dialogTitle="Add Screen Category"
      dialogBody="Enter the name of the category you want to add"
      dialogConfirm="Add"
      valid={name.length > 0}
      handleConfirm={async () => {
        addGeneralCodeDO(GeneralCodeType.ScreenCategory, name);
      }}
    >
      <MDInput
        label="Category Name"
        placeholder="Category Name"
        required
        value={name}
        onChange={(e: any) => setName(e.target.value)}
      />
    </AloveDialog>
  );
};
